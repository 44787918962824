import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ChartArea, DoughnutChart, DoughnutChartDataType, LoadableContentArea } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { CurrentCapacityDoughnut$key } from './__generated__/CurrentCapacityDoughnut.graphql';
import style from './common.module.css';

export interface RemainingBatteryBreakdown {
    rangeLow: number;
    rangeHigh: number;
    deviceCount: number;
}

export interface CurrentCapacityDoughnutProps {
    data: CurrentCapacityDoughnut$key | null;
    error?: boolean;
    retry: () => void;
}

export const CurrentCapacityDoughnut: FC<CurrentCapacityDoughnutProps> = ({ data, error, retry }) => {
    const results = useFragment<CurrentCapacityDoughnut$key>(
        graphql`
            fragment CurrentCapacityDoughnut on Query {
                batteryMetrics {
                    remainingCapacityBreakdown {
                        rangeLow
                        rangeHigh
                        deviceCount
                    }
                }
            }
        `,
        data
    );

    let remainingCapacityBreakdown: RemainingBatteryBreakdown[] | null;
    if (results?.batteryMetrics?.remainingCapacityBreakdown) {
        remainingCapacityBreakdown = [...results.batteryMetrics.remainingCapacityBreakdown];
        if (remainingCapacityBreakdown.every(section => section.deviceCount === 0)) {
            remainingCapacityBreakdown = [];
        }
    } else {
        remainingCapacityBreakdown = null;
    }

    return (
        <ChartArea title='Current battery capacity'>
            <LoadableContentArea
                data={remainingCapacityBreakdown}
                error={error}
                onRetry={retry}
                className={style.doughnut}
                render={result => {
                    const data: DoughnutChartDataType[] = result.map(section => ({
                        name: `${(section.rangeLow * 100).toFixed(0)}% - ${(section.rangeHigh * 100).toFixed(0)}%`,
                        value: section.deviceCount,
                    }));

                    return (
                        <div className={style.doughnut}>
                            <DoughnutChart data={data} valueFormatter={value => `${value} devices`} />
                        </div>
                    );
                }}
            />
        </ChartArea>
    );
};
