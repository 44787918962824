import graphql from 'babel-plugin-relay/macro';
import {
    DeviceExtraFilters,
    DeviceFilterState,
    DeviceTableColumn,
    DeviceTableColumnId,
    deviceToFilterObject,
} from 'filters/device';
import { SortDirection, TableState } from 'layouts';
import { FetchOptions } from 'lib/query-helpers';
import { DefaultTimeRange, TimeRange } from 'views/reports/ac-power/settings';

import { useTableQuery } from '../table-query';
import { DeviceOrdering, DeviceSortField, requestDevicesQuery } from './__generated__/requestDevicesQuery.graphql';

const ACReliabilityColumns: DeviceTableColumnId[] = [
    DeviceTableColumn.ACReliability,
    DeviceTableColumn.IncidentCount,
    DeviceTableColumn.Mtbf,
    DeviceTableColumn.Mttr,
    DeviceTableColumn.OutageDurationSum,
];

export function useDeviceTableQuery(
    tableState: TableState<DeviceTableColumnId>,
    filters: DeviceFilterState,
    fetchOptions?: FetchOptions
) {
    return useTableQuery<DeviceTableColumnId, requestDevicesQuery>(
        RequestQuery,
        options => {
            const sortObject: DeviceOrdering = {
                field: options.orderBy as DeviceSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            const acReliabilityTimeRange: TimeRange =
                (filters.extraFilters[DeviceExtraFilters.ACReliabilityTimeRange] as TimeRange | undefined) ??
                DefaultTimeRange;

            const filterObject = deviceToFilterObject(filters);

            return {
                page: options.page,
                pageSize: options.pageSize,
                pageCount: options.pageCount,
                search: options.search,
                orderBy: sortObject,
                filter: filterObject,
                withMonitorOnly: options.visibleColumns.includes(DeviceTableColumn.MonitorOnly),
                withSnmpVersion: options.visibleColumns.includes(DeviceTableColumn.SnmpVersion),
                withBatteryStatus: options.visibleColumns.includes(DeviceTableColumn.BatteryStatus),
                withDeviceHealth: options.visibleColumns.includes(DeviceTableColumn.DeviceStatus),
                withBatteryStringCount: options.visibleColumns.includes(DeviceTableColumn.BatteryStringCount),
                withBatteryTemperature: options.visibleColumns.includes(DeviceTableColumn.BatteryTemperature),
                withBatteryCapacityRemaining: options.visibleColumns.includes(
                    DeviceTableColumn.BatteryCapacityRemaining
                ),
                withBatteryEnergyTotal: options.visibleColumns.includes(DeviceTableColumn.BatteryEnergyTotal),
                withBatteryReserveTime: options.visibleColumns.includes(DeviceTableColumn.BatteryReserveTime),
                withBatteryStateOfHealth: options.visibleColumns.includes(DeviceTableColumn.BatteryStateOfHealth),
                withAcReliability: options.visibleColumns.some(column => ACReliabilityColumns.includes(column)),
                acTimeRange: acReliabilityTimeRange,
            };
        },
        tableState,
        fetchOptions
    );
}

const RequestQuery = graphql`
    query requestDevicesQuery(
        $acTimeRange: ACTimeRange!
        $page: Int!
        $pageSize: Int!
        $pageCount: Int
        $search: String = ""
        $orderBy: DeviceOrdering
        $filter: DeviceFilter
        $withMonitorOnly: Boolean = false
        $withSnmpVersion: Boolean = false
        $withBatteryStatus: Boolean = false
        $withDeviceHealth: Boolean = false
        $withBatteryStringCount: Boolean = false
        $withBatteryTemperature: Boolean = false
        $withBatteryReserveTime: Boolean = false
        $withBatteryStateOfHealth: Boolean = false
        $withBatteryCapacityRemaining: Boolean = false
        $withBatteryEnergyTotal: Boolean = false
        $withAcReliability: Boolean = false
    ) {
        devices(
            page: $page
            pageSize: $pageSize
            pageCount: $pageCount
            search: $search
            orderBy: $orderBy
            filters: $filter
            acReliabilityTimeRange: $acTimeRange
        ) {
            total
            data {
                id
                name
                type {
                    displayName
                }
                site {
                    id
                    name
                    address {
                        state
                    }
                }
                monitorOnly @include(if: $withMonitorOnly)
                health @include(if: $withDeviceHealth)
                connectionSettings @include(if: $withSnmpVersion) {
                    protocols {
                        ... on ProtocolSnmp {
                            version
                        }
                    }
                }
                battery {
                    reserveTime @include(if: $withBatteryReserveTime)
                    metrics {
                        latestStatus @include(if: $withBatteryStatus)
                        latestTemperature @include(if: $withBatteryTemperature)
                        latestStateOfHealth @include(if: $withBatteryStateOfHealth)
                        latestRemainingCapacity @include(if: $withBatteryCapacityRemaining)
                        latestTotalEnergy @include(if: $withBatteryEnergyTotal)
                    }
                    strings {
                        count @include(if: $withBatteryStringCount)
                    }
                }
                acPower {
                    reliability(timeRange: $acTimeRange) @include(if: $withAcReliability) {
                        incidentCount
                        totalOutageDuration {
                            current
                        }
                        mttr {
                            current
                        }
                        mtbf {
                            current
                        }
                        percentile {
                            current
                        }
                    }
                }
                attributes {
                    name
                    value
                }
            }
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
        }
        overallDevices: devices {
            total
        }
    }
`;
