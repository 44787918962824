/**
 * @generated SignedSource<<c3f48e2f90483442140fc5be5c9a4e20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GatewayInventoryErrorType = "GatewayInvalid" | "GatewayNotFound" | "GatewayUnreachable" | "%future added value";
export type GatewayModuleDetailsQuery$variables = {
  id: string;
};
export type GatewayModuleDetailsQuery$data = {
  readonly gatewayInventory: {
    readonly error?: GatewayInventoryErrorType;
    readonly modules?: ReadonlyArray<{
      readonly displayName: string;
      readonly version: string;
    }>;
  } | null;
};
export type GatewayModuleDetailsQuery = {
  response: GatewayModuleDetailsQuery$data;
  variables: GatewayModuleDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "GatewayInventoryError",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleInventoryResult",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GatewayInventoryResult",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GatewayModuleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "gatewayInventory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GatewayModuleDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "gatewayInventory",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89fe419938a666c49fa5c59adf8b161e",
    "id": null,
    "metadata": {},
    "name": "GatewayModuleDetailsQuery",
    "operationKind": "query",
    "text": "query GatewayModuleDetailsQuery(\n  $id: ID!\n) {\n  gatewayInventory(id: $id) {\n    __typename\n    ... on GatewayInventoryError {\n      error\n    }\n    ... on GatewayInventoryResult {\n      modules {\n        displayName\n        version\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9bca232d5eb5d6c48d632748c9f8672";

export default node;
