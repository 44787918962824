/**
 * @generated SignedSource<<8a776a108d4cb256930c894da6f12cf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReliabilityChart$data = {
  readonly siteACReliabilityDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly key: number;
      readonly percentileEnd: number;
      readonly percentileStart: number;
    }>;
  } | null;
  readonly " $fragmentType": "ReliabilityChart";
};
export type ReliabilityChart$key = {
  readonly " $data"?: ReliabilityChart$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReliabilityChart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReliabilityChart",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "timeRange",
          "variableName": "timeRange"
        }
      ],
      "concreteType": "SiteACReliabilityDistribution",
      "kind": "LinkedField",
      "name": "siteACReliabilityDistribution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteACReliabilityDistributionBucket",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileStart",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileEnd",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "0b592f5cd5303a6a44e6b15431defe93";

export default node;
