import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import {
    CircleAlertIcon,
    CircleCheckIcon,
    ReportTile,
    ReportTileProps,
    ReportTileStatus,
    ReportTileTrend,
    Tooltip,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { Duration } from 'luxon';

import { intervalToHuman } from '../../../common';
import { getSectionTimeDescription } from '../../../common';
import { TimeRange } from '../../../settings';
import { ReliabilityChart } from '../components/ReliabilityChart';
import { ReliabilityChart_query$key } from '../components/__generated__/ReliabilityChart_query.graphql';
import { ACSiteReliability_reliability$key } from './__generated__/ACSiteReliability_reliability.graphql';

interface ReliabilityMetric {
    current: number | null;
    previous: number | null;
    percentChange: number | null;
}

const MinimumPercentChange = 5;

export interface ACSiteReliabilityProps {
    reliabilityRef: ACSiteReliability_reliability$key;
    queryRef: ReliabilityChart_query$key;
    timeRange: TimeRange;
}

export const ACSiteReliability: FC<ACSiteReliabilityProps> = ({ reliabilityRef, queryRef, timeRange }) => {
    const { mttr, mtbf, percentile } = useFragment(SiteFragment, reliabilityRef);

    const reliabilityTile: Pick<ReportTileProps, 'content' | 'status' | 'trend' | 'description'> = {
        content: (
            <div className='flex flex-row gap-2'>
                <div className='w-6 rotate-180'>
                    <CircleAlertIcon />
                </div>{' '}
                <Tooltip content='This site has not yet been ranked against other sites. This will happen automatically as enough information is collected.'>
                    <div>Not graded</div>
                </Tooltip>
            </div>
        ),
        status: 'neutral',
        trend: 'neutral',
    };

    function describePercentile(percentile: number): string {
        if (percentile < 50) {
            return `Bottom ${percentile.toFixed(0)}%`;
        } else {
            const topPercentile = 100 - percentile;
            return `Top ${topPercentile.toFixed(0)}%`;
        }
    }

    if (percentile.current != null) {
        let icon: ReactNode;

        if (percentile.current < 50) {
            icon = (
                <div className='w-6 text-coralRegular'>
                    <CircleAlertIcon />
                </div>
            );
        } else {
            icon = (
                <div className='w-6 text-pineRegular'>
                    <CircleCheckIcon />
                </div>
            );
        }

        const topPercentile = 100 - percentile.current;
        const tooltip = `${topPercentile.toFixed(0)}% of sites are more reliable than this site.`;

        reliabilityTile.content = (
            <div className='flex flex-row gap-2'>
                {icon}
                <Tooltip content={tooltip}>
                    <div>{describePercentile(percentile.current)}</div>
                </Tooltip>
            </div>
        );
    }

    if (percentile.percentChange != null) {
        const timeDescription = intervalToHuman(timeRange, {
            lifetimeOverride: 'since the site was created',
            prefix: 'in the last ',
        });

        if (percentile.percentChange > MinimumPercentChange) {
            reliabilityTile.status = 'good';
            reliabilityTile.trend = 'up';

            reliabilityTile.description = (
                <div>
                    <div>
                        Improved {percentile.percentChange.toFixed(0)}% {timeDescription}.
                    </div>
                    <div>Was {describePercentile(percentile.previous!).toLowerCase()} previously.</div>
                </div>
            );
        } else if (percentile.percentChange < -MinimumPercentChange) {
            reliabilityTile.status = 'bad';
            reliabilityTile.trend = 'down';

            reliabilityTile.description = (
                <div>
                    <div>
                        Worsened {Math.abs(percentile.percentChange).toFixed(0)}% {timeDescription}.
                    </div>
                    <div>Was {describePercentile(percentile.previous!).toLowerCase()} previously.</div>
                </div>
            );
        } else {
            reliabilityTile.description = `Unchanged ${timeDescription}`;
        }
    }

    const mtbfTile = prepareTile(mtbf, true);
    const mttrTile = prepareTile(mttr, false);

    return (
        <div className='grid grid-cols-12 gap-x-4 col-span-12'>
            <div className='col-span-12 font-bold text-xl mb-2 mt-6'>
                Reliability <span className='font-light text-xs'>{getSectionTimeDescription(timeRange, 'site')}</span>
            </div>
            <div className='col-span-12 lg:col-span-8 row-span-2 h-40 lg:h-auto lg:col-start-5 lg:row-start-2'>
                <ReliabilityChart queryRef={queryRef} sitePercentile={percentile.current} />
            </div>
            <ReportTile
                title='Reliability across all sites'
                size='large'
                tileClassName='col-span-12 lg:col-span-4 mb-4'
                {...reliabilityTile}
            />

            <ReportTile title='MTBF' tileClassName='col-span-6 lg:col-span-2' {...mtbfTile} />
            <ReportTile title='MTTR' tileClassName='col-span-6 lg:col-span-2' {...mttrTile} />
        </div>
    );
};

const SiteFragment = graphql`
    fragment ACSiteReliability_reliability on SiteAcPowerReliabilityMetrics {
        mttr {
            current
            previous
            percentChange
        }
        mtbf {
            current
            previous
            percentChange
        }
        percentile {
            current
            previous
            percentChange
        }
    }
`;

function prepareTile(
    value: ReliabilityMetric,
    positiveIsGood = true
): Pick<ReportTileProps, 'content' | 'status' | 'trend' | 'description'> {
    let content: string;
    let status: ReportTileStatus = 'neutral';
    let trend: ReportTileTrend = 'neutral';
    let description: ReactNode;

    if (value.current == null) {
        content = 'No failures';
    } else {
        const mtbfDuration = Duration.fromObject({ minutes: value.current });

        content = humanizeDuration(mtbfDuration.as('milliseconds'), {
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
            largest: 1,
        });
    }

    if (value.percentChange != null) {
        if (positiveIsGood) {
            if (value.percentChange > MinimumPercentChange) {
                status = 'good';
                trend = 'up';
            } else if (value.percentChange < -MinimumPercentChange) {
                status = 'bad';
                trend = 'down';
            }
        } else {
            if (value.percentChange > MinimumPercentChange) {
                status = 'bad';
                trend = 'up';
            } else if (value.percentChange < -MinimumPercentChange) {
                status = 'good';
                trend = 'down';
            }
        }

        if (value.previous != null) {
            const previousDuration = Duration.fromObject({ minutes: value.previous });
            const lastDurationDescribed = humanizeDuration(previousDuration.as('milliseconds'), {
                units: ['y', 'mo', 'd', 'h', 'm'],
                round: true,
                largest: 1,
            });

            description = `Was ${lastDurationDescribed} previously.`;
        } else {
            description = <>&nbsp;</>;
        }
    } else {
        description = <>&nbsp;</>;
    }

    return {
        content,
        status,
        trend,
        description,
    };
}
