import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { StatusMetric } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NotesSection } from 'components/NotesSection';
import { numberToLocaleString } from 'lib/numberFormatters';

import { RunParameterView_data$key } from './__generated__/RunParameterView_data.graphql';

interface RunParameterViewprops {
    runReport: RunParameterView_data$key;
}

export const RunParameterView: FC<RunParameterViewprops> = ({ runReport }) => {
    const data = useFragment<RunParameterView_data$key>(RunParameterViewFragment, runReport);

    let fuelTankCapacity: string;
    if (data.generatorInfo.fuelCapacity != null) {
        fuelTankCapacity = `${numberToLocaleString(data.generatorInfo.fuelCapacity, 0)}L`;
    } else {
        fuelTankCapacity = '-';
    }

    return (
        <div className='grid grid-cols-3 gap-4 items-stretch px-8 py-4 my-4'>
            <StatusMetric title='Fuel tank capacity' content={fuelTankCapacity} status={'neutral'} showIcon={false} />
            <StatusMetric
                title='Serial number'
                content={data.generatorInfo.serialNumber ?? 'Unknown'}
                status={'neutral'}
                showIcon={false}
            />
            <StatusMetric
                title='Model Name'
                content={data.generatorInfo.modelName ?? 'Unknown'}
                status={'neutral'}
                showIcon={false}
            />
            <NotesSection editId={data.id} note={data.note} type='generatorRun' />
        </div>
    );
};

const RunParameterViewFragment = graphql`
    fragment RunParameterView_data on GeneratorRunReport {
        id
        generatorInfo {
            modelName
            serialNumber
            fuelCapacity(unit: Liter)
        }
        note {
            content
        }
    }
`;
