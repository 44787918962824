import React, { FC } from 'react';

import { CommonFilterProps } from 'filters/common';

import { BatteryTypeCellReferenceTemperatureFilter } from '../types';
import { BatteryTypeFilterRange } from './BatteryTypeFilterRange';

export type BatteryTypeCellReferenceTemperatureFilterUIProps =
    CommonFilterProps<BatteryTypeCellReferenceTemperatureFilter | null>;

export const BatteryTypeCellReferenceTemperatureFilterUI: FC<BatteryTypeCellReferenceTemperatureFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <BatteryTypeFilterRange
        current={current}
        onClearOrBack={onClearOrBack}
        onClose={onClose}
        onSubmit={onSubmit}
        title='Filter by Cell Reference Temperature (°C)'
        distributionType='CellReferenceTemperature'
        showBack={hasPrevious}
    />
);
