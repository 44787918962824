import React, { Dispatch, ReactElement, useCallback, useMemo } from 'react';

import { ActiveFilterActionButton, FilterKeyValue, FilterSection } from '@accesstel/pcm-ui';

import { AddFilterPane } from 'components/AddFilterPane';
import { FilterAction, FilterActionType, FilterState, FilterValue, renderEmbeddedTopLevelFilter } from 'filters/common';
import { getFilters } from 'filters/common/helpers';

import { useConfirmationModal } from '../../lib/confirmation';

export interface FilterBarProps<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>> {
    state: FilterState<ColumnType, TypeMap>;
    updateState: Dispatch<FilterAction<TypeMap, ColumnType>>;
    variant?: 'light' | 'dark';
    additionalActions?: ActiveFilterActionButton[];
}

export function FilterBar<ColumnType extends string, TypeMap extends Record<ColumnType, unknown>>({
    state,
    updateState,
    variant = 'light',
    additionalActions,
}: FilterBarProps<ColumnType, TypeMap>): ReactElement {
    const [showModal, modalComponent] = useConfirmationModal();

    const handleAddFilterClick = useCallback(() => {
        if (state.addFilterVisible) {
            updateState({ type: FilterActionType.HideAll });
        } else {
            updateState({ type: FilterActionType.ShowAdd });
        }
    }, [state.addFilterVisible, updateState]);

    const handleAddFilterClose = useCallback(() => {
        updateState({ type: FilterActionType.HideAll });
    }, [updateState]);

    const filterList = useMemo<FilterValue[]>(() => getFilters(state), [state]);
    const filterListTransformed = useMemo<FilterKeyValue[]>(
        () =>
            filterList.map(filter => {
                const definition = state.filterDefinitions.find(definition => definition.id === filter.definition);
                return {
                    label: definition!.name,
                    value: definition!.describeValue(filter.value),
                };
            }),
        [filterList, state.filterDefinitions]
    );

    const handleFilterClick = useCallback(
        (_: FilterKeyValue, index: number) => {
            const filter = filterList[index];
            updateState({ type: FilterActionType.Show, definition: filter.definition, index: filter.index });
        },
        [filterList, updateState]
    );
    const handleFilterRemove = useCallback(
        (_: FilterKeyValue, index: number) => {
            const filter = filterList[index];
            updateState({ type: FilterActionType.Remove, definition: filter.definition, index: filter.index });
        },
        [filterList, updateState]
    );

    return (
        <>
            <FilterSection
                activeFilters={filterListTransformed}
                showNumberOfResults={false}
                onClearAllClick={() =>
                    showModal({
                        title: 'Clear Filters',
                        content: 'Are you sure you want to remove all filters?',
                        onResult: async result => {
                            if (result === 'confirm') {
                                updateState({ type: FilterActionType.ClearAll });
                            }
                        },
                    })
                }
                onFilterClick={handleFilterClick}
                onFilterRemove={handleFilterRemove}
                embeddedFilterComponent={renderEmbeddedTopLevelFilter(state, updateState)}
                circleColor={variant === 'dark' ? 'gray' : undefined}
                embeddedAddComponent={
                    state.addFilterVisible && (
                        <AddFilterPane onClose={handleAddFilterClose} state={state} updateState={updateState} />
                    )
                }
                onAddFilterClick={handleAddFilterClick}
                additionalActions={additionalActions}
            />
            {modalComponent}
        </>
    );
}
