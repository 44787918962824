/**
 * @generated SignedSource<<a05c1ff288c89d5f3348dd77d27882cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BatteryTestRow_data$data = {
  readonly cause: BatteryTestReason;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly state: BatteryTestState;
  readonly task: {
    readonly name: string | null;
    readonly overallState: TaskState;
    readonly schedule: {
      readonly time: string;
    };
  } | null;
  readonly " $fragmentType": "BatteryTestRow_data";
};
export type BatteryTestRow_data$key = {
  readonly " $data"?: BatteryTestRow_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"BatteryTestRow_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BatteryTestRow_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overallState",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSchedule",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "d31059f1de01d8a86d68a0632c1f0fd0";

export default node;
