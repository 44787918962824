/**
 * @generated SignedSource<<9e92dbbb0271d635e55992a1f0dc0ce6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type ActivityLogSource = "Alert" | "AuditLog" | "GridEvent" | "%future added value";
export type ActivityLogType = "AlertSeverityCritical" | "AlertSeverityIndeterminate" | "AlertSeverityMajor" | "AlertSeverityMinor" | "AlertSeverityWarning" | "BatteryTest" | "DeviceAdd" | "DeviceEdit" | "DeviceRemove" | "GridEventStatusHigh" | "GridEventStatusLow" | "GridEventStatusOffline" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeviceOverview_device$data = {
  readonly acPower: {
    readonly metrics: {
      readonly latestApparentPower: number | null;
    };
  };
  readonly acPowerAlerts: {
    readonly total: number;
  };
  readonly activityLogs: {
    readonly data: ReadonlyArray<{
      readonly changes: ReadonlyArray<{
        readonly field: string;
        readonly newValue: string | null;
        readonly oldValue: string | null;
      }> | null;
      readonly link: {
        readonly __typename: "ACPowerEvent";
        readonly affectedAllFeeds: boolean;
        readonly affectedFeeds: ReadonlyArray<{
          readonly id: string;
          readonly status: ACPowerEventStatus;
        }>;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly duration: string;
        readonly worstStatus: ACPowerEventStatus;
      } | {
        readonly __typename: "Alert";
        readonly category: string;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly isActive: boolean;
        readonly message: string;
        readonly severity: AlertSeverity;
      } | {
        readonly __typename: "BatteryTest";
        readonly commencedTime: string | null;
        readonly id: string;
        readonly taskName: string | null;
      } | {
        readonly __typename: "Device";
        readonly id: string;
        readonly name: string;
      } | {
        readonly __typename: "DeviceBatteryTestResults";
        readonly commencedTime: string | null;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly id: string;
        readonly state: BatteryTestState;
        readonly task: {
          readonly abortedUser: {
            readonly name: string | null;
            readonly username: string;
          } | null;
          readonly id: string;
          readonly name: string | null;
          readonly testState: BatteryTaskState;
          readonly type: BatteryTestType;
        } | null;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly source: ActivityLogSource;
      readonly timestamp: string;
      readonly type: ActivityLogType;
      readonly user: {
        readonly name: string | null;
        readonly username: string;
      } | null;
    }>;
    readonly hasMore: boolean;
    readonly total: number;
  };
  readonly battery: {
    readonly metrics: {
      readonly latestStatus: BatteryStatus;
      readonly latestVoltage: number | null;
    };
  };
  readonly batteryAlerts: {
    readonly total: number;
  };
  readonly dualPlaneCompanion: {
    readonly device: {
      readonly name: string;
    } | null;
  } | null;
  readonly firmware: {
    readonly name: string;
  } | null;
  readonly health: DeviceHealth | null;
  readonly lastUpdate: string | null;
  readonly load: {
    readonly metrics: {
      readonly latestPower: number | null;
    };
  };
  readonly loadAlerts: {
    readonly total: number;
  };
  readonly name: string;
  readonly rectifier: {
    readonly capacityAtRiskWithHeadroom: boolean | null;
    readonly freeCapacity: number | null;
    readonly metrics: {
      readonly latestOutputPower: number | null;
    };
    readonly offlineCapacity: number | null;
    readonly totalCapacity: number | null;
    readonly usedCapacity: number | null;
  } | null;
  readonly rectifierAlerts: {
    readonly total: number;
  };
  readonly serialNumber: string | null;
  readonly site: {
    readonly id: string;
  };
  readonly type: {
    readonly displayName: string;
  };
  readonly " $fragmentType": "DeviceOverview_device";
};
export type DeviceOverview_device$key = {
  readonly " $data"?: DeviceOverview_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceOverview_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Watt"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "username",
    "storageKey": null
  },
  (v1/*: any*/)
],
v8 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceOverview_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "health",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DualPlaneCompanion",
      "kind": "LinkedField",
      "name": "dualPlaneCompanion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FirmwareVersion",
      "kind": "LinkedField",
      "name": "firmware",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceACPower",
      "kind": "LinkedField",
      "name": "acPower",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceACPowerMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "unit",
                  "value": "VoltAmp"
                }
              ],
              "kind": "ScalarField",
              "name": "latestApparentPower",
              "storageKey": "latestApparentPower(unit:\"VoltAmp\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceBattery",
      "kind": "LinkedField",
      "name": "battery",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestVoltage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latestStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceLoad",
      "kind": "LinkedField",
      "name": "load",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceLoadMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v3/*: any*/),
              "kind": "ScalarField",
              "name": "latestPower",
              "storageKey": "latestPower(unit:\"Watt\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceRectifier",
      "kind": "LinkedField",
      "name": "rectifier",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "freeCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usedCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "offlineCapacity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacityAtRiskWithHeadroom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceRectifierMetrics",
          "kind": "LinkedField",
          "name": "metrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v3/*: any*/),
              "kind": "ScalarField",
              "name": "latestOutputPower",
              "storageKey": "latestOutputPower(unit:\"Watt\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "acPowerAlerts",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "domain": {
              "value": "AcPower"
            }
          }
        }
      ],
      "concreteType": "PaginatedAlerts",
      "kind": "LinkedField",
      "name": "alerts",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "alerts(filters:{\"domain\":{\"value\":\"AcPower\"}})"
    },
    {
      "alias": "batteryAlerts",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "domain": {
              "value": "Battery"
            }
          }
        }
      ],
      "concreteType": "PaginatedAlerts",
      "kind": "LinkedField",
      "name": "alerts",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "alerts(filters:{\"domain\":{\"value\":\"Battery\"}})"
    },
    {
      "alias": "loadAlerts",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "domain": {
              "value": "Load"
            }
          }
        }
      ],
      "concreteType": "PaginatedAlerts",
      "kind": "LinkedField",
      "name": "alerts",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "alerts(filters:{\"domain\":{\"value\":\"Load\"}})"
    },
    {
      "alias": "rectifierAlerts",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "domain": {
              "value": "Rectifier"
            }
          }
        }
      ],
      "concreteType": "PaginatedAlerts",
      "kind": "LinkedField",
      "name": "alerts",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": "alerts(filters:{\"domain\":{\"value\":\"Rectifier\"}})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "types",
          "value": [
            "DeviceAdd",
            "DeviceEdit",
            "AlertSeverityCritical",
            "AlertSeverityMajor",
            "GridEventStatusOffline",
            "GridEventStatusHigh",
            "BatteryTest"
          ]
        }
      ],
      "concreteType": "PaginatedActivityLogs",
      "kind": "LinkedField",
      "name": "activityLogs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMore",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityLog",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChangeFieldValue",
              "kind": "LinkedField",
              "name": "changes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "field",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "oldValue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "newValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "link",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v8/*: any*/),
                  "type": "Device",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "severity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isActive",
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "type": "Alert",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "worstStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "duration",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "affectedAllFeeds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ACPowerEventFeed",
                      "kind": "LinkedField",
                      "name": "affectedFeeds",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "type": "ACPowerEvent",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "state",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BatteryTest",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v6/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "testState",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "abortedUser",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v9/*: any*/)
                  ],
                  "type": "DeviceBatteryTestResults",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": "taskName",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "type": "BatteryTest",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "activityLogs(limit:5,types:[\"DeviceAdd\",\"DeviceEdit\",\"AlertSeverityCritical\",\"AlertSeverityMajor\",\"GridEventStatusOffline\",\"GridEventStatusHigh\",\"BatteryTest\"])"
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "e1646e74177e86081293aaa293f3eb2a";

export default node;
