/**
 * @generated SignedSource<<c5bdc21bb74645f5a892379d794fcc35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeviceErrorType = "DuplicateName" | "EditNotAllowed" | "InvalidAttributeName" | "InvalidBatteryId" | "InvalidBatteryType" | "InvalidBatteryVoltage" | "InvalidCellCount" | "InvalidDevice" | "InvalidDeviceType" | "InvalidDualPlaneDevice" | "InvalidFirmware" | "InvalidManufactureDate" | "InvalidQuickTestCheckPercent" | "InvalidQuickTestThreshold" | "InvalidReserveTime" | "InvalidSite" | "MissingName" | "Noop" | "NotPossibleRightNow" | "Skipped" | "%future added value";
export type DeviceIntegrationErrorType = "InvalidIntegration" | "InvalidIntegrationAttribute" | "MissingIntegration" | "MissingIntegrationAttribute" | "%future added value";
export type DualPlaneConfiguration = "NPlusOne" | "TwoN" | "%future added value";
export type ProtocolErrorField = "BasicPassword" | "BasicUsername" | "GatewayNotFound" | "InvalidGateway" | "MissingProtocol" | "SnmpPort" | "SnmpV1ReadCommunity" | "SnmpV1WriteCommunity" | "SnmpV3AuthPassphrase" | "SnmpV3EngineId" | "SnmpV3PrivPassphrase" | "SnmpV3User" | "UnknownProtocol" | "%future added value";
export type SnmpAuthAlgorithm = "MD5" | "SHA1" | "%future added value";
export type SnmpPrivAlgorithm = "AES" | "DES" | "%future added value";
export type SnmpSecurityLevel = "AuthNoPriv" | "AuthPriv" | "NoAuthNoPriv" | "%future added value";
export type SnmpVersion = "V1" | "V2c" | "V3" | "%future added value";
export type DeviceIn = {
  attributes?: ReadonlyArray<AttributeIn> | null;
  battery?: DeviceBatteryIn | null;
  connectionSettings: ConnectionSettingsIn;
  dualPlaneDevice?: string | null;
  dualPlaneType?: DualPlaneConfiguration | null;
  integrations?: ReadonlyArray<DeviceIntegrationIn> | null;
  monitorOnly?: boolean | null;
  name: string;
  site: string;
  targetFirmware?: string | null;
  type: string;
};
export type DeviceBatteryIn = {
  maximumAllowedVoltage?: number | null;
  minimumAllowedVoltage?: number | null;
  quickTestCheckPercent?: number | null;
  quickTestFailThreshold?: number | null;
  reserveTime?: number | null;
  strings: ReadonlyArray<DeviceBatteryStringIn>;
};
export type DeviceBatteryStringIn = {
  cellsPerString: number;
  installDate?: string | null;
  manufactureDate?: string | null;
  type: string;
};
export type AttributeIn = {
  name: string;
  value: string;
};
export type ConnectionSettingsIn = {
  addresses: ReadonlyArray<string>;
  protocols: ReadonlyArray<ProtocolIn>;
};
export type ProtocolIn = {
  basic?: ProtocolBasicIn | null;
  gateway?: ProtocolGatewayIn | null;
  id: string;
  snmp?: ProtocolSnmpIn | null;
};
export type ProtocolSnmpIn = {
  port?: number | null;
  settingsV1V2c?: ProtocolSnmpV1AndV2cIn | null;
  settingsV3?: ProtocolSnmpV3In | null;
  version: SnmpVersion;
};
export type ProtocolSnmpV1AndV2cIn = {
  readOnlyCommunity: string;
  readWriteCommunity: string;
};
export type ProtocolSnmpV3In = {
  authPassphrase?: string | null;
  authType: SnmpAuthAlgorithm;
  engineId?: string | null;
  privPassphrase?: string | null;
  privType: SnmpPrivAlgorithm;
  securityLevel: SnmpSecurityLevel;
  user: string;
};
export type ProtocolBasicIn = {
  password: string;
  username: string;
};
export type ProtocolGatewayIn = {
  gatewayId: string;
  localId: string;
};
export type DeviceIntegrationIn = {
  attributes: ReadonlyArray<IntegrationAttributeIn>;
  type: string;
};
export type IntegrationAttributeIn = {
  name: string;
  value?: string | null;
};
export type mutations_CreateDeviceMutation$variables = {
  device: DeviceIn;
};
export type mutations_CreateDeviceMutation$data = {
  readonly addDevice: {
    readonly id?: string;
    readonly problems?: ReadonlyArray<{
      readonly field?: ProtocolErrorField;
      readonly integration?: string;
      readonly integrationAttribute?: string | null;
      readonly integrationProblemType?: DeviceIntegrationErrorType;
      readonly protocolId?: string;
      readonly string?: number | null;
      readonly type?: DeviceErrorType;
    }>;
  } | null;
};
export type mutations_CreateDeviceMutation = {
  response: mutations_CreateDeviceMutation$data;
  variables: mutations_CreateDeviceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "device"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "device",
    "variableName": "device"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "string",
      "storageKey": null
    }
  ],
  "type": "DeviceGeneralProblem",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protocolId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    }
  ],
  "type": "DeviceProtocolProblem",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "integrationProblemType",
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "integrationAttribute",
      "storageKey": null
    }
  ],
  "type": "DeviceIntegrationProblem",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mutations_CreateDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addDevice",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mutations_CreateDeviceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addDevice",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "DeviceProblemResponse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8fd831ec7ca932991e2bf15d2d94a15f",
    "id": null,
    "metadata": {},
    "name": "mutations_CreateDeviceMutation",
    "operationKind": "mutation",
    "text": "mutation mutations_CreateDeviceMutation(\n  $device: DeviceIn!\n) {\n  addDevice(device: $device) {\n    __typename\n    ... on Device {\n      id\n    }\n    ... on DeviceProblemResponse {\n      problems {\n        __typename\n        ... on DeviceGeneralProblem {\n          type\n          string\n        }\n        ... on DeviceProtocolProblem {\n          protocolId\n          field\n        }\n        ... on DeviceIntegrationProblem {\n          integrationProblemType: type\n          integration\n          integrationAttribute\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d764daf8bc4542cb59a33efb8d87d59";

export default node;
