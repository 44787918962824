/**
 * @generated SignedSource<<b103d3e6e459a8fb5b3caacee9ba807f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTaskState = "Aborted" | "Failed" | "InProgress" | "Inconclusive" | "PartialFail" | "PartialPass" | "Passed" | "Scheduled" | "%future added value";
export type BatteryTestFailReason = "BatteryHealth" | "BatteryHealthUnknown" | "Busy" | "DeviceRemoved" | "DidNotStart" | "InsufficientReserveTime" | "InternalError" | "NoDischarge" | "NotEnoughData" | "SmartStartTimeout" | "SystemAbort" | "TestLockout" | "ThresholdReached" | "TooShort" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type TaskState = "Cancelled" | "Completed" | "InProgress" | "Scheduled" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StatusList_task$data = {
  readonly abortedTime: string | null;
  readonly cancelledTime: string | null;
  readonly completedTime: string | null;
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly dualPlaneCompanion: {
        readonly device: {
          readonly id: string;
        } | null;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly address: {
          readonly state: string;
        };
        readonly name: string;
      };
      readonly test: {
        readonly failReason: BatteryTestFailReason | null;
        readonly finalSoH: number | null;
        readonly id: string;
        readonly state: BatteryTestState;
      } | null;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly id: string;
  readonly overallState: TaskState;
  readonly testState: BatteryTaskState;
  readonly " $fragmentType": "StatusList_task";
};
export type StatusList_task$key = {
  readonly " $data"?: StatusList_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusList_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "deviceFilters"
    },
    {
      "kind": "RootArgument",
      "name": "filters"
    },
    {
      "kind": "RootArgument",
      "name": "id"
    },
    {
      "kind": "RootArgument",
      "name": "orderBy"
    },
    {
      "kind": "RootArgument",
      "name": "page"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusList_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overallState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "testState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelledTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "deviceFilters",
          "variableName": "deviceFilters"
        },
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        }
      ],
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "page",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "size",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNext",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPrevious",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Site",
              "kind": "LinkedField",
              "name": "site",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Address",
                  "kind": "LinkedField",
                  "name": "address",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "id"
                }
              ],
              "concreteType": "DeviceBatteryTestResults",
              "kind": "LinkedField",
              "name": "test",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "failReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "finalSoH",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DualPlaneCompanion",
              "kind": "LinkedField",
              "name": "dualPlaneCompanion",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Device",
                  "kind": "LinkedField",
                  "name": "device",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BatteryTest",
  "abstractKey": null
};
})();

(node as any).hash = "83ead6f7e3cfbf3fa20927c2742a290a";

export default node;
