import React, { FC, useCallback } from 'react';
import { fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { SiteExtraFilters, SiteTableColumn, SiteTableColumnId, useSiteFilter } from 'filters/site';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { getGlobalEnvironment } from 'lib/environment';

import { useSiteColumnDefinitions } from '../../../../lib/tables/site/columns';
import { useSiteTableQuery } from '../../../../lib/tables/site/request';
import { SiteDevice, SiteWithDevices } from '../../../../lib/tables/site/type';
import { DefaultTimeRange, SiteTableAllColumns, SiteTableBaseColumns, TimeRangeSearchParameter } from '../settings';
import {
    ACPowerSiteListSearchQuery,
    ACPowerSiteListSearchQuery$data,
} from './__generated__/ACPowerSiteListSearchQuery.graphql';

type SiteSearchResult = ACPowerSiteListSearchQuery$data['sites']['data'][number];

const TableStorageKeyPrefix = 'ac-power-sites-table';

export const ACPowerSiteList: FC = () => {
    useDocumentTitle('Reports - AC Power by site');

    const environment = getGlobalEnvironment();

    const columnDefinitions = useSiteColumnDefinitions({
        columns: SiteTableAllColumns,
    });

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumnId>({
        defaultSortColumn: SiteTableColumn.ACReliability,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: columnDefinitions,
        defaultVisibleColumns: SiteTableBaseColumns,
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useSiteFilter();

    const { data: props, error, retry, isFetching, fetchTable } = useSiteTableQuery(tableState, filters);

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<ACPowerSiteListSearchQuery>(
                environment,
                graphql`
                    query ACPowerSiteListSearchQuery($name: String = "", $pageSize: Int!) {
                        sites(
                            filters: { name: { value: $name } }
                            pageSize: $pageSize
                            onlyProvisioningStatuses: Active
                        ) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => (result?.sites.data as SiteSearchResult[]) ?? []);
        },
        [environment]
    );

    const generateSiteIdWithSearchParam = (siteId: string) => {
        const currentInterval = filters.extraFilters[SiteExtraFilters.ACReliabilityTimeRange] ?? DefaultTimeRange;
        return `${siteId}?${TimeRangeSearchParameter}=${currentInterval}`;
    };

    return (
        <TableLayout
            title='AC Power by Site'
            columns={columnDefinitions}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.sites.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: SiteWithDevices | SiteDevice) => row.id}
            page={props?.sites.pageInfo.page}
            pageCount={props?.sites.pageInfo.total}
            overallCount={props?.overallSites.total}
            resultCount={props?.sites.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by Site name'
            onSearch={handleSearch}
            renderSearchResult={(item: SiteSearchResult) => item.name}
            renderSearchResultAsString={(item: SiteSearchResult) => item.name}
            emptyMessage='There are no sites present'
            unit='Site'
            getItemLink={(item: SiteWithDevices | SiteDevice) => generateSiteIdWithSearchParam(item.id)}
            exportEnabled
            exportFilename='sites'
            exportFetchData={options => fetchTable(options).then(result => result.sites.data)}
        />
    );
};
