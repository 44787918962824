/**
 * @generated SignedSource<<0ee27ee324115fe7f6218993fe9dfa51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ACPowerDeviceListSearchQuery$variables = {
  name?: string | null;
  pageSize: number;
};
export type ACPowerDeviceListSearchQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type ACPowerDeviceListSearchQuery = {
  response: ACPowerDeviceListSearchQuery$data;
  variables: ACPowerDeviceListSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "name"
              }
            ],
            "kind": "ObjectValue",
            "name": "name"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "pageSize"
      }
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerDeviceListSearchQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ACPowerDeviceListSearchQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "241cc60a4d992a29db802879228681dc",
    "id": null,
    "metadata": {},
    "name": "ACPowerDeviceListSearchQuery",
    "operationKind": "query",
    "text": "query ACPowerDeviceListSearchQuery(\n  $name: String = \"\"\n  $pageSize: Int!\n) {\n  devices(filters: {name: {value: $name}}, pageSize: $pageSize, onlyProvisioningStatuses: Active) {\n    data {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3623f439560f8e0fb1f63d697b2a7d04";

export default node;
