import { TimeseriesOffsetPoint } from 'lib/dateChart';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { DateTime, Duration } from 'luxon';

export interface AggregatedDataPoint {
    readonly timestamp: unknown;
    readonly offset: unknown;
    readonly average: number | null;
    readonly stdDeviation?: number | null;
}

export type MetricsData = [
    TimeseriesOffsetPoint[] | null,
    TimeseriesOffsetPoint[] | null,
    TimeseriesOffsetPoint[] | null,
    TimeseriesOffsetPoint[] | null,
];

export type DualPlaneMetricsData = [
    [TimeseriesOffsetPoint[] | null, TimeseriesOffsetPoint[] | null],
    [TimeseriesOffsetPoint[] | null, TimeseriesOffsetPoint[] | null],
    [TimeseriesOffsetPoint[] | null, TimeseriesOffsetPoint[] | null],
    [TimeseriesOffsetPoint[] | null, TimeseriesOffsetPoint[] | null],
];

export function getDisplayInterval(start: DateTime, end: DateTime, ticks: number): Duration;
export function getDisplayInterval(start: Duration, end: Duration, ticks: number): Duration;
export function getDisplayInterval<T extends Duration | DateTime>(start: T, end: T, ticks: number): Duration {
    let totalDuration: number;
    if (DateTime.isDateTime(start)) {
        totalDuration = (end as DateTime).diff(start).as('seconds');
    } else {
        totalDuration = (end as Duration).as('seconds') - start.as('seconds');
    }

    const intervalInSeconds = totalDuration / ticks;

    if (intervalInSeconds < 1) {
        return Duration.fromObject({ seconds: 1 });
    }

    return Duration.fromObject({
        seconds: intervalInSeconds,
    });
}

export function spacedTicks(data: AggregatedDataPoint[] | undefined | null, tickCount = 20): string[] {
    if (!data) {
        return [];
    }

    if (data.length > tickCount) {
        const skip = Math.round(data.length / tickCount);
        data = data.filter((point, index, array) => {
            return index === 0 || index === array.length - 1 || (index % skip === 0 && index < array.length - skip);
        });
    }

    return data.map(point =>
        Duration.fromISO(point.offset as string)
            .as('minutes')
            .toFixed(0)
    );
}

export function formatAPIDate(date: unknown, fullMonth = true): string | null {
    if (!date) {
        return null;
    }

    return getDateTimeFormat(date as string, { fullMonth });
}

export function toTimeSeriesOffsetData(
    points: AggregatedDataPoint[] | null | undefined,
    convertNegative = false
): TimeseriesOffsetPoint[] | null {
    if (!points) {
        return null;
    }
    return points.flatMap(point => {
        if (point.average !== null) {
            let standardDeviation = point.stdDeviation;
            let average = point.average;
            if (convertNegative) {
                if (standardDeviation) {
                    standardDeviation = -standardDeviation;
                }
                average = -average;
            }

            return [
                {
                    timestamp: DateTime.fromISO(point.timestamp as string),
                    offset: Duration.fromISO(point.offset as string),
                    stdDeviation: standardDeviation,
                    value: average,
                },
            ];
        } else {
            return [];
        }
    });
}

export function spliceAllData(base: MetricsData, overlay: MetricsData): MetricsData {
    return [
        base[0] ? spliceData(base[0], overlay[0]) : null,
        base[1] ? spliceData(base[1], overlay[1]) : null,
        base[2] ? spliceData(base[2], overlay[2]) : null,
        base[3] ? spliceData(base[3], overlay[3]) : null,
    ];
}

function spliceData(base: TimeseriesOffsetPoint[], overlay: TimeseriesOffsetPoint[] | null): TimeseriesOffsetPoint[] {
    if (overlay === null || overlay.length === 0) {
        return base;
    }

    const start = overlay[0].offset;
    const end = overlay[overlay.length - 1].offset;

    const firstAfterOverlay = base.find(item => item.offset >= end);
    const baseWithGap = base.filter(item => (item.offset < start || item.offset >= end) && item !== firstAfterOverlay);

    const splicedData = baseWithGap
        .concat(overlay)
        .sort((a, b) => a.offset.as('milliseconds') - b.offset.as('milliseconds'));

    return splicedData;
}
