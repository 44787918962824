import {
    FilterDefinition,
    FilterValueMap,
    decodeDateRangeFilter,
    decodeRangeFilter,
    encodeDateRangeFilter,
    encodeRangeFilter,
} from '../common';
import { formatDateFilter, formatDurationFilter } from '../common/util';
import { decodeDeviceNameFilter, encodeDeviceNameFilter } from '../device';
import { CauseFilterUI } from './components/CauseFilterUI';
import { GeneratorReportDeviceNameFilterUI } from './components/GeneratorReportDeviceNameFilterUI';
import { FuelCapacityFilterUI } from './components/GeneratorReportFuelCapacityFilterUI';
import { GeneratorReportModelFilterUI } from './components/GeneratorReportModelFilterUI';
import { GeneratorReportSerialNumberFilterUI } from './components/GeneratorReportSerialNumberFilterUI';
import { RunTimeFilterUI } from './components/RunTimeFilterUI';
import { StateFilterUI } from './components/StateFilterUI';
import { TimeFilterUI } from './components/TimeFilterUI';
import { decodeCauseFilter, decodeRuntimeFilter, decodeStateFilter, decodeTextFilter } from './decode';
import { encodeCauseFilter, encodeRuntimeFilter, encodeStateFilter, encodeTextFilter } from './encode';
import {
    GeneratorRunReportCauseFilter,
    GeneratorRunReportDeviceNameFilter,
    GeneratorRunReportFuelCapacityFilter,
    GeneratorRunReportModelFilter,
    GeneratorRunReportSerialNumberFilter,
    GeneratorRunReportStateFilter,
    GeneratorRunReportTableColumn,
    GeneratorRunReportTimeFilter,
    GeneratorRunReportTotalRunTimeFilter,
} from './types';

export type GeneratorRunReportColumnFilterMap = {
    [GeneratorRunReportTableColumn.DeviceName]: GeneratorRunReportDeviceNameFilter[];
    [GeneratorRunReportTableColumn.CreatedTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.FinalisedTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.GeneratorStartTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.GeneratorStopTime]: GeneratorRunReportTimeFilter | null;
    [GeneratorRunReportTableColumn.TotalRunTime]: GeneratorRunReportTotalRunTimeFilter | null;
    [GeneratorRunReportTableColumn.State]: GeneratorRunReportStateFilter[];
    [GeneratorRunReportTableColumn.Cause]: GeneratorRunReportCauseFilter[];
    [GeneratorRunReportTableColumn.ModelName]: GeneratorRunReportModelFilter[];
    [GeneratorRunReportTableColumn.SerialNumber]: GeneratorRunReportSerialNumberFilter[];
    [GeneratorRunReportTableColumn.FuelCapacity]: GeneratorRunReportFuelCapacityFilter | null;
};

export type GeneratorRunReportAllFilterMap = GeneratorRunReportColumnFilterMap;

export const DefaultValues: FilterValueMap<GeneratorRunReportColumnFilterMap> = {
    [GeneratorRunReportTableColumn.DeviceName]: [],
    [GeneratorRunReportTableColumn.CreatedTime]: null,
    [GeneratorRunReportTableColumn.FinalisedTime]: null,
    [GeneratorRunReportTableColumn.GeneratorStartTime]: null,
    [GeneratorRunReportTableColumn.GeneratorStopTime]: null,
    [GeneratorRunReportTableColumn.TotalRunTime]: null,
    [GeneratorRunReportTableColumn.State]: [],
    [GeneratorRunReportTableColumn.Cause]: [],
    [GeneratorRunReportTableColumn.ModelName]: [],
    [GeneratorRunReportTableColumn.SerialNumber]: [],
    [GeneratorRunReportTableColumn.FuelCapacity]: null,
};

export const StaticGeneratorRunReportFilterDefinitions: FilterDefinition<GeneratorRunReportTableColumn>[] = [
    {
        id: GeneratorRunReportTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: GeneratorRunReportTableColumn.DeviceName,
        component: () => GeneratorReportDeviceNameFilterUI,
        describeValue: (value: GeneratorRunReportDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: GeneratorRunReportTableColumn.State,
        type: 'multi',
        name: 'State',
        category: 'State',
        column: GeneratorRunReportTableColumn.State,
        component: () => StateFilterUI,
        describeValue: (value: GeneratorRunReportStateFilter) => value.name,
        encodeValue: encodeStateFilter,
        decodeValue: decodeStateFilter,
    },
    {
        id: GeneratorRunReportTableColumn.Cause,
        type: 'multi',
        name: 'Cause',
        category: 'Cause',
        column: GeneratorRunReportTableColumn.Cause,
        component: () => CauseFilterUI,
        describeValue: (value: GeneratorRunReportCauseFilter) => value.name,
        encodeValue: encodeCauseFilter,
        decodeValue: decodeCauseFilter,
    },
    {
        id: GeneratorRunReportTableColumn.TotalRunTime,
        type: 'single',
        name: 'Run Time',
        category: 'Run Time',
        column: GeneratorRunReportTableColumn.TotalRunTime,
        component: () => RunTimeFilterUI,
        describeValue: formatDurationFilter,
        encodeValue: encodeRuntimeFilter,
        decodeValue: decodeRuntimeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.GeneratorStartTime,
        type: 'single',
        name: 'Start Time',
        category: 'Start Time',
        column: GeneratorRunReportTableColumn.GeneratorStartTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.CreatedTime,
        type: 'single',
        name: 'Created Time',
        category: 'Created Time',
        column: GeneratorRunReportTableColumn.CreatedTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FinalisedTime,
        type: 'single',
        name: 'Finalised Time',
        category: 'Finalised Time',
        column: GeneratorRunReportTableColumn.FinalisedTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.GeneratorStopTime,
        type: 'single',
        name: 'Stop Time',
        category: 'Stop Time',
        column: GeneratorRunReportTableColumn.GeneratorStopTime,
        component: () => TimeFilterUI,
        describeValue: (value: GeneratorRunReportTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: GeneratorRunReportTableColumn.ModelName,
        type: 'multi',
        name: 'Model Name',
        category: 'Model Name',
        column: GeneratorRunReportTableColumn.ModelName,
        component: () => GeneratorReportModelFilterUI,
        describeValue: (value: GeneratorRunReportModelFilter) => value.value,
        encodeValue: encodeTextFilter,
        decodeValue: decodeTextFilter,
    },
    {
        id: GeneratorRunReportTableColumn.SerialNumber,
        type: 'multi',
        name: 'Serial Number',
        category: 'Serial Number',
        column: GeneratorRunReportTableColumn.SerialNumber,
        component: () => GeneratorReportSerialNumberFilterUI,
        describeValue: (value: GeneratorRunReportSerialNumberFilter) => value.value,
        encodeValue: encodeTextFilter,
        decodeValue: decodeTextFilter,
    },
    {
        id: GeneratorRunReportTableColumn.FuelCapacity,
        type: 'single',
        name: 'Fuel Capacity',
        category: 'Fuel Capacity',
        column: GeneratorRunReportTableColumn.FuelCapacity,
        component: () => FuelCapacityFilterUI,
        describeValue: (value: GeneratorRunReportFuelCapacityFilter) => `${value.min}L - ${value.max}L`,
        encodeValue: encodeRangeFilter,
        decodeValue: decodeRangeFilter,
    },
];
