/**
 * @generated SignedSource<<ba257e364a01aaf2cdef072bc5e3411d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type IncidentCountsChart_acPower$data = {
  readonly deviceAcIncidentStatusDistribution: ReadonlyArray<{
    readonly device: {
      readonly id: string;
      readonly name: string;
    };
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly key: ACPowerEventStatus;
    }>;
  }> | null;
  readonly " $fragmentType": "IncidentCountsChart_acPower";
};
export type IncidentCountsChart_acPower$key = {
  readonly " $data"?: IncidentCountsChart_acPower$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncidentCountsChart_acPower">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "timeRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IncidentCountsChart_acPower",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "timeRange",
          "variableName": "timeRange"
        }
      ],
      "concreteType": "DeviceACIncidentStatusDistribution",
      "kind": "LinkedField",
      "name": "deviceAcIncidentStatusDistribution",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceACIncidentCount",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SiteACPower",
  "abstractKey": null
};

(node as any).hash = "c0d1f88ce11eb8ef1f8053c3ac18d43e";

export default node;
