/**
 * @generated SignedSource<<66b5ec4ac60034248f5914e0d35d9f0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunParameterView_data$data = {
  readonly generatorInfo: {
    readonly fuelCapacity: number | null;
    readonly modelName: string | null;
    readonly serialNumber: string | null;
  };
  readonly id: string;
  readonly note: {
    readonly content: string;
  } | null;
  readonly " $fragmentType": "RunParameterView_data";
};
export type RunParameterView_data$key = {
  readonly " $data"?: RunParameterView_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunParameterView_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunParameterView_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneratorRunReportGeneratorInfo",
      "kind": "LinkedField",
      "name": "generatorInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "modelName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serialNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "unit",
              "value": "Liter"
            }
          ],
          "kind": "ScalarField",
          "name": "fuelCapacity",
          "storageKey": "fuelCapacity(unit:\"Liter\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNote",
      "kind": "LinkedField",
      "name": "note",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};

(node as any).hash = "12c4b6a9636b4adf6505e400f568769c";

export default node;
