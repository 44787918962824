import React, { FC, useState } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';

import { GeneratorRunReportCard_data$key } from './__generated__/GeneratorRunReportCard_data.graphql';
import { RunParameterView, RunReportHeader, RunReportResultDisplay } from './components';
import style from './style.module.css';

export interface GeneratorRunReportCardProps {
    runReport: GeneratorRunReportCard_data$key;
}

export const GeneratorRunReportCard: FC<GeneratorRunReportCardProps> = ({ runReport }) => {
    const [showTestDetails, setShowTestDetails] = useState(false);
    const data = useFragment<GeneratorRunReportCard_data$key>(GeneratorRunReportCardFragment, runReport);

    return (
        <div className={classNames('bg-white', style.main_area)}>
            <div className='pt-8'>
                <RunReportHeader runReport={data} />
                {showTestDetails && <RunParameterView runReport={data} />}
                <div className='flex justify-end px-8 font-normal hover:underline'>
                    <button
                        className='flex items-center space-x-2 text-sm'
                        onClick={() => setShowTestDetails(!showTestDetails)}
                    >
                        {showTestDetails ? 'Hide' : 'Show'} Details
                    </button>
                </div>
                <div className={style.header_horizontal_rule}></div>
            </div>
            <div className='p-8 space-y-8'>
                <RunReportResultDisplay runReport={data} />
            </div>
        </div>
    );
};

const GeneratorRunReportCardFragment = graphql`
    fragment GeneratorRunReportCard_data on GeneratorRunReport @argumentDefinitions(chartPoints: { type: "Int" }) {
        ...RunReportHeader_data
        ...RunParameterView_data
        ...RunReportResultDisplay_data @arguments(chartPoints: $chartPoints)
    }
`;
