/**
 * @generated SignedSource<<c32337dc55771a48a56ff590e112c32b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeviceIncidentExpandedRow_event$data = {
  readonly affectedAllFeeds: boolean;
  readonly affectedFeeds: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly status: ACPowerEventStatus;
    readonly voltage: number | null;
  }>;
  readonly device: {
    readonly id: string;
    readonly name: string;
    readonly site: {
      readonly id: string;
    };
  };
  readonly duration: string;
  readonly endTime: string;
  readonly id: string;
  readonly startTime: string;
  readonly stats: {
    readonly batteryDidDischarge: boolean;
    readonly batteryDoD: number | null;
    readonly loadUptime: number | null;
  };
  readonly worstStatus: ACPowerEventStatus;
  readonly " $fragmentType": "DeviceIncidentExpandedRow_event";
};
export type DeviceIncidentExpandedRow_event$key = {
  readonly " $data"?: DeviceIncidentExpandedRow_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentExpandedRow_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceIncidentExpandedRow_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worstStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affectedAllFeeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerEventFeed",
      "kind": "LinkedField",
      "name": "affectedFeeds",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ACPowerEventStats",
      "kind": "LinkedField",
      "name": "stats",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "batteryDidDischarge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "batteryDoD",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loadUptime",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ACPowerEvent",
  "abstractKey": null
};
})();

(node as any).hash = "0f99f606968102e1c203189b67daf4c2";

export default node;
