/**
 * @generated SignedSource<<a367e100286911d2a031d0eaae19353c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type DualPlaneConfiguration = "NPlusOne" | "TwoN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeaderDetailContent_device$data = {
  readonly acPower: {
    readonly feeds: {
      readonly data: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly status: DeviceHealth;
      }>;
      readonly total: number;
    };
  };
  readonly dualPlaneCompanion: {
    readonly configuration: DualPlaneConfiguration | null;
    readonly device: {
      readonly id: string;
      readonly name: string;
    } | null;
  } | null;
  readonly type: {
    readonly displayName: string;
  };
  readonly " $fragmentType": "HeaderDetailContent_device";
};
export type HeaderDetailContent_device$key = {
  readonly " $data"?: HeaderDetailContent_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderDetailContent_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderDetailContent_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DualPlaneCompanion",
      "kind": "LinkedField",
      "name": "dualPlaneCompanion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "configuration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceACPower",
      "kind": "LinkedField",
      "name": "acPower",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaginatedACFeeds",
          "kind": "LinkedField",
          "name": "feeds",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ACFeed",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "0591c80f005dcbd03f0c9bfaa5c097a6";

export default node;
