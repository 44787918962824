/**
 * @generated SignedSource<<73e9610d45e3c30d0a6f51c029c06305>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery$variables = {
  input: string;
  maxResults: number;
};
export type GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery$data = {
  readonly generatorRunReports: {
    readonly data: ReadonlyArray<{
      readonly generator: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly address: {
            readonly state: string;
          };
          readonly id: string;
          readonly name: string;
        };
      };
      readonly id: string;
    }>;
  };
};
export type GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery = {
  response: GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery$data;
  variables: GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxResults"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "value",
                "variableName": "input"
              }
            ],
            "kind": "ObjectValue",
            "name": "deviceName"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      },
      {
        "kind": "Variable",
        "name": "pageSize",
        "variableName": "maxResults"
      }
    ],
    "concreteType": "PaginatedGeneratorRunReport",
    "kind": "LinkedField",
    "name": "generatorRunReports",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneratorRunReport",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "generator",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "site",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2391abb07e6a7b7f69cb3074ceb483b5",
    "id": null,
    "metadata": {},
    "name": "GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery",
    "operationKind": "query",
    "text": "query GeneratorReportDeviceNameFilterUI_deviceNameSearchQuery(\n  $input: String!\n  $maxResults: Int!\n) {\n  generatorRunReports(filters: {deviceName: {value: $input}}, pageSize: $maxResults) {\n    data {\n      id\n      generator {\n        id\n        name\n        site {\n          id\n          name\n          address {\n            state\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "58732ffcd6ec8cb113f1dac5e59a1ea3";

export default node;
