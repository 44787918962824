/**
 * @generated SignedSource<<dbc72f3812fcfa1de30ef8e62bebd642>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type ACPowerSiteIncidentListTableExportQuery$variables = {
  siteId: string;
};
export type ACPowerSiteIncidentListTableExportQuery$data = {
  readonly site: {
    readonly acPower: {
      readonly events: {
        readonly data: ReadonlyArray<{
          readonly affectedDevices: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          }>;
          readonly affectedFeeds: ReadonlyArray<{
            readonly label: string;
            readonly status: ACPowerEventStatus;
            readonly voltage: number | null;
          }>;
          readonly duration: string;
          readonly site: {
            readonly id: string;
            readonly name: string;
          };
          readonly startTime: string;
          readonly worstStatus: ACPowerEventStatus;
        }>;
      };
    };
  } | null;
};
export type ACPowerSiteIncidentListTableExportQuery = {
  response: ACPowerSiteIncidentListTableExportQuery$data;
  variables: ACPowerSiteIncidentListTableExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10000
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worstStatus",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voltage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "affectedDevices",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerSiteIncidentListTableExportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "PaginatedSiteACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ACPowerEventFeed",
                        "kind": "LinkedField",
                        "name": "affectedFeeds",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "events(pageSize:10000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ACPowerSiteIncidentListTableExportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "PaginatedSiteACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ACPowerEventFeed",
                        "kind": "LinkedField",
                        "name": "affectedFeeds",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "events(pageSize:10000)"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d743d4a19bf306a60b4804452a319458",
    "id": null,
    "metadata": {},
    "name": "ACPowerSiteIncidentListTableExportQuery",
    "operationKind": "query",
    "text": "query ACPowerSiteIncidentListTableExportQuery(\n  $siteId: ID!\n) {\n  site(id: $siteId) {\n    acPower {\n      events(pageSize: 10000) {\n        data {\n          duration\n          startTime\n          worstStatus\n          site {\n            id\n            name\n          }\n          affectedFeeds {\n            label\n            status\n            voltage\n            id\n          }\n          affectedDevices {\n            id\n            name\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8d4d7cf1d1ac297a51f39cc5a6a992ed";

export default node;
