/**
 * @generated SignedSource<<1d38cb269b488c6978245b3a3ee26e87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ActivityLogSource = "Alert" | "AuditLog" | "GridEvent" | "%future added value";
export type ActivityLogType = "AlertSeverityCritical" | "AlertSeverityIndeterminate" | "AlertSeverityMajor" | "AlertSeverityMinor" | "AlertSeverityWarning" | "BatteryTest" | "DeviceAdd" | "DeviceEdit" | "DeviceRemove" | "GridEventStatusHigh" | "GridEventStatusLow" | "GridEventStatusOffline" | "%future added value";
export type AlertSeverity = "Critical" | "Indeterminate" | "Major" | "Minor" | "Warning" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GatewayOverview_device$data = {
  readonly activityLogs: {
    readonly data: ReadonlyArray<{
      readonly changes: ReadonlyArray<{
        readonly field: string;
        readonly newValue: string | null;
        readonly oldValue: string | null;
      }> | null;
      readonly link: {
        readonly __typename: "Alert";
        readonly category: string;
        readonly device: {
          readonly id: string;
          readonly name: string;
        };
        readonly isActive: boolean;
        readonly message: string;
        readonly severity: AlertSeverity;
      } | {
        readonly __typename: "Device";
        readonly id: string;
        readonly name: string;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null;
      readonly source: ActivityLogSource;
      readonly timestamp: string;
      readonly type: ActivityLogType;
      readonly user: {
        readonly name: string | null;
        readonly username: string;
      } | null;
    }>;
    readonly hasMore: boolean;
    readonly total: number;
  };
  readonly connectionSettings: {
    readonly addresses: ReadonlyArray<string>;
  };
  readonly id: string;
  readonly model: string | null;
  readonly site: {
    readonly id: string;
  };
  readonly " $fragmentType": "GatewayOverview_device";
};
export type GatewayOverview_device$key = {
  readonly " $data"?: GatewayOverview_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"GatewayOverview_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GatewayOverview_device",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "model",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConnectionSettings",
      "kind": "LinkedField",
      "name": "connectionSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addresses",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 5
        },
        {
          "kind": "Literal",
          "name": "types",
          "value": [
            "DeviceAdd",
            "DeviceEdit",
            "AlertSeverityCritical",
            "AlertSeverityMajor",
            "GridEventStatusOffline",
            "GridEventStatusHigh",
            "BatteryTest"
          ]
        }
      ],
      "concreteType": "PaginatedActivityLogs",
      "kind": "LinkedField",
      "name": "activityLogs",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityLog",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "source",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChangeFieldValue",
              "kind": "LinkedField",
              "name": "changes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "field",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "oldValue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "newValue",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "link",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v2/*: any*/),
                  "type": "Device",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "severity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "message",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isActive",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Device",
                      "kind": "LinkedField",
                      "name": "device",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Alert",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "activityLogs(limit:5,types:[\"DeviceAdd\",\"DeviceEdit\",\"AlertSeverityCritical\",\"AlertSeverityMajor\",\"GridEventStatusOffline\",\"GridEventStatusHigh\",\"BatteryTest\"])"
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "f95f0bd3425e072139d2279e5f20f465";

export default node;
