import React from 'react';
import { useFragment } from 'react-relay';

import { Change, SmallSparkline, TimeseriesPoint } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { CommonPropsWithParentQuery, asChange } from '../common';
import { TemperatureSparkline_battery$key } from './__generated__/TemperatureSparkline_battery.graphql';

export function TemperatureSparkline({ metrics }: CommonPropsWithParentQuery): JSX.Element {
    const result = useFragment<TemperatureSparkline_battery$key>(
        graphql`
            fragment TemperatureSparkline_battery on DeviceBatteryMetrics {
                latestTemperature
                temperature {
                    values {
                        timestamp
                        value
                    }
                    trend {
                        state
                    }
                }
            }
        `,
        metrics
    );

    const data = result?.temperature ?? {
        trend: {
            state: 'Flat',
        },
        values: [],
    };

    const history: TimeseriesPoint[] = data.values.map(value => ({
        date: new Date(value.timestamp as string),
        value: value.value,
    }));

    return (
        <SmallSparkline
            background='dark'
            history={history}
            value={result?.latestTemperature ?? null}
            unit='°C'
            title='Battery temperature'
            change={data.trend ? asChange(data.trend.state) : Change.Flat}
        />
    );
}
