/**
 * @generated SignedSource<<5c89c760137141eb38a7c45fd4c74669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TestResultHeader_test$data = {
  readonly abortedBy: {
    readonly email: string | null;
    readonly name: string | null;
  } | null;
  readonly abortedTime: string | null;
  readonly cause: BatteryTestReason;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly name: string;
  };
  readonly id: string;
  readonly name: string | null;
  readonly state: BatteryTestState;
  readonly task: {
    readonly createdTime: string;
    readonly creator: {
      readonly email: string | null;
      readonly name: string | null;
    };
    readonly id: string;
    readonly name: string | null;
    readonly schedule: {
      readonly time: string;
    };
  } | null;
  readonly " $fragmentType": "TestResultHeader_test";
};
export type TestResultHeader_test$key = {
  readonly " $data"?: TestResultHeader_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestResultHeader_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestResultHeader_test",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSchedule",
          "kind": "LinkedField",
          "name": "schedule",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "time",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "abortedBy",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "b133d104098a95eb27ca1d5d81b7f4f8";

export default node;
