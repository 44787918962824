import React, { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'react-relay';

import { CrossIcon, FormikDropdownWithSearch, IconButton, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import classNames from 'classnames';
import { SUGGESTIONS_LIMIT } from 'lib/provision';

import { queries_GetSiteNamesQuery } from '../../../__generated__/queries_GetSiteNamesQuery.graphql';
import { getSiteNamesQuery } from '../../../queries';
import { FormControlAction, FormControlActionTypes, FormControlParameters } from '../lib/reducer';
import { SiteReference } from '../schema';
import style from '../style.module.css';

export interface SiteSelectorProps {
    // form control
    formControlState: FormControlParameters;
    formControlDispatch: Dispatch<FormControlAction>;
}

export const SiteSelector: FC<SiteSelectorProps> = ({ formControlState, formControlDispatch }) => {
    const { show } = useToast();

    const environment = useRelayEnvironment();
    const [siteReferences, setSiteReferences] = useState<SiteReference[]>([]);
    const [siteListError, setSiteListError] = useState<string | null>(null);

    const handleSearch = useCallback(
        (search: string, force = false) => {
            if (search.length === 0 && !force) {
                return;
            }

            fetchQuery<queries_GetSiteNamesQuery>(environment, getSiteNamesQuery, {
                search,
                limit: SUGGESTIONS_LIMIT,
            }).subscribe({
                next(value) {
                    const siteReferences = value.sites.data.map<SiteReference>(site => ({
                        id: site.id,
                        displayName: site.name,
                    }));
                    setSiteReferences(siteReferences);
                    setSiteListError(null);
                },
                error(error: unknown) {
                    captureException(error);
                    show({ variant: 'error', text: 'Failed to get sites' });
                    setSiteReferences([]);
                    setSiteListError('Unable to search at this time');
                },
            });
        },
        [environment, show]
    );

    useEffect(() => {
        // Pre-populate search results, so there is something to select by default.
        // TODO: it would be better to show the most recently selected options instead of just any
        handleSearch('', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={classNames('col-start-1', formControlState.addNewSite ? style.page_item_disabled : '')}>
                <FormikDropdownWithSearch<SiteReference>
                    name='site'
                    placeHolder={'Site*'}
                    light
                    disabledText='Adding New Site'
                    disabled={formControlState.addNewSite}
                    required={!formControlState.addNewSite}
                    renderItemAsString={item => item.displayName}
                    items={siteReferences}
                    onSearch={handleSearch}
                    variant='outlined'
                    testId='site-name'
                    noResultsText={search => {
                        if (siteListError) {
                            return siteListError;
                        }

                        if (search.length > 0) {
                            return 'No matching sites';
                        }

                        return 'No sites available';
                    }}
                />
            </div>

            <div className='flex items-end col-start-2'>
                <IconButton
                    title={formControlState.addNewSite ? 'Use Existing Site' : 'New Site'}
                    size='small'
                    onClick={() =>
                        formControlDispatch({
                            type: FormControlActionTypes.AddNewSite,
                            payload: !formControlState.addNewSite,
                        })
                    }
                    icon={formControlState.addNewSite ? <CrossIcon /> : undefined}
                />
            </div>
        </>
    );
};
