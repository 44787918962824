export type MetricsLineType = 'current' | 'voltage' | 'power' | 'temperature' | 'load';

export function getUnit(type: MetricsLineType): string {
    switch (type) {
        case 'current':
            return 'A';
        case 'voltage':
            return 'V';
        case 'power':
            return 'W';
        case 'temperature':
            return '°C';
        case 'load':
            return 'A';
        default:
            return '';
    }
}
