/**
 * @generated SignedSource<<0a7d3828b970bf636c5d148f7c57eb0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneratorRunReportEditResult = "InvalidReport" | "InvalidValue" | "Success" | "%future added value";
export type RunReportHeaderUpdateNameMutation$variables = {
  id: string;
  name?: string | null;
};
export type RunReportHeaderUpdateNameMutation$data = {
  readonly editGeneratorRunReportName: GeneratorRunReportEditResult;
};
export type RunReportHeaderUpdateNameMutation = {
  response: RunReportHeaderUpdateNameMutation$data;
  variables: RunReportHeaderUpdateNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ScalarField",
    "name": "editGeneratorRunReportName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunReportHeaderUpdateNameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunReportHeaderUpdateNameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5f5c1ccc9ab233c22bdf7605c7c39e03",
    "id": null,
    "metadata": {},
    "name": "RunReportHeaderUpdateNameMutation",
    "operationKind": "mutation",
    "text": "mutation RunReportHeaderUpdateNameMutation(\n  $id: ID!\n  $name: String\n) {\n  editGeneratorRunReportName(id: $id, name: $name)\n}\n"
  }
};
})();

(node as any).hash = "fa5bf72d57027c79f20fa5a7a92823f5";

export default node;
