import { fetchQuery } from 'react-relay';

import { exportAsCsv } from 'lib/csv-export';
import { getGlobalEnvironment } from 'lib/environment';
import { formatBatteryTestName, getNiceStatusName } from 'lib/textFormatters';
import { DateTime, Duration } from 'luxon';

import { CompareBatteryTestResultsQuery } from './test-compare/CompareBatteryTestResultsContent';
import { CompareBatteryTestResultsContentQuery } from './test-compare/__generated__/CompareBatteryTestResultsContentQuery.graphql';

export enum CompareTestExportError {
    NoTestIds = 'No test IDs provided',
    NoTestResults = 'No test results found',
}

type Row = string[];
type HeaderAndRow = {
    header: HeaderField[];
    row: Row;
};

enum HeaderField {
    testId = 'Test ID',
    testName = 'Test Name',
    testDate = 'Test Date',

    status = 'Status',
    stateOfHealth = 'State of Health (%)',
    estimatedReserveTime = 'Estimated Reserve Time (minutes)',
    estimatedCapacity = 'Estimated Capacity (Ah)',
    estimatedCapacityPerString = 'Estimated Capacity per string (Ah)',
    runTime = 'Run Time (minutes)',
    dischargeCause = 'Discharge Cause',
    minimumVoltage = 'Minimum Voltage (V)',
    averageLoad = 'Average Load (A)',
    averagePower = 'Average Power (W)',
    averageTemperature = 'Average Temperature (°C)',
    depthOfDischarge = 'Depth of Discharge (%)',
    dischargedValue = 'Discharged Value (Ah)',
    deviceId = 'Device ID',
    deviceName = 'Device Name',
    deviceType = 'Device Type',
    siteId = 'Site ID',
    siteName = 'Site Name',
    siteState = 'Site State',
    installedStrings = 'Installed Strings',
    strings = 'Strings',
    averageAge = 'Average Age (months)',
    oldestAge = 'Oldest Age (months)',
    totalDesignCapacity = 'Total Design Capacity (Ah)',
    designReserveTime = 'Design Reserve Time (minutes)',
}

/**
 *
 * @param testIds TestResult document IDs
 *
 * @returns A CSV file with the test results
 *
 * The csv file should contain the following columns:
 *
 * **Basic Information**
 * - Test ID
 * - Test Name
 * - Test Date
 *
 * **Results**
 * - Status
 * - State of Health (%)
 * - Estimated Reserve Time (minutes)
 * - Estimated Capacity (Ah)
 * - Estimated Capacity per string (Ah)
 *
 * **Key Information**
 * - Run Time (minutes)
 * - Discharge Cause
 * - Minimum Voltage (V)
 * - Average Load (A)
 * - Average Power (W)
 * - Average Temperature (°C)
 * - Depth of Discharge (%)
 * - Discharged Value (Ah)
 *
 * **General Information**
 * - Device ID
 * - Device Name
 * - Device Type
 * - Site ID
 * - Site Name
 * - Site State
 *
 *  **Battery Information**
 * - Installed Strings
 * - Strings (commma separated)
 * - Average Age (months)
 * - Oldest Age (months)
 * - Total Design Capacity (Ah)
 * - Design Reserve Time (minutes)
 *
 */
export const exportTestResults = async (testIds: string[], filename: string) => {
    if (testIds.length === 0) {
        throw new Error(CompareTestExportError.NoTestIds);
    }

    const data = await fetchTestResults(testIds);

    const rowsWithHeader: HeaderAndRow[] = data.map(test => {
        let runTime;
        if (test.completedTime && test.commencedTime) {
            runTime = DateTime.fromISO(test.completedTime)
                .diff(DateTime.fromISO(test.commencedTime), 'minutes')
                .as('minutes');
        }
        const estimatedCapacity =
            test.estimatedCapacity?.value !== undefined ? test.estimatedCapacity?.value.toString() : '';
        const estimatedCapacityPerString =
            test.estimatedCapacity?.value !== undefined
                ? `${test.estimatedCapacity.value / test.batteryStrings.length}`
                : '';

        const averageAgeToPresent: Duration | null =
            test.device.battery.averageAge != null ? Duration.fromISO(test.device.battery.averageAge) : null;
        const commencedTimeToPresent: Duration | null =
            test.commencedTime != null ? DateTime.now().diff(DateTime.fromISO(test.commencedTime)) : null;
        const averageAgeCappedToCommencedTime =
            averageAgeToPresent && commencedTimeToPresent
                ? `${averageAgeToPresent.minus(commencedTimeToPresent).as('months')}`
                : '';

        const oldestInstallDate = test.device.battery.strings.strings
            .filter(string => string.installDate)
            .map(string => DateTime.fromISO(string.installDate!))
            .reduce((oldestInstallDate, currentInstallDate) => {
                if (oldestInstallDate < currentInstallDate) {
                    return oldestInstallDate;
                }
                return currentInstallDate;
            });
        const oldestAgeCappedToCommencedTime =
            test.commencedTime != null
                ? `${DateTime.fromISO(test.commencedTime).diff(oldestInstallDate).as('months')}`
                : '';

        const testTransformed: Record<HeaderField, string> = {
            [HeaderField.testId]: test.id,
            [HeaderField.testName]: test.name ?? test.task?.name ?? '',
            [HeaderField.testDate]: test.commencedTime ?? '',
            [HeaderField.status]: getNiceStatusName(test.state),
            [HeaderField.stateOfHealth]:
                test.estimatedStateOfHealth?.value !== undefined ? test.estimatedStateOfHealth?.value.toString() : '',
            [HeaderField.estimatedReserveTime]:
                test.estimatedReserveTime?.value !== undefined ? test.estimatedReserveTime?.value.toString() : '',
            [HeaderField.estimatedCapacity]: estimatedCapacity,
            [HeaderField.estimatedCapacityPerString]: estimatedCapacityPerString,
            [HeaderField.runTime]: runTime !== undefined ? runTime.toString() : '',
            [HeaderField.dischargeCause]: formatBatteryTestName(test.cause),
            [HeaderField.minimumVoltage]: test.finalVoltage !== null ? test.finalVoltage.toString() : '',
            [HeaderField.averageLoad]: test.averageCurrent !== null ? test.averageCurrent.toString() : '',
            [HeaderField.averagePower]: test.averagePower !== null ? test.averagePower.toString() : '',
            [HeaderField.averageTemperature]:
                test.averageTemperature !== null ? test.averageTemperature.toString() : '',
            [HeaderField.depthOfDischarge]: test.depthOfDischarge !== null ? test.depthOfDischarge.toString() : '',
            [HeaderField.dischargedValue]: test.discharged !== null ? test.discharged.toString() : '',
            [HeaderField.deviceId]: test.device.id,
            [HeaderField.deviceName]: test.device.name,
            [HeaderField.deviceType]: test.device.type.displayName,
            [HeaderField.siteId]: test.device.site.id,
            [HeaderField.siteName]: test.device.site.name,
            [HeaderField.siteState]: test.device.site.address.state,
            [HeaderField.installedStrings]: test.batteryStrings.length.toString(),
            [HeaderField.strings]: test.batteryStrings
                .map(
                    batteryString =>
                        `${batteryString.string}.${batteryString.type.manufacturer} ${batteryString.type.model}`
                )
                .join(','),
            [HeaderField.averageAge]: averageAgeCappedToCommencedTime, // FIXME: This is not a snapshot, its based on the device and not the test
            [HeaderField.oldestAge]: oldestAgeCappedToCommencedTime, // FIXME: This is not a snapshot, its based on the device and not the test
            [HeaderField.totalDesignCapacity]: test.device.battery.metrics.originalCapacity // FIXME: This is not a snapshot, its based on the device and not the test
                ? test.device.battery.metrics.originalCapacity.toString()
                : '',
            // FIXME: This is not a snapshot, its based on the device and not the test
            [HeaderField.designReserveTime]:
                test.device.battery.reserveTime !== null ? test.device.battery.reserveTime.toString() : '',
        };

        const header: HeaderField[] = [];
        const row: Row = [];
        for (const key in testTransformed) {
            header.push(key as HeaderField);
            row.push(testTransformed[key as HeaderField]);
        }

        return {
            header,
            row,
        };
    });

    if (rowsWithHeader.length === 0) {
        throw new Error(CompareTestExportError.NoTestResults);
    }

    const header = rowsWithHeader[0].header;
    const rows = rowsWithHeader.map(row => row.row);

    exportAsCsv(filename, header, rows);
};
function fetchTestResults(testIds: string[]) {
    return fetchQuery<CompareBatteryTestResultsContentQuery>(getGlobalEnvironment(), CompareBatteryTestResultsQuery, {
        ids: testIds,
    })
        .toPromise()
        .then(data => data?.batteryTestResults.data ?? []);
}
