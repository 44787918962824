/**
 * @generated SignedSource<<fab1df5185b7b2a63a87fb0aa0345506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTestFailReason = "BatteryHealth" | "BatteryHealthUnknown" | "Busy" | "DeviceRemoved" | "DidNotStart" | "InsufficientReserveTime" | "InternalError" | "NoDischarge" | "NotEnoughData" | "SmartStartTimeout" | "SystemAbort" | "TestLockout" | "ThresholdReached" | "TooShort" | "%future added value";
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestTypeIncludingUnplanned = "Capacity" | "Custom" | "Quick" | "Unplanned" | "%future added value";
export type TestResultFilter = {
  batteryStringCount?: IntRangeFilter | null;
  cause?: ReadonlyArray<BatteryTestReason> | null;
  currentMetrics?: FloatRangeFilter | null;
  deviceId?: ReadonlyArray<StringFilter> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  dischargedMetrics?: FloatRangeFilter | null;
  estimatedCapacity?: FloatRangeFilter | null;
  estimatedReserveTime?: FloatRangeFilter | null;
  estimatedStateOfHealth?: FloatRangeFilter | null;
  failReason?: ReadonlyArray<BatteryTestFailReason> | null;
  runTime?: DurationFilter | null;
  startTime?: DateRangeFilter | null;
  state?: ReadonlyArray<BatteryTestState> | null;
  taskName?: ReadonlyArray<StringFilter> | null;
  taskType?: ReadonlyArray<BatteryTestTypeIncludingUnplanned> | null;
  temperatureMetrics?: FloatRangeFilter | null;
  voltageMetrics?: FloatRangeFilter | null;
};
export type StringFilter = {
  value: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type TestSelectModalContentAllIdsQuery$variables = {
  filters?: TestResultFilter | null;
  ids?: ReadonlyArray<string> | null;
};
export type TestSelectModalContentAllIdsQuery$data = {
  readonly batteryTestResults: {
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type TestSelectModalContentAllIdsQuery = {
  response: TestSelectModalContentAllIdsQuery$data;
  variables: TestSelectModalContentAllIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      },
      {
        "kind": "Literal",
        "name": "pageSize",
        "value": 10000
      }
    ],
    "concreteType": "PaginatedDeviceBatteryTestResults",
    "kind": "LinkedField",
    "name": "batteryTestResults",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestSelectModalContentAllIdsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TestSelectModalContentAllIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1ea30e2f4d87a0d4d9863b41b0941416",
    "id": null,
    "metadata": {},
    "name": "TestSelectModalContentAllIdsQuery",
    "operationKind": "query",
    "text": "query TestSelectModalContentAllIdsQuery(\n  $ids: [String!]\n  $filters: TestResultFilter\n) {\n  batteryTestResults(ids: $ids, pageSize: 10000, filters: $filters) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "12776d46e5cfd0bd2e5aa7245259624b";

export default node;
