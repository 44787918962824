import { round } from 'lib/number';

export function numberToLocaleString(input: number | null, maximumFractionDigits = 2): string {
    if (input === null) {
        return '-';
    } else {
        return input.toLocaleString(undefined, {
            maximumFractionDigits: maximumFractionDigits,
            minimumFractionDigits: 0,
        });
    }
}

export function percentageToStringCapped(input: number | null): string {
    if (input == null) {
        return '-%';
    }

    if (input >= 100) {
        return '100%';
    } else if (input > 99) {
        return '>99%';
    } else if (input === 0) {
        return '0%';
    } else if (input < 1) {
        return '<1%';
    } else {
        return input.toFixed(0) + '%';
    }
}

export function wattToKillowattString(watt: number | null | undefined): string {
    if (watt && watt !== 0) {
        if (watt >= 1000) {
            return `${round(watt / 1000, 1)}kW`;
        } else {
            return `${Math.round(watt)}W`;
        }
    } else {
        return '-W';
    }
}

/**
 *
 * @param value The value (in base unit). null value will be displayed as '-'
 * @param unit the unit of the value
 *
 * @example
 * displayKiloUnitMetrics(1000, 'W') => '1kW'
 * displayKiloUnitMetrics(100, 'W') => '100W'
 * displayKiloUnitMetrics(null, 'W') => '-W'
 */
export function handleKiloUnitMetricsDisplay(value: number | null, unit: string) {
    if (value === null) {
        return `-${unit}`;
    }

    if (value >= 1000) {
        return `${round(value / 1000, 1)}k${unit}`;
    } else {
        return `${Math.round(value)}${unit}`;
    }
}
