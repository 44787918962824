import React, { FC, useEffect, useState } from 'react';
import { Environment, PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { getGlobalEnvironment } from 'lib/environment';

import { getColumnCountForWindowSize, getLargeSectionLoadSize, getSmallSectionLoadSize } from '../../lib/grid-layout';
import { GeneratorRunTaskOverviewContentQuery } from './__generated__/GeneratorRunTaskOverviewContentQuery.graphql';
import { CompletedGeneratorRuns, InProgressGeneratorRuns } from './sections';

export interface GeneratorRunTaskOverviewContentProps {
    preloadedQuery: PreloadedQuery<GeneratorRunTaskOverviewContentQuery>;
    reload: (smallSize: number, largeSize: number) => void;
}

export const GeneratorRunTaskOverviewContent: FC<GeneratorRunTaskOverviewContentProps> = ({
    preloadedQuery,
    reload,
}) => {
    const data = usePreloadedQuery(ContentQuery, preloadedQuery);

    const [smallSectionSize, setSmallSectionSize] = useState(() =>
        getSmallSectionLoadSize(getColumnCountForWindowSize(window.innerWidth))
    );

    const [largeSectionSize, setLargeSectionSize] = useState(() =>
        getLargeSectionLoadSize(getColumnCountForWindowSize(window.innerWidth))
    );

    useEffect(() => {
        let debounceTimer: NodeJS.Timeout | null = null;

        const handleWindowResize = () => {
            const visibleColumns = getColumnCountForWindowSize(window.innerWidth);

            const newSmallSize = getSmallSectionLoadSize(visibleColumns);
            const newLargeSize = getLargeSectionLoadSize(visibleColumns);

            let needsReload = false;

            setSmallSectionSize(oldSmallSize => {
                if (newSmallSize > oldSmallSize) {
                    needsReload = true;
                }
                return newSmallSize;
            });
            setLargeSectionSize(oldLargeSize => {
                if (newLargeSize > oldLargeSize) {
                    needsReload = true;
                }
                return newLargeSize;
            });

            if (needsReload) {
                if (debounceTimer) {
                    clearTimeout(debounceTimer);
                }

                // Wait until the user has stopped resizing the window
                debounceTimer = setTimeout(() => {
                    reload(newSmallSize, newLargeSize);
                    debounceTimer = null;
                }, 500);
            }
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [reload]);

    return (
        <div className='space-y-6'>
            <div>
                <div className='flex justify-between items-center'>
                    <PageHeading value='Generator Runs' />
                </div>

                <div className='mt-8'>
                    <InProgressGeneratorRuns maxItems={smallSectionSize} tasks={data.inProgressGeneratorRuns} />
                    <CompletedGeneratorRuns maxItems={largeSectionSize} tasks={data.completedGeneratorRuns} />
                </div>
            </div>
        </div>
    );
};

export const ContentQuery = graphql`
    query GeneratorRunTaskOverviewContentQuery($smallSectionSize: Int!, $largeSectionSize: Int!) {
        inProgressGeneratorRuns: generatorRunReports(
            pageSize: $smallSectionSize
            filters: { state: [Running, Finishing, Analysing] }
            orderBy: { field: GeneratorStartTime, dir: Desc }
        ) {
            ...InProgressGeneratorRuns_tasks
        }
        completedGeneratorRuns: generatorRunReports(
            pageSize: $largeSectionSize
            filters: { state: [Completed, Error] }
            orderBy: { field: GeneratorStartTime, dir: Desc }
        ) {
            ...CompletedGeneratorRuns_tasks
        }
    }
`;

export function loadGeneratorRunTasksQuery(environment?: Environment) {
    const columnCount = getColumnCountForWindowSize(window.innerWidth);
    const smallSectionSize = getSmallSectionLoadSize(columnCount);
    const largeSectionSize = getLargeSectionLoadSize(columnCount);

    return loadQuery(
        environment ?? getGlobalEnvironment(),
        ContentQuery,
        {
            largeSectionSize: largeSectionSize,
            smallSectionSize: smallSectionSize,
        },
        { fetchPolicy: 'store-and-network' }
    );
}

export function fetchGeneratorRunTasksQuery(environment: Environment) {
    const columnCount = getColumnCountForWindowSize(window.innerWidth);
    const smallSectionSize = getSmallSectionLoadSize(columnCount);
    const largeSectionSize = getLargeSectionLoadSize(columnCount);

    return fetchQuery(
        environment,
        ContentQuery,
        {
            largeSectionSize: largeSectionSize,
            smallSectionSize: smallSectionSize,
        },
        { fetchPolicy: 'network-only' }
    );
}
