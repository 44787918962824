import React, { ReactElement, ReactNode, useCallback, useState } from 'react';

import { ArrowLeftIcon, FilterContainer } from '@accesstel/pcm-ui';

import { SelectionList } from './SelectionList';

export interface MultipleSelectProps<T> {
    title?: string;
    variant?: 'standard' | 'shadowed';
    options: T[];
    active?: T[];
    onClear: () => void;
    onSubmit?: (items: T[]) => void;
    onClose?: () => void;
    renderItem: (item: T) => ReactNode;
    renderItemSimple: (item: T) => string;
    areItemsEqual?: (itemA: T, itemB: T) => boolean;
    showBack?: boolean;
    /**
     * Display a custom node in the clear button position
     */
    clearButton?: ReactNode;
    /**
     * Custom rule on when to hide the clear button
     */
    overwriteHideClearButton?: (selectedItems: T[]) => boolean;
    /**
     * Enforce a minimum number of items to be selected.
     * Any toggle that goes beyond the threshold will do nothing
     * Has a default value of 0
     */
    minItemSelected?: number;
}

export function MultipleSelect<T>({
    title,
    variant,
    options,
    active,
    onClear,
    onSubmit,
    onClose,
    renderItem,
    renderItemSimple,
    areItemsEqual = (itemA, itemB) => itemA === itemB,
    showBack,
    clearButton,
    overwriteHideClearButton,
    minItemSelected = 0,
}: MultipleSelectProps<T>): ReactElement {
    const [selectedItems, setSelectedItems] = useState<T[]>(active ?? []);

    const toggleItem = useCallback(
        (item: T) => {
            setSelectedItems(items => {
                const existingIndex = items.findIndex(existing => areItemsEqual(item, existing));
                if (existingIndex < 0) {
                    return [...items, item];
                } else {
                    const filteredItems = items.filter((_, index) => index !== existingIndex);
                    return filteredItems.length >= minItemSelected ? filteredItems : items;
                }
            });
        },
        [areItemsEqual, minItemSelected]
    );

    const handleConfirmClick = useCallback(() => {
        if (!onSubmit) {
            return;
        }

        onSubmit(selectedItems);
    }, [onSubmit, selectedItems]);

    let customClearButton: ReactNode;
    if (showBack && selectedItems.length === 0) {
        customClearButton = (
            <div className='w-4 h-4 hover:text-coralRegular'>
                <ArrowLeftIcon />
            </div>
        );
    }

    return (
        <FilterContainer
            title={title ?? ''}
            variant={variant}
            customButton={clearButton ?? customClearButton}
            onClearClick={onClear}
            onConfirmClick={handleConfirmClick}
            onClose={onClose}
            primaryContent={
                <SelectionList
                    items={options}
                    selectedItems={selectedItems}
                    onSelect={toggleItem}
                    renderItem={renderItem}
                    renderItemSimple={renderItemSimple}
                />
            }
            hideClearButton={
                overwriteHideClearButton
                    ? overwriteHideClearButton(selectedItems)
                    : selectedItems.length === 0 && !showBack
            }
            hideConfirmButton={selectedItems.length === 0 && (!active || active.length === 0)}
        />
    );
}
