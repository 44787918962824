/**
 * @generated SignedSource<<05ab0c1bb6a6b212ca24a13e45d842b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricUnit = "AmpHours" | "Amps" | "Celsius" | "Degree" | "Hertz" | "Hours" | "Litres" | "LitresPerHour" | "Minutes" | "Pascals" | "Percent" | "RPM" | "VoltAmps" | "VoltAmpsReactive" | "Volts" | "WattHours" | "Watts" | "%future added value";
export type MetricsExploreContentQuery$variables = {
  deviceIds?: ReadonlyArray<string> | null;
};
export type MetricsExploreContentQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly metricTypes: ReadonlyArray<{
    readonly displayName: string;
    readonly metric: string;
    readonly unit: MetricUnit | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SelectMetricsPaneFragment">;
};
export type MetricsExploreContentQuery = {
  response: MetricsExploreContentQuery$data;
  variables: MetricsExploreContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceIds"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "aggregateable",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "devices",
      "variableName": "deviceIds"
    }
  ],
  "concreteType": "DefinedMetric",
  "kind": "LinkedField",
  "name": "metricTypes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metric",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "ids",
          "variableName": "deviceIds"
        }
      ],
      "kind": "ObjectValue",
      "name": "filters"
    }
  ],
  "concreteType": "PaginatedDevices",
  "kind": "LinkedField",
  "name": "devices",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "data",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetricsExploreContentQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SelectMetricsPaneFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetricsExploreContentQuery",
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "c2a0185b67295d96cb913a8a1b3b2f7d",
    "id": null,
    "metadata": {},
    "name": "MetricsExploreContentQuery",
    "operationKind": "query",
    "text": "query MetricsExploreContentQuery(\n  $deviceIds: [ID!]\n) {\n  metricTypes(aggregateable: true, devices: $deviceIds) {\n    metric\n    displayName\n    unit\n  }\n  devices(filters: {ids: $deviceIds}) {\n    data {\n      id\n      name\n    }\n  }\n  ...SelectMetricsPaneFragment\n}\n\nfragment SelectMetricsPaneFragment on Query {\n  metricTypes(aggregateable: true, devices: $deviceIds) {\n    metric\n    displayName\n    unit\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e526e54debb7ba8628fc2d60643b252";

export default node;
