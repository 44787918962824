import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import {
    CircleAlertIcon,
    CirclePlayIcon,
    CircleStopIcon,
    Menu,
    MetricDisplay,
    NavigateTarget,
    PowermeterIcon,
    RadioIcon,
    RpmIcon,
    StackedHorizontalBar,
    StraightAngleGauge,
    Theme,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { useUserPermissions } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { round } from 'lib/number';
import { handleKiloUnitMetricsDisplay } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { formatGeneratorRunModeToString, formatGeneratorState } from 'lib/textFormatters';
import { DateTime, Duration } from 'luxon';
import { Subscription } from 'relay-runtime';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';
import { SiteViewPagePath } from 'views/sites/paths';

import { DevicePane } from '../../components/DevicePane';
import { WrappedDevicePlaceholder } from '../../components/WrappedDevicePlaceholder';
import { GeneratorPageDeviceCategories } from '../../lib';
import { DefaultTimeRange, LiveDataRefreshInterval } from '../../settings';
import { GeneratorContentQuery, GeneratorRunMode } from './__generated__/GeneratorContentQuery.graphql';
import { GeneratorFuelSection, GeneratorLineCard, GeneratorPowerChart, GeneratorVoltageChart } from './components';
import style from './style.module.css';

type Device = NonNullable<GeneratorContentQuery['response']['device']>;

export interface GeneratorContentProps {
    queryRef: PreloadedQuery<GeneratorContentQuery>;
    timeRange: Duration;
    deviceId: string;
}

const MAX_FUEL_RUNTIME_FALLBACK = 480; // 480 hours = 20 days

export const GeneratorContent: FC<GeneratorContentProps> = ({ queryRef, timeRange, deviceId }) => {
    const data = usePreloadedQuery(ContentQuery, queryRef);
    const environment = useRelayEnvironment();
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();
    const navigate = useExtendedNavigate();

    // Live refresh the data
    useEffect(() => {
        // NOTE: The built-in poll mechanism does not support changing the variables
        let timeout: NodeJS.Timeout;
        let currentSubscription: Subscription | undefined;

        function poll() {
            currentSubscription = fetchQuery<GeneratorContentQuery>(
                environment,
                ContentQuery,
                {
                    id: deviceId,
                    begin: DateTime.local().minus(DefaultTimeRange).toISO(),
                    end: DateTime.local().toISO(),
                },
                { fetchPolicy: 'network-only' }
            ).subscribe({
                complete: () => {
                    timeout = setTimeout(poll, LiveDataRefreshInterval);
                },
            });
        }

        timeout = setTimeout(poll, LiveDataRefreshInterval);

        return () => {
            clearTimeout(timeout);
            currentSubscription?.unsubscribe();
        };
    }, [deviceId, environment, timeRange]);

    useEffect(() => {
        if (!data.device) {
            return;
        }

        if (
            data.device.type.category !== '%future added value' &&
            !GeneratorPageDeviceCategories.includes(data.device.type.category)
        ) {
            // try to navigate to a generator on site
            const siteGenerators = data.device.site.generators.data;
            if (siteGenerators.length > 0) {
                navigate(
                    {
                        pathname: Paths.SiteViewViewSiteDevicePage,
                        params: {
                            siteId: data.device.site.id,
                            deviceId: siteGenerators[0].id,
                            page: SiteViewPagePath.Generator,
                        },
                    },
                    { replace: true }
                );
            }
        }
        // NOTE: This effect should only run once at the start to renavigate if the device is not a power controller
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data.device) {
        // NOTE: This should never happen as the device is validated by the DeviceLayout
        return null;
    }

    if (
        data.device.type.category !== '%future added value' &&
        !GeneratorPageDeviceCategories.includes(data.device.type.category)
    ) {
        // site has no generator, show a placeholder to add a generator
        const url: NavigateTarget = {
            pathname: Paths.AddDevice,
            search: {
                site: data.device.site.id,
            },
        };

        return (
            <div className='grid grid-cols-1 gap-4 min-h-96'>
                <WrappedDevicePlaceholder
                    message='No generator on this site'
                    buttonText='Add Generator'
                    disabledButtonMessage='You do not have permission to add a device'
                    navigateTo={url}
                />
            </div>
        );
    }

    const gateway = data.device.connectionSettings.protocols?.at(0)?.gateway;

    const generator: Device = data.device;
    const generatorStateText = formatGeneratorState(generator.generatorMetrics.latestState);
    const generatorOperationMode = generator.generatorMetrics.latestOperationMode
        ? `${generator.generatorMetrics.latestOperationMode} mode`
        : null;
    const generatorRunMode = formatGeneratorRunModeToString(generator.generatorMetrics.latestRunMode);
    const generatorOutputPower = handleKiloUnitMetricsDisplay(generator.generatorMetrics.output.latestPower, 'W');
    const generatorIdleHours = generator.generatorMetrics.idleTime?.percentage
        ? round((24 * generator.generatorMetrics.idleTime.percentage) / 100)
        : 0;
    const generatorRemoteStartState =
        generator.generatorMetrics.latestRemoteStartState === null
            ? 'Remote Start Unknown'
            : generator.generatorMetrics.latestRemoteStartState
              ? 'Remote Start Enabled'
              : 'Remote Start Disabled';

    const coolantMetricsLevel =
        generator.generatorMetrics.latestCoolantLevel !== null
            ? `${generator.generatorMetrics.latestCoolantLevel.toFixed()}%`
            : '-%';
    const coolantMetricsTemperature =
        generator.generatorMetrics.latestCoolantTemperature !== null
            ? `${generator.generatorMetrics.latestCoolantTemperature.toFixed()}°C`
            : '-°C';
    const coolantMetricsPressure =
        generator.generatorMetrics.latestCoolantPressure !== null
            ? `${generator.generatorMetrics.latestCoolantPressure.toFixed()}psi`
            : '-psi';

    const oilMetricsTemperature =
        generator.generatorMetrics.latestOilTemperature !== null
            ? `${generator.generatorMetrics.latestOilTemperature.toFixed()}°C`
            : '-°C';
    const oilMetricsPressure =
        generator.generatorMetrics.latestOilPressure !== null
            ? `${generator.generatorMetrics.latestOilPressure.toFixed()}psi`
            : '-psi';

    const engineMetricsTotalRunTime =
        generator.generatorMetrics.latestEngineRunTime !== null
            ? `${generator.generatorMetrics.latestEngineRunTime.toFixed()}hrs`
            : '-hrs';

    const engineMetricsTripRunTime =
        generator.generatorMetrics.latestTripRunTime !== null
            ? `${generator.generatorMetrics.latestTripRunTime.toFixed()}hrs`
            : '-hrs';

    const fuelRuntimeMax =
        generator.fuelTankMetrics.capacity !== null && generator.generatorMetrics.latestFuelConsumption
            ? generator.fuelTankMetrics.capacity / generator.generatorMetrics.latestFuelConsumption
            : null;
    const fuelRuntimeLeft =
        generator.fuelTankMetrics.latestAmount !== null && generator.generatorMetrics.latestFuelConsumption
            ? generator.fuelTankMetrics.latestAmount - generator.generatorMetrics.latestFuelConsumption
            : null;

    const menuItems = [
        {
            name: hasAssetsWrite ? 'Edit generator' : 'View generator',
            onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
            disabled: !hasAssetsRead,
        },
        {
            name: hasAssetsWrite ? 'Edit gateway' : 'View gateway',
            onClick: () => (gateway ? navigate({ pathname: Paths.EditDevice, params: { id: gateway.id } }) : null),
            disabled: !gateway || !hasAssetsRead,
        },
    ];

    const navigateToMetrics = (metric: string) => {
        const to = makeLinkToMetric(deviceId, { metric, op: Operation.Average });
        navigate(to);
    };

    const getGeneratorStateColor = () => {
        switch (generator.generatorMetrics.latestState) {
            case 'CoolingDown':
                return 'text-mustardRegular';
            case 'Idle':
                return 'text-eggplantRegular';
            case 'WarmingUp':
                return 'text-lapisRegular';
            case 'Running':
            case 'RunningOnLoad':
            case '%future added value':
            default:
                return 'text-coralRegular';
        }
    };

    return (
        <DevicePane
            title={generator.name}
            subtitle={generator.type.displayName}
            health={generator.health}
            lastUpdate={generator.lastUpdate}
            lastOnline={generator.lastOnline}
        >
            <div className='space-y-4'>
                <div className='flex flex-row justify-between'>
                    <h1 className='font-bold text-xl'>Generator</h1>
                    <div className='flex flex-row gap-4'>
                        <div className={`font-bold text-lg ${getGeneratorStateColor()}`}>
                            {generatorStateText} {generatorOperationMode ? `(${generatorOperationMode})` : ''}
                        </div>
                        <div>
                            <Menu id={`device-menu-${deviceId}`} menuItems={menuItems} variant='small' />
                        </div>
                    </div>
                </div>
                <StackedHorizontalBar
                    data={[
                        { label: 'Idle', value: generatorIdleHours, bgClass: 'bg-eggplantRegular' },
                        { label: 'Running', value: 24 - generatorIdleHours, bgClass: 'bg-coralRegular' },
                    ]}
                    valueFormatter={value => `${value} hours`}
                    emptyLabel='No data available'
                    sort={false}
                />
                <div className='flex flex-row justify-start gap-8 mb-2'>
                    <IconWithStatus
                        title='Power Output'
                        icon={<PowermeterIcon />}
                        label={generatorOutputPower}
                        link={makeLinkToMetric(deviceId, {
                            metric: 'GeneratorOutputTotalPower',
                            op: Operation.Average,
                        })}
                    />
                    <IconWithStatus title='Remote Start' icon={<RadioIcon />} label={generatorRemoteStartState} />
                    <IconWithStatus
                        title='Run Mode'
                        icon={generatorRunModeIcon(generator.generatorMetrics.latestRunMode)}
                        label={generatorRunMode}
                    />
                    <IconWithStatus
                        title='Engine RPM'
                        icon={<RpmIcon />}
                        label={
                            generator.generatorMetrics.latestRPM !== null
                                ? `${generator.generatorMetrics.latestRPM}RPM`
                                : '-RPM'
                        }
                        link={makeLinkToMetric(deviceId, { metric: 'GeneratorRPM', op: Operation.Average })}
                    />
                </div>
                <div className='grid grid-cols-2 lg:grid-cols-4'>
                    <div>
                        <div className='text-md font-semibold'>Fuel level</div>
                        <div className={style.chart_container}>
                            <StraightAngleGauge
                                thresholds={[33, 66]}
                                min={0}
                                max={100}
                                unit='%'
                                value={generator.fuelTankMetrics.latestLevel}
                                subtitle={
                                    generator.fuelTankMetrics.capacity !== null
                                        ? `out of ${generator.fuelTankMetrics.capacity.toFixed()} litres`
                                        : undefined
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <div className='text-md font-semibold'>Runtime until empty</div>
                        <div className={style.chart_container}>
                            <StraightAngleGauge
                                thresholds={
                                    fuelRuntimeMax
                                        ? [fuelRuntimeMax / 3, fuelRuntimeMax * (2 / 3)]
                                        : [MAX_FUEL_RUNTIME_FALLBACK / 3, MAX_FUEL_RUNTIME_FALLBACK * (2 / 3)]
                                }
                                min={0}
                                max={fuelRuntimeMax ?? MAX_FUEL_RUNTIME_FALLBACK}
                                unit='hours'
                                value={fuelRuntimeLeft}
                                subtitle={fuelRuntimeMax ? `out of ${fuelRuntimeMax.toFixed(1)} hours` : ''}
                                significantDigits={2}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='text-md font-semibold'>RPM</div>
                        <div className={style.chart_container}>
                            <StraightAngleGauge
                                thresholds={[1000, 2000]}
                                min={0}
                                max={3000}
                                unit='RPM'
                                value={generator.generatorMetrics.latestRPM}
                                regionColours={[Theme.grayRegular, Theme.grayRegular, Theme.grayRegular]}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='text-md font-semibold'>Starter battery</div>
                        <div className={style.chart_container}>
                            <StraightAngleGauge
                                min={0}
                                max={100}
                                unit='V'
                                value={generator.battery.metrics.latestVoltage}
                                thresholds={[33, 66]}
                                regionColours={[Theme.grayRegular, Theme.grayRegular, Theme.grayRegular]}
                                maximumFractionDigits={1}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className='font-bold pb-1'>Lines</h2>
                    <div className='grid grid-cols-3 gap-4'>
                        {generator.generatorMetrics.output.phase1 ? (
                            <GeneratorLineCard
                                deviceId={deviceId}
                                lineNumber={1}
                                fragmentRef={generator.generatorMetrics.output.phase1}
                            />
                        ) : (
                            <div className='bg-grayRegular flex flex-col min-h-64 p-2 space-y-2'>
                                <div className='text-md font-bold'>Line 1</div>
                                <div className='h-full text-center flex flex-col justify-center'>
                                    <span className='text-coralRegular'>No data available</span>
                                </div>
                            </div>
                        )}
                        {generator.generatorMetrics.output.phase2 ? (
                            <GeneratorLineCard
                                deviceId={deviceId}
                                lineNumber={2}
                                fragmentRef={generator.generatorMetrics.output.phase2}
                            />
                        ) : (
                            <div className='bg-grayRegular flex flex-col min-h-64 p-2 space-y-2'>
                                <div className='text-md font-bold'>Line 2</div>
                                <div className='h-full text-center flex flex-col justify-center'>
                                    <span className='text-coralRegular'>No data available</span>
                                </div>
                            </div>
                        )}
                        {generator.generatorMetrics.output.phase3 ? (
                            <GeneratorLineCard
                                deviceId={deviceId}
                                lineNumber={3}
                                fragmentRef={generator.generatorMetrics.output.phase3}
                            />
                        ) : (
                            <div className='bg-grayRegular flex flex-col min-h-64 p-2 space-y-2'>
                                <div className='text-md font-bold'>Line 3</div>
                                <div className='h-full text-center flex flex-col justify-center'>
                                    <span className='text-coralRegular'>No data available</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='grid grid-cols-1 gap-4 xl:grid-cols-2'>
                    <GeneratorVoltageChart fragmentRef={generator.generatorMetrics.output} />
                    <GeneratorPowerChart fragmentRef={generator.generatorMetrics.output} />
                </div>
                <div className='grid grid-cols-2 gap-8'>
                    {/* TODO: PCM-2469 add generator capacity (component: <GeneratorCapacitySection />) */}

                    <div>
                        <h1 className='font-bold text-xl'>Engine oil</h1>
                        <div className='flex flex-row space-x-4'>
                            <MetricDisplay
                                valueOnClick={() => navigateToMetrics('GeneratorOilTemperature')}
                                size='large'
                                label='Oil temperature'
                                value={oilMetricsTemperature}
                            />
                            <MetricDisplay
                                valueOnClick={() => navigateToMetrics('GeneratorOilPressure')}
                                size='large'
                                label='Oil pressure'
                                value={oilMetricsPressure}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className='font-bold text-xl'>Run hours</h1>
                        <div className='flex flex-row space-x-4'>
                            <MetricDisplay
                                size='large'
                                label='Trip run hours'
                                value={engineMetricsTripRunTime}
                                valueOnClick={() => navigateToMetrics('GeneratorTripRunHours')}
                            />
                            <MetricDisplay
                                size='large'
                                label='Total run hours'
                                value={engineMetricsTotalRunTime}
                                valueOnClick={() => navigateToMetrics('GeneratorEngineRunHours')}
                            />
                        </div>
                    </div>
                    <div>
                        <h1 className='font-bold text-xl'>Coolant</h1>
                        <div className='flex flex-row space-x-4'>
                            <MetricDisplay
                                size='large'
                                label='Coolant level'
                                value={coolantMetricsLevel}
                                valueOnClick={() => navigateToMetrics('GeneratorCoolantLevel')}
                            />
                            <MetricDisplay
                                size='large'
                                label='Coolant temperature'
                                value={coolantMetricsTemperature}
                                valueOnClick={() => navigateToMetrics('GeneratorCoolantTemperature')}
                            />
                            <MetricDisplay
                                size='large'
                                label='Coolant pressure'
                                value={coolantMetricsPressure}
                                valueOnClick={() => navigateToMetrics('GeneratorCoolantPressure')}
                            />
                        </div>
                    </div>
                    <GeneratorFuelSection
                        deviceId={deviceId}
                        capacity={generator.fuelTankMetrics.capacity}
                        remaining={generator.fuelTankMetrics.latestAmount}
                        consumption={generator.generatorMetrics.latestFuelConsumption}
                    />
                </div>
            </div>
        </DevicePane>
    );
};

export const ContentQuery = graphql`
    query GeneratorContentQuery($id: ID!, $begin: Timestamp!, $end: Timestamp!) {
        device(id: $id) {
            id
            name
            health
            lastUpdate
            lastOnline
            type {
                displayName
                category
            }
            site {
                id
                generators: devices(filters: { category: [Generator] }) {
                    data {
                        id
                    }
                }
            }
            connectionSettings {
                protocols {
                    ... on ProtocolGateway {
                        gateway {
                            id
                        }
                    }
                }
            }

            fuelTankMetrics {
                latestLevel
                latestAmount
                capacity
            }

            generatorMetrics {
                latestOperationMode
                latestState
                latestRunMode
                latestEngineRunTime
                latestTripRunTime
                latestRemoteStartState
                idleTime {
                    percentage
                }

                latestRPM
                output {
                    latestPower
                    phase1 {
                        ...GeneratorLineCard_metrics
                    }
                    phase2 {
                        ...GeneratorLineCard_metrics
                    }
                    phase3 {
                        ...GeneratorLineCard_metrics
                    }
                    ...GeneratorVoltageChart_metrics
                    ...GeneratorPowerChart_metrics
                }

                latestFuelConsumption

                latestCoolantLevel
                latestCoolantTemperature
                latestCoolantPressure(unit: PSI)

                latestOilTemperature
                latestOilPressure(unit: PSI)

                latestEngineRunTime
                latestTripRunTime
            }

            battery {
                metrics {
                    latestVoltage
                }
            }
        }
    }
`;

export function loadGeneratorPageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        ContentQuery,
        {
            id,
            begin: DateTime.local().minus(DefaultTimeRange).toISO(),
            end: DateTime.local().toISO(),
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}

function generatorRunModeIcon(runMode: GeneratorRunMode | null) {
    if (!runMode) {
        return <CircleAlertIcon />;
    }

    switch (runMode) {
        case 'NotRunning':
            return <CircleStopIcon />;
        case 'EmergencyRun':
        case 'IndeterminateRun':
        case 'MaintenanceRun':
        case 'MalfunctionRun':
        case 'TestRun':
            return <CirclePlayIcon />;
        case '%future added value':
        default:
            return <CircleAlertIcon />;
    }
}
