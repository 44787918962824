/**
 * @generated SignedSource<<85438986f7f25ac0f4079ab2ee19f98e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type ACPowerEventFilter = {
  activeWithin?: DateRangeFilter | null;
  affectedFeeds?: ReadonlyArray<StringFilter> | null;
  affectsAllFeeds?: BooleanFilter | null;
  deviceId?: ReadonlyArray<string> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  duration?: DurationFilter | null;
  endTime?: DateRangeFilter | null;
  isSignificant?: BooleanFilter | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  startTime?: DateRangeFilter | null;
  status?: ReadonlyArray<ACPowerEventStatusFilter> | null;
  voltage?: FloatRangeFilter | null;
  worstStatus?: ReadonlyArray<ACPowerEventStatusFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type ACPowerEventStatusFilter = {
  value: ACPowerEventStatus;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type ACPowerDeviceIncidentListTableExportQuery$variables = {
  filter?: ACPowerEventFilter | null;
};
export type ACPowerDeviceIncidentListTableExportQuery$data = {
  readonly acEvents: {
    readonly data: ReadonlyArray<{
      readonly affectedFeeds: ReadonlyArray<{
        readonly label: string;
        readonly status: ACPowerEventStatus;
        readonly voltage: number | null;
      }>;
      readonly device: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly id: string;
          readonly name: string;
        };
      };
      readonly duration: string;
      readonly startTime: string;
      readonly worstStatus: ACPowerEventStatus;
    }>;
    readonly total: number;
  };
};
export type ACPowerDeviceIncidentListTableExportQuery = {
  response: ACPowerDeviceIncidentListTableExportQuery$data;
  variables: ACPowerDeviceIncidentListTableExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filter"
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10000
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voltage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worstStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerDeviceIncidentListTableExportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedACPowerEvent",
        "kind": "LinkedField",
        "name": "acEvents",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ACPowerEvent",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ACPowerEventFeed",
                "kind": "LinkedField",
                "name": "affectedFeeds",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ACPowerDeviceIncidentListTableExportQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedACPowerEvent",
        "kind": "LinkedField",
        "name": "acEvents",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ACPowerEvent",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ACPowerEventFeed",
                "kind": "LinkedField",
                "name": "affectedFeeds",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7aed74fd9acd51f9bf265671aae557d7",
    "id": null,
    "metadata": {},
    "name": "ACPowerDeviceIncidentListTableExportQuery",
    "operationKind": "query",
    "text": "query ACPowerDeviceIncidentListTableExportQuery(\n  $filter: ACPowerEventFilter\n) {\n  acEvents(pageSize: 10000, filters: $filter) {\n    total\n    data {\n      startTime\n      affectedFeeds {\n        label\n        status\n        voltage\n        id\n      }\n      device {\n        id\n        name\n        site {\n          id\n          name\n        }\n      }\n      duration\n      worstStatus\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbcb46265c548d34cf56133e9c75b4c3";

export default node;
