/**
 * @generated SignedSource<<47adffe1a4e570017e84794a30c9cec4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceReliabilityChart_query$data = {
  readonly deviceACReliabilityDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly key: number;
      readonly percentileEnd: number;
      readonly percentileStart: number;
    }>;
  } | null;
  readonly " $fragmentType": "DeviceReliabilityChart_query";
};
export type DeviceReliabilityChart_query$key = {
  readonly " $data"?: DeviceReliabilityChart_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceReliabilityChart_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceReliabilityChart_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "buckets",
          "value": 11
        },
        {
          "kind": "Literal",
          "name": "timeRange",
          "value": "Last6Months"
        }
      ],
      "concreteType": "DeviceACReliabilityDistribution",
      "kind": "LinkedField",
      "name": "deviceACReliabilityDistribution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceACReliabilityDistributionBucket",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileStart",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileEnd",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "deviceACReliabilityDistribution(buckets:11,timeRange:\"Last6Months\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a748f2bcf8224f13a3d66907a53e3781";

export default node;
