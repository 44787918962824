import React, { FC } from 'react';

import { Domain, DurationLineChart, LineChartSeries } from '@accesstel/pcm-ui';

import { numberToLocaleString } from 'lib/numberFormatters';
import { Duration } from 'luxon';

import { GeneratorRunReportState } from './__generated__/RunReportResultDisplay_data.graphql';

type LineChartDataType = {
    average: number | null;
    min: number | null;
    max: number | null;
    latest: number | null;
};

export interface LineChartWithMetricsProps {
    title: string;
    series: LineChartSeries<Duration>[];
    unit: string;
    data: LineChartDataType;
    yDomain?: Domain<number>;
    type: 'consumption' | 'fuelLevel';
    state: GeneratorRunReportState;
}

export const LineChartWithMetrics: FC<LineChartWithMetricsProps> = ({
    title,
    series,
    unit,
    data,
    yDomain,
    type,
    state,
}) => {
    return (
        <div data-testid={`${title.replaceAll(' ', '-').toLowerCase()}-metrics-chart`}>
            <div className='pb-4'>{title}</div>
            <div className='flex flex-col lg:flex-row space-x-4'>
                <div className='w-1/2 lg:w-1/4 pb-2 lg:pb-0'>
                    <div className='grid grid-rows-3 grid-cols-2 gap-x-2'>
                        {type === 'consumption' ? (
                            <>
                                <div className='font-light'>Average</div>
                                <div>
                                    {numberToLocaleString(data.average, 1)}
                                    {unit}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='font-light'>{getFuelLevelMetricTitle(state)}</div>
                                <div>
                                    {numberToLocaleString(data.latest, 1)}
                                    {unit}
                                </div>
                            </>
                        )}

                        <div className='font-light'>Minimum</div>
                        <div>
                            {numberToLocaleString(data.min, 1)}
                            {unit}
                        </div>
                        <div className='font-light'>Maximum</div>
                        <div>
                            {numberToLocaleString(data.max, 1)}
                            {unit}
                        </div>
                    </div>
                </div>
                <DurationLineChart
                    series={series}
                    legend={false}
                    aspectRatio={5}
                    unit={unit}
                    end
                    start
                    axisUnits
                    xDomain={['min', 'max']}
                    formatTooltipValue={value => `${numberToLocaleString(value, 1)}${unit}`}
                    yTicks={5}
                    xTicks={15}
                    yDomain={yDomain}
                />
            </div>
        </div>
    );
};

function getFuelLevelMetricTitle(state: GeneratorRunReportState) {
    if (state === 'Completed' || state === 'Error') {
        return 'Final';
    } else {
        return 'Current';
    }
}
