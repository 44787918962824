/**
 * @generated SignedSource<<02125d162b67c139b4fc79ab84f3a32b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BatteryOverviewQuery$variables = {};
export type BatteryOverviewQuery$data = {
  readonly batteryMetrics: {
    readonly remainingCapacityHistogram: {
      readonly " $fragmentSpreads": FragmentRefs<"BatteryCapacityHisto_remainingCapacityHistogram">;
    } | null;
    readonly timeRemainingHistogram: {
      readonly " $fragmentSpreads": FragmentRefs<"BatteryTimeRemainingHisto_timeRemainingHistogram">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CurrentCapacityDoughnut" | "CurrentStatusDoughnut">;
};
export type BatteryOverviewQuery = {
  response: BatteryOverviewQuery$data;
  variables: BatteryOverviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "interval",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "range",
    "value": {
      "from": 0,
      "to": 2000
    }
  },
  {
    "kind": "Literal",
    "name": "unit",
    "value": "AmpHour"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "interval",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "range",
    "value": {
      "from": 0,
      "to": 24
    }
  },
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Hours"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rangeLow",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rangeHigh",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HistogramBucket",
    "kind": "LinkedField",
    "name": "buckets",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countMin",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "countMax",
    "storageKey": null
  },
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BatteryOverviewQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CurrentStatusDoughnut"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CurrentCapacityDoughnut"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalBatteryMetrics",
        "kind": "LinkedField",
        "name": "batteryMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Histogram",
            "kind": "LinkedField",
            "name": "remainingCapacityHistogram",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BatteryCapacityHisto_remainingCapacityHistogram"
              }
            ],
            "storageKey": "remainingCapacityHistogram(interval:100,range:{\"from\":0,\"to\":2000},unit:\"AmpHour\")"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Histogram",
            "kind": "LinkedField",
            "name": "timeRemainingHistogram",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BatteryTimeRemainingHisto_timeRemainingHistogram"
              }
            ],
            "storageKey": "timeRemainingHistogram(interval:1,range:{\"from\":0,\"to\":24},unit:\"Hours\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BatteryOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GlobalBatteryMetrics",
        "kind": "LinkedField",
        "name": "batteryMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryStatusBreakdown",
            "kind": "LinkedField",
            "name": "statusBreakdown",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CapacityBreakdown",
            "kind": "LinkedField",
            "name": "remainingCapacityBreakdown",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "Histogram",
            "kind": "LinkedField",
            "name": "remainingCapacityHistogram",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "remainingCapacityHistogram(interval:100,range:{\"from\":0,\"to\":2000},unit:\"AmpHour\")"
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Histogram",
            "kind": "LinkedField",
            "name": "timeRemainingHistogram",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": "timeRemainingHistogram(interval:1,range:{\"from\":0,\"to\":24},unit:\"Hours\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aeb2ab3cdd66e1033b7a21c778358ae3",
    "id": null,
    "metadata": {},
    "name": "BatteryOverviewQuery",
    "operationKind": "query",
    "text": "query BatteryOverviewQuery {\n  ...CurrentStatusDoughnut\n  ...CurrentCapacityDoughnut\n  batteryMetrics {\n    remainingCapacityHistogram(unit: AmpHour, interval: 100, range: {from: 0, to: 2000}) {\n      ...BatteryCapacityHisto_remainingCapacityHistogram\n    }\n    timeRemainingHistogram(unit: Hours, interval: 1, range: {from: 0, to: 24}) {\n      ...BatteryTimeRemainingHisto_timeRemainingHistogram\n    }\n  }\n}\n\nfragment BatteryCapacityHisto_remainingCapacityHistogram on Histogram {\n  buckets {\n    rangeLow\n    rangeHigh\n    count\n  }\n  countMin\n  countMax\n  rangeLow\n  rangeHigh\n}\n\nfragment BatteryTimeRemainingHisto_timeRemainingHistogram on Histogram {\n  buckets {\n    rangeLow\n    rangeHigh\n    count\n  }\n  countMin\n  countMax\n  rangeLow\n  rangeHigh\n}\n\nfragment CurrentCapacityDoughnut on Query {\n  batteryMetrics {\n    remainingCapacityBreakdown {\n      rangeLow\n      rangeHigh\n      deviceCount\n    }\n  }\n}\n\nfragment CurrentStatusDoughnut on Query {\n  batteryMetrics {\n    statusBreakdown {\n      status\n      deviceCount\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e610e833e8922553fa648c9e3b34777";

export default node;
