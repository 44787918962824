/**
 * @generated SignedSource<<eaeea4602b6d2bccc76c87b5ca9eb949>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleSiteContent_SiteViewQuery$variables = {
  siteId: string;
};
export type SingleSiteContent_SiteViewQuery$data = {
  readonly site: {
    readonly address: {
      readonly address: string | null;
      readonly state: string;
    };
    readonly devices: {
      readonly data: ReadonlyArray<{
        readonly battery: {
          readonly installed: boolean;
          readonly metrics: {
            readonly latestRemainingCapacity: number | null;
            readonly originalCapacity: number | null;
          };
        };
        readonly dualPlaneCompanion: {
          readonly device: {
            readonly id: string;
          } | null;
        } | null;
        readonly id: string;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"Details_device">;
      }>;
    };
    readonly name: string;
  } | null;
};
export type SingleSiteContent_SiteViewQuery = {
  response: SingleSiteContent_SiteViewQuery$data;
  variables: SingleSiteContent_SiteViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "siteId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "category": [
        "PowerController"
      ]
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installed",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originalCapacity",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestRemainingCapacity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleSiteContent_SiteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DualPlaneCompanion",
                    "kind": "LinkedField",
                    "name": "dualPlaneCompanion",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBattery",
                    "kind": "LinkedField",
                    "name": "battery",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryMetrics",
                        "kind": "LinkedField",
                        "name": "metrics",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Details_device"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleSiteContent_SiteViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "data",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DualPlaneCompanion",
                    "kind": "LinkedField",
                    "name": "dualPlaneCompanion",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceBattery",
                    "kind": "LinkedField",
                    "name": "battery",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryMetrics",
                        "kind": "LinkedField",
                        "name": "metrics",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestStatus",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DeviceBatteryStrings",
                        "kind": "LinkedField",
                        "name": "strings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeviceBatteryString",
                            "kind": "LinkedField",
                            "name": "strings",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "installDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "BatteryType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "model",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "manufacturer",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "technology",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "capabilities",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82d0606abe0475b7f27ffe45c8d4cc09",
    "id": null,
    "metadata": {},
    "name": "SingleSiteContent_SiteViewQuery",
    "operationKind": "query",
    "text": "query SingleSiteContent_SiteViewQuery(\n  $siteId: ID!\n) {\n  site(id: $siteId) {\n    name\n    address {\n      state\n      address\n    }\n    devices(filters: {category: [PowerController]}) {\n      data {\n        id\n        name\n        type {\n          displayName\n          id\n        }\n        dualPlaneCompanion {\n          device {\n            id\n          }\n        }\n        battery {\n          installed\n          metrics {\n            originalCapacity\n            latestRemainingCapacity\n          }\n        }\n        ...Details_device\n      }\n    }\n    id\n  }\n}\n\nfragment Details_device on Device {\n  capabilities\n  battery {\n    metrics {\n      latestStatus\n      originalCapacity\n      latestRemainingCapacity\n    }\n    strings {\n      strings {\n        installDate\n        type {\n          model\n          manufacturer\n          technology\n          id\n        }\n        id\n      }\n    }\n  }\n  dualPlaneCompanion {\n    device {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1d4d5890f06917a9215183b4227ccdb";

export default node;
