import { numberToLocaleString } from 'lib/numberFormatters';
import { DeviceIntegrationIn, DeviceUpdateIn } from 'views/manage/__generated__/mutations_EditDeviceMutation.graphql';

import { DualPlaneConfiguration, EditDeviceFormQuery } from '../__generated__/EditDeviceFormQuery.graphql';
import { SettingsName } from '../components/Integration';
import { DeviceIn } from '../helperFunctions';
import { DeviceFormValues, DualPlaneConfigurationType, createDefaultDeviceFormValues } from '../schema';
import { DeviceBatterySettings } from '../sub-forms/battery-settings';
import { ProtocolIn } from '../sub-forms/connectivity-settings/__generated__/useConnectionTestMutation.graphql';
import {
    BasicSettingsFormValues,
    ConnectivitySettingsFormValues,
    GatewaySettingsFormValues,
    SnmpSettingsFormValues,
    createDefaultConnectivityBasicSettingsValues,
    createDefaultConnectivityGatewaySettingsValues,
    createDefaultConnectivitySettingsValues,
    createDefaultConnectivitySnmpSettingsValues,
} from '../sub-forms/connectivity-settings/schema';
import { DeviceType } from './device-types';

type Device = EditDeviceFormQuery['response']['device'];
type ConnectionSettings = NonNullable<Device>['connectionSettings'];
type AnyProtocol = NonNullable<ConnectionSettings>['protocols'][0];
type ProtocolBasic = Required<Pick<AnyProtocol, 'definition' | 'type' | 'displayName' | 'credentials'>>;
type ProtocolSnmp = Required<
    Pick<AnyProtocol, 'definition' | 'type' | 'displayName' | 'port' | 'version' | 'settings'>
>;
type ProtocolGateway = Required<Pick<AnyProtocol, 'definition' | 'type' | 'displayName' | 'gateway' | 'localId'>>;

export function convertDeviceToFormState(device: Device): DeviceFormValues {
    if (!device) {
        return createDefaultDeviceFormValues();
    }

    const values: DeviceFormValues = createDefaultDeviceFormValues();

    // General information
    values.name = device.name;
    values.category = device.type.category;
    values.type = device.type.id;

    if (device.firmware && device.firmware.defined) {
        values.firmwareVersion = device.firmware.id;
    }

    if (device.site) {
        values.site = {
            id: device.site.id,
            displayName: device.site.name,
        };
    }

    if (device.attributes) {
        values.attributes = device.attributes.map(attribute => ({ ...attribute }));
    }

    values.monitorOnly = device.monitorOnly;

    values.settings = convertConnectionSettingsToFormState(device.connectionSettings);

    // Integrations
    if (device.integrations) {
        for (const integration of device.integrations) {
            // FIXME: We should be supporting any integration not just this one
            if (integration.type.id !== 'fbdr') {
                continue;
            }

            const elementNameAttribute = integration.attributes.find(
                attribute => attribute.name === SettingsName.ElementName
            );
            const siteIdAttribute = integration.attributes.find(attribute => attribute.name === SettingsName.SiteId);

            if (elementNameAttribute) {
                values.optimaIntegration.elementName = elementNameAttribute.value ?? '';
            }
            if (siteIdAttribute) {
                values.optimaIntegration.siteId = siteIdAttribute.value ?? '';
            }
        }
    }

    // Battery Information
    if (device.battery.reserveTime != null) {
        values.batteries.reserveTime = numberToLocaleString(device.battery.reserveTime);
    }
    if (device.battery.allowedVoltage.minimum != null) {
        values.batteries.minimumAllowedVoltage = numberToLocaleString(device.battery.allowedVoltage.minimum);
    }
    if (device.battery.allowedVoltage.maximum != null) {
        values.batteries.maximumAllowedVoltage = numberToLocaleString(device.battery.allowedVoltage.maximum);
    }

    if (device.battery.quickTestFailThreshold != null) {
        values.batteries.quickTestFailThreshold = numberToLocaleString(device.battery.quickTestFailThreshold);
    }
    if (device.battery.quickTestCheckPercent != null) {
        values.batteries.quickTestCheckPercent = numberToLocaleString(device.battery.quickTestCheckPercent);
    }

    if (device.battery.strings.count > 0) {
        values.batteries.strings = device.battery.strings.strings.map(batteryString => ({
            id: batteryString.id,
            wasEdited: false,
            type: {
                id: batteryString.type.id,
                manufacturer: batteryString.type.manufacturer,
                model: batteryString.type.model,
                displayName: `${batteryString.type.manufacturer} ${batteryString.type.model}`, // FIXME: We have 2 places that format this. Maybe combine
            },
            cellsPerString: batteryString.cellsPerString.toFixed(0),
            installDate: batteryString.installDate ? new Date(batteryString.installDate) : null,
            manufactureDate: batteryString.manufactureDate ? new Date(batteryString.manufactureDate) : null,
        }));
    }

    // Companion
    if (device.dualPlaneCompanion) {
        if (device.dualPlaneCompanion.configuration) {
            values.companionConfiguration = device.dualPlaneCompanion.configuration as DualPlaneConfigurationType;
        }

        if (device.dualPlaneCompanion.device) {
            values.companionReference = {
                id: device.dualPlaneCompanion.device.id,
                displayName: device.dualPlaneCompanion.device.name,
                siteName: device.dualPlaneCompanion.device.site.name,
            };
        }
    }
    return values;
}

function convertSnmpSettingsToFormState(protocol: ProtocolSnmp): SnmpSettingsFormValues {
    const values = createDefaultConnectivitySnmpSettingsValues(protocol.definition);

    const protocolSettings = protocol.settings;
    values.port = protocol.port.toFixed(0);
    values.version = protocol.version;

    if (protocol.version === 'V3') {
        values.user = protocolSettings.user!;
        values.engineId = protocolSettings.engineId!;
        values.securityLevel = protocolSettings.securityLevel!;
        values.authType = protocolSettings.authType!;
        values.privType = protocolSettings.privType!;

        // FIXME: Don't retrieve the password value unless the user asks for it
        if (
            protocolSettings.authPassphrase &&
            protocolSettings.authPassphrase.isSet &&
            protocolSettings.authPassphrase.canBeRetrieved
        ) {
            values.authPassphrase = protocolSettings.authPassphrase.value ?? '';
        }

        if (
            protocolSettings.privPassphrase &&
            protocolSettings.privPassphrase.isSet &&
            protocolSettings.privPassphrase.canBeRetrieved
        ) {
            values.privPassphrase = protocolSettings.privPassphrase.value ?? '';
        }
    } else {
        values.readOnlyCommunity = protocol.settings.readOnlyCommunity ?? '';
        values.readWriteCommunity = protocol.settings.readWriteCommunity ?? '';
    }

    return values;
}

function convertBasicSettingsToFormState(protocol: ProtocolBasic): BasicSettingsFormValues {
    const values = createDefaultConnectivityBasicSettingsValues(protocol.definition);

    values.username = protocol.credentials?.username ?? '';
    // FIXME: Don't retrieve the password value unless the user asks for it
    values.password = protocol.credentials?.password.value ?? '';

    return values;
}

function convertGatewaySettingsToFormState(protocol: ProtocolGateway): GatewaySettingsFormValues {
    const values = createDefaultConnectivityGatewaySettingsValues(protocol.definition);

    values.gateway = {
        id: protocol.gateway?.id ?? '',
        displayName: protocol.gateway?.name ?? '',
        siteName: protocol.gateway?.site.name ?? '',
    };

    values.localId = protocol.localId ?? '';
    return values;
}

function convertConnectionSettingsToFormState(settings: ConnectionSettings): ConnectivitySettingsFormValues {
    const values: ConnectivitySettingsFormValues = createDefaultConnectivitySettingsValues();

    values.addresses = [...settings.addresses];
    values.protocols = {};
    for (const protocol of settings.protocols) {
        if (protocol.type === 'Snmp') {
            values.protocols[protocol.definition] = convertSnmpSettingsToFormState(protocol as ProtocolSnmp);
        } else if (protocol.type === 'Basic') {
            values.protocols[protocol.definition] = convertBasicSettingsToFormState(protocol as ProtocolBasic);
        } else if (protocol.type === 'Gateway') {
            values.protocols[protocol.definition] = convertGatewaySettingsToFormState(protocol as ProtocolGateway);
        }
    }

    return values;
}

export function switchCompanionStateToNormal(
    values: DeviceFormValues,
    duplicateNormalBatteries: boolean
): DeviceFormValues {
    const newValues: DeviceFormValues = {
        ...values.newCompanion!,
        newSite: values.newSite,
        newBatteryType: values.newBatteryType,
        firmwareVersion: null,
        category: values.category,
        type: values.type,
        site: values.site,
        companionConfiguration: values.companionConfiguration,
        companionReference: null,
        newCompanion: values.newCompanion,
    };

    newValues.optimaIntegration.siteId = values.optimaIntegration.siteId;

    if (duplicateNormalBatteries) {
        newValues.batteries = values.batteries;
    }
    return newValues;
}

function convertCommonDeviceFormStateToApi(
    values: DeviceFormValues,
    deviceType: DeviceType
): Omit<DeviceIn & DeviceUpdateIn, 'battery'> {
    let dualPlaneType: DualPlaneConfiguration | null = null;
    if (values.companionConfiguration && values.companionConfiguration !== DualPlaneConfigurationType.Single) {
        dualPlaneType = values.companionConfiguration;
    }

    let integrations: DeviceIntegrationIn[] | null = null;

    // FIXME: Support arbitrary integration types
    if (values.optimaIntegration.elementName && values.optimaIntegration.siteId) {
        integrations = [
            {
                type: 'fbdr',
                attributes: [
                    {
                        name: SettingsName.ElementName,
                        value: values.optimaIntegration.elementName,
                    },
                    {
                        name: SettingsName.SiteId,
                        value: values.optimaIntegration.siteId,
                    },
                ],
            },
        ];
    }

    return {
        name: values.name,
        type: values.type,
        monitorOnly: values.monitorOnly,
        site: values.site!.id,
        targetFirmware: values.firmwareVersion,
        dualPlaneType: values.companionReference?.id ? dualPlaneType : null,
        dualPlaneDevice: dualPlaneType ? values.companionReference?.id : null,
        attributes: values.attributes,
        connectionSettings: convertConnectivitySettingsFormStateToApi(values.settings, deviceType),
        integrations,
    };
}
export function convertDeviceFormStateToApi(values: DeviceFormValues, deviceTypes: DeviceType[]): DeviceIn {
    const deviceType = deviceTypes.find(type => type.id === values.type);

    if (!deviceType) {
        throw new Error('Invalid device type');
    }

    const result = convertCommonDeviceFormStateToApi(values, deviceType);

    return {
        ...result,
        battery: convertBatteryFormStateToApi(values.batteries),
    };
}

export function convertBatteryFormStateToApi(battery: DeviceBatterySettings): NonNullable<DeviceIn['battery']> {
    return {
        strings: battery.strings.map(batteryString => ({
            type: batteryString.type!.id,
            cellsPerString: Number(batteryString.cellsPerString),
            installDate: batteryString.installDate?.toISOString(),
            manufactureDate: batteryString.manufactureDate?.toISOString(),
        })),
        reserveTime: battery.reserveTime ? Number(battery.reserveTime) : null,
        minimumAllowedVoltage: battery.minimumAllowedVoltage ? Number(battery.minimumAllowedVoltage) : null,
        maximumAllowedVoltage: battery.maximumAllowedVoltage ? Number(battery.maximumAllowedVoltage) : null,
        quickTestFailThreshold: battery.quickTestFailThreshold ? Number(battery.quickTestFailThreshold) : null,
        quickTestCheckPercent: battery.quickTestCheckPercent ? Number(battery.quickTestCheckPercent) : null,
    };
}

function convertBatteryFormStateToApiForUpdate(battery: DeviceBatterySettings): NonNullable<DeviceUpdateIn['battery']> {
    const addedBatteryStrings = battery.strings.filter(batteryString => !batteryString.id);
    const removedBatteryStrings = battery.removedStrings;
    const updatedBatteryStrings = battery.strings.filter(batteryString => batteryString.id && batteryString.wasEdited);

    return {
        strings: {
            add: addedBatteryStrings.map(batteryString => ({
                type: batteryString.type!.id,
                cellsPerString: Number(batteryString.cellsPerString),
                installDate: batteryString.installDate?.toISOString(),
                manufactureDate: batteryString.manufactureDate?.toISOString(),
            })),
            update: updatedBatteryStrings.map(batteryString => ({
                id: batteryString.id!,
                type: batteryString.type!.id,
                cellsPerString: Number(batteryString.cellsPerString),
                installDate: batteryString.installDate?.toISOString(),
                manufactureDate: batteryString.manufactureDate?.toISOString(),
            })),
            remove: removedBatteryStrings,
        },
        reserveTime: battery.reserveTime ? Number(battery.reserveTime) : null,
        minimumAllowedVoltage: battery.minimumAllowedVoltage ? Number(battery.minimumAllowedVoltage) : null,
        maximumAllowedVoltage: battery.maximumAllowedVoltage ? Number(battery.maximumAllowedVoltage) : null,
        quickTestFailThreshold: battery.quickTestFailThreshold ? Number(battery.quickTestFailThreshold) : null,
        quickTestCheckPercent: battery.quickTestCheckPercent ? Number(battery.quickTestCheckPercent) : null,
    };
}

export function convertConnectivitySettingsFormStateToApi(
    settings: ConnectivitySettingsFormValues,
    deviceType: DeviceType
): DeviceIn['connectionSettings'] {
    const encodedProtocolSettings: Array<ProtocolIn> = [];

    const { addresses, protocols } = settings;

    for (const protocol of deviceType.connectivity.protocols) {
        const protocolSettings = protocols[protocol.id];
        if (!protocolSettings) {
            continue;
        }

        if (protocol.type === 'Snmp') {
            const snmpSettings = protocolSettings as SnmpSettingsFormValues;
            if (snmpSettings.version === 'V3') {
                encodedProtocolSettings.push({
                    id: protocol.id,
                    snmp: {
                        version: snmpSettings.version,
                        port: Number(snmpSettings.port),
                        settingsV3: {
                            authType: snmpSettings.authType,
                            authPassphrase: snmpSettings.authPassphrase,
                            privType: snmpSettings.privType,
                            privPassphrase: snmpSettings.privPassphrase,
                            securityLevel: snmpSettings.securityLevel,
                            user: snmpSettings.user!,
                            engineId: snmpSettings.engineId,
                        },
                    },
                });
            } else {
                encodedProtocolSettings.push({
                    id: protocol.id,
                    snmp: {
                        version: snmpSettings.version,
                        port: Number(snmpSettings.port),
                        settingsV1V2c: {
                            readOnlyCommunity: snmpSettings.readOnlyCommunity!,
                            readWriteCommunity: snmpSettings.readWriteCommunity!,
                        },
                    },
                });
            }
        } else if (protocol.type === 'Basic') {
            const webSettings = protocolSettings as BasicSettingsFormValues;
            encodedProtocolSettings.push({
                id: protocol.id,
                basic: {
                    username: webSettings.username,
                    password: webSettings.password,
                },
            });
        } else if (protocol.type === 'Gateway') {
            const gatewaySettings = protocolSettings as GatewaySettingsFormValues;
            encodedProtocolSettings.push({
                id: protocol.id,
                gateway: {
                    gatewayId: gatewaySettings.gateway.id,
                    localId: gatewaySettings.localId,
                },
            });
        }
    }

    return {
        addresses,
        protocols: encodedProtocolSettings,
    };
}

export function convertDeviceFormStateToApiForUpdate(
    values: DeviceFormValues,
    deviceTypes: DeviceType[]
): DeviceUpdateIn {
    const deviceType = deviceTypes.find(type => type.id === values.type);

    if (!deviceType) {
        throw new Error('Invalid device type');
    }

    const result = convertCommonDeviceFormStateToApi(values, deviceType);

    return {
        ...result,
        battery: convertBatteryFormStateToApiForUpdate(values.batteries),
    };
}
