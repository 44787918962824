/**
 * @generated SignedSource<<6b8ed0a6bd5f1a4bb44fc62c642bc459>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACPowerEventSortField = "AffectedAllFeeds" | "DeviceName" | "Duration" | "EndTime" | "SiteName" | "SiteState" | "StartTime" | "Voltage" | "WorstStatus" | "%future added value";
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type ACPowerEventFilter = {
  activeWithin?: DateRangeFilter | null;
  affectedFeeds?: ReadonlyArray<StringFilter> | null;
  affectsAllFeeds?: BooleanFilter | null;
  deviceId?: ReadonlyArray<string> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  duration?: DurationFilter | null;
  endTime?: DateRangeFilter | null;
  isSignificant?: BooleanFilter | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  startTime?: DateRangeFilter | null;
  status?: ReadonlyArray<ACPowerEventStatusFilter> | null;
  voltage?: FloatRangeFilter | null;
  worstStatus?: ReadonlyArray<ACPowerEventStatusFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type ACPowerEventStatusFilter = {
  value: ACPowerEventStatus;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type ACPowerEventOrdering = {
  dir?: SortDirection | null;
  field: ACPowerEventSortField;
};
export type ACPowerSiteIncidentListTableQuery$variables = {
  filter?: ACPowerEventFilter | null;
  orderBy?: ACPowerEventOrdering | null;
  page: number;
  siteId: string;
};
export type ACPowerSiteIncidentListTableQuery$data = {
  readonly overallIncidents: {
    readonly acPower: {
      readonly events: {
        readonly total: number;
      };
    };
  } | null;
  readonly site: {
    readonly acPower: {
      readonly events: {
        readonly data: ReadonlyArray<{
          readonly affectedAllDevices: boolean;
          readonly affectedDevices: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
          }>;
          readonly affectedFeeds: ReadonlyArray<{
            readonly id: string;
            readonly label: string;
            readonly status: ACPowerEventStatus;
            readonly voltage: number | null;
          }>;
          readonly duration: string;
          readonly id: string;
          readonly site: {
            readonly id: string;
            readonly name: string;
          };
          readonly startTime: string;
          readonly worstStatus: ACPowerEventStatus;
          readonly " $fragmentSpreads": FragmentRefs<"SiteIncidentExpandedRow_event">;
        }>;
        readonly pageInfo: {
          readonly hasNext: boolean | null;
          readonly hasPrevious: boolean | null;
          readonly page: number;
          readonly total: number;
        };
        readonly total: number;
      };
    };
  } | null;
};
export type ACPowerSiteIncidentListTableQuery = {
  response: ACPowerSiteIncidentListTableQuery$data;
  variables: ACPowerSiteIncidentListTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "siteId"
  }
],
v5 = {
  "kind": "Variable",
  "name": "page",
  "variableName": "page"
},
v6 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affectedAllDevices",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worstStatus",
  "storageKey": null
},
v14 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ACPowerEventFeed",
  "kind": "LinkedField",
  "name": "affectedFeeds",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voltage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "affectedDevices",
  "plural": true,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "SiteACPower",
  "kind": "LinkedField",
  "name": "acPower",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        (v5/*: any*/)
      ],
      "concreteType": "PaginatedSiteACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerSiteIncidentListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "PaginatedSiteACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SiteIncidentExpandedRow_event"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "overallIncidents",
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ACPowerSiteIncidentListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteACPower",
            "kind": "LinkedField",
            "name": "acPower",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "PaginatedSiteACPowerEvent",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteACPowerEvent",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "affectedAllFeeds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ACPowerEventStats",
                        "kind": "LinkedField",
                        "name": "stats",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "batteryDidDischarge",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "batteryDoD",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "loadUptime",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "overallIncidents",
        "args": (v4/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          (v18/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3579ae43557356e7c75ec60b268ae5c3",
    "id": null,
    "metadata": {},
    "name": "ACPowerSiteIncidentListTableQuery",
    "operationKind": "query",
    "text": "query ACPowerSiteIncidentListTableQuery(\n  $page: Int!\n  $siteId: ID!\n  $filter: ACPowerEventFilter\n  $orderBy: ACPowerEventOrdering\n) {\n  site(id: $siteId) {\n    acPower {\n      events(page: $page, filters: $filter, orderBy: $orderBy) {\n        total\n        pageInfo {\n          page\n          total\n          hasNext\n          hasPrevious\n        }\n        data {\n          id\n          affectedAllDevices\n          duration\n          startTime\n          worstStatus\n          site {\n            id\n            name\n          }\n          affectedFeeds {\n            id\n            label\n            status\n            voltage\n          }\n          affectedDevices {\n            id\n            name\n          }\n          ...SiteIncidentExpandedRow_event\n        }\n      }\n    }\n    id\n  }\n  overallIncidents: site(id: $siteId) {\n    acPower {\n      events(page: $page) {\n        total\n      }\n    }\n    id\n  }\n}\n\nfragment SiteIncidentExpandedRow_event on SiteACPowerEvent {\n  id\n  startTime\n  endTime\n  duration\n  worstStatus\n  affectedAllFeeds\n  affectedFeeds {\n    id\n    label\n    voltage\n    status\n  }\n  affectedAllDevices\n  affectedDevices {\n    name\n    id\n  }\n  stats {\n    batteryDidDischarge\n    batteryDoD\n    loadUptime\n  }\n  site {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "629ca1ccddc684291ee33d91b07e0f79";

export default node;
