/**
 * @generated SignedSource<<f38201b5a35607addbefa57456c7d74c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type breadcrumbBatteryTestResultQuery$variables = {
  id: string;
};
export type breadcrumbBatteryTestResultQuery$data = {
  readonly batteryTestResult: {
    readonly device: {
      readonly name: string;
    };
    readonly id: string;
    readonly task: {
      readonly name: string | null;
    } | null;
  } | null;
};
export type breadcrumbBatteryTestResultQuery = {
  response: breadcrumbBatteryTestResultQuery$data;
  variables: breadcrumbBatteryTestResultQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "breadcrumbBatteryTestResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryTest",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "breadcrumbBatteryTestResultQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BatteryTest",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24bd917cf1cd6c2e4b78afaa5254c92d",
    "id": null,
    "metadata": {},
    "name": "breadcrumbBatteryTestResultQuery",
    "operationKind": "query",
    "text": "query breadcrumbBatteryTestResultQuery(\n  $id: ID!\n) {\n  batteryTestResult(id: $id) {\n    id\n    device {\n      name\n      id\n    }\n    task {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3de9b4ed44127858259ead02838cdf6";

export default node;
