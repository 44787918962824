import React, { FC } from 'react';

import { FilterRange } from 'components/FilterRange';
import { CommonFilterProps } from 'filters/common';

import { GeneratorRunReportFuelCapacityFilter } from '../types';

export const FuelCapacityFilterUI: FC<CommonFilterProps<GeneratorRunReportFuelCapacityFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by Fuel Capacity (L)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};
