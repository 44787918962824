/**
 * @generated SignedSource<<a043d4140229f59772e8deaebaf4ec34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReliabilityChart_query$data = {
  readonly siteACReliabilityDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly key: number;
      readonly percentileEnd: number;
      readonly percentileStart: number;
    }>;
  } | null;
  readonly " $fragmentType": "ReliabilityChart_query";
};
export type ReliabilityChart_query$key = {
  readonly " $data"?: ReliabilityChart_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReliabilityChart_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReliabilityChart_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "buckets",
          "value": 11
        },
        {
          "kind": "Literal",
          "name": "timeRange",
          "value": "Last6Months"
        }
      ],
      "concreteType": "SiteACReliabilityDistribution",
      "kind": "LinkedField",
      "name": "siteACReliabilityDistribution",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteACReliabilityDistributionBucket",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileStart",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentileEnd",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "siteACReliabilityDistribution(buckets:11,timeRange:\"Last6Months\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "772aef720116e20bf24d8f3f97892910";

export default node;
