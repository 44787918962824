/**
 * @generated SignedSource<<4a1ffb0b32f825a91c0b9286ec4a62bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorLineCard_metrics$data = {
  readonly latestApparentPower: number | null;
  readonly latestCurrent: number | null;
  readonly latestFrequency: number | null;
  readonly latestPower: number | null;
  readonly latestPowerFactor: number | null;
  readonly latestReactivePower: number | null;
  readonly latestVoltage: number | null;
  readonly " $fragmentType": "GeneratorLineCard_metrics";
};
export type GeneratorLineCard_metrics$key = {
  readonly " $data"?: GeneratorLineCard_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorLineCard_metrics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorLineCard_metrics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestCurrent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestPower",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestApparentPower",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestReactivePower",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestPowerFactor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestFrequency",
      "storageKey": null
    }
  ],
  "type": "DeviceGeneratorPhaseMetrics",
  "abstractKey": null
};

(node as any).hash = "1bc7490eaaab71e9e94e67570c535a5d";

export default node;
