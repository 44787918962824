/**
 * @generated SignedSource<<3f91feab141daea406d2dfa9f8d33c52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TestEditResult = "InvalidTest" | "InvalidValue" | "Success" | "%future added value";
export type TestResultHeaderUpdateNameMutation$variables = {
  id: string;
  name?: string | null;
};
export type TestResultHeaderUpdateNameMutation$data = {
  readonly editBatteryTestName: TestEditResult;
};
export type TestResultHeaderUpdateNameMutation = {
  response: TestResultHeaderUpdateNameMutation$data;
  variables: TestResultHeaderUpdateNameMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ScalarField",
    "name": "editBatteryTestName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestResultHeaderUpdateNameMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestResultHeaderUpdateNameMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2bf978931d771820ea6a35b7e35f8cfe",
    "id": null,
    "metadata": {},
    "name": "TestResultHeaderUpdateNameMutation",
    "operationKind": "mutation",
    "text": "mutation TestResultHeaderUpdateNameMutation(\n  $id: ID!\n  $name: String\n) {\n  editBatteryTestName(id: $id, name: $name)\n}\n"
  }
};
})();

(node as any).hash = "b75dce35a505738b0f54e1f283e003f7";

export default node;
