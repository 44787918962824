/**
 * @generated SignedSource<<f6e0cfba5385f2b7e19b08c7d1589f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthLevelAdmin = "Admin" | "None" | "%future added value";
export type AuthLevelRead = "None" | "ReadOnly" | "%future added value";
export type AuthLevelWrite = "None" | "ReadOnly" | "ReadWrite" | "%future added value";
export type UserStatus = "Active" | "Inactive" | "Invited" | "PasswordResetRequired" | "Unknown" | "VerificationRequired" | "%future added value";
export type UserType = "External" | "Local" | "%future added value";
export type EditUserContentQuery$variables = {
  username: string;
};
export type EditUserContentQuery$data = {
  readonly user: {
    readonly email: string | null;
    readonly enabled: boolean;
    readonly name: string | null;
    readonly permissions: {
      readonly administration: AuthLevelAdmin;
      readonly assets: AuthLevelWrite;
      readonly batteryHealthTasks: AuthLevelWrite;
      readonly general: AuthLevelRead;
    };
    readonly status: UserStatus;
    readonly type: UserType;
  } | null;
};
export type EditUserContentQuery = {
  response: EditUserContentQuery$data;
  variables: EditUserContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPermissions",
        "kind": "LinkedField",
        "name": "permissions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "general",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "batteryHealthTasks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "administration",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "enabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditUserContentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditUserContentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6e67f031b54d58a55ba3bf4c5ef2d191",
    "id": null,
    "metadata": {},
    "name": "EditUserContentQuery",
    "operationKind": "query",
    "text": "query EditUserContentQuery(\n  $username: String!\n) {\n  user(username: $username) {\n    name\n    email\n    permissions {\n      general\n      assets\n      batteryHealthTasks\n      administration\n    }\n    type\n    status\n    enabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad695a8d0b42bda64be11ba56e7abed2";

export default node;
