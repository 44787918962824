import { Environment, fetchQuery } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { TestResultFilterState, TestResultTableColumn, testResultToFilterObject } from 'filters/test-result';
import { SortDirection } from 'layouts';
import { TableStateForVariables } from 'lib/tables/table-query';

import {
    TestResultOrdering,
    TestResultSortField,
    testResultExportQuery,
} from './__generated__/testResultExportQuery.graphql';

export interface TestResultExportOptions {
    page: number;
    pageSize: number;
    orderBy: string;
    orderDirection: SortDirection;
    visibleColumns: TestResultTableColumn[];
    filters: TestResultFilterState;
}

export async function exportBatteryTestResults(
    options: TableStateForVariables<TestResultTableColumn>,
    filters: TestResultFilterState,
    environment: Environment
) {
    const sortObject: TestResultOrdering = {
        field: options.orderBy as TestResultSortField,
        dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: testResultExportQuery['variables'] = {
        page: options.page,
        pageSize: options.pageSize,
        pageCount: options.pageCount,
        filters: testResultToFilterObject(filters),
        orderBy: sortObject,
        search: options.search,
        withDeviceName: options.visibleColumns.includes(TestResultTableColumn.DeviceName),
        withCause: options.visibleColumns.includes(TestResultTableColumn.Cause),
        withFailReason: options.visibleColumns.includes(TestResultTableColumn.FailReason),
        withCurrentMetrics: options.visibleColumns.includes(TestResultTableColumn.CurrentMetrics),
        withTemperatureMetrics: options.visibleColumns.includes(TestResultTableColumn.TemperatureMetrics),
        withVoltageMetrics: options.visibleColumns.includes(TestResultTableColumn.VoltageMetrics),
        withDischargedMetrics: options.visibleColumns.includes(TestResultTableColumn.DischargedMetrics),
        withStateOfHealth: options.visibleColumns.includes(TestResultTableColumn.EstimatedStateOfHealth),
        withCapacity: options.visibleColumns.includes(TestResultTableColumn.EstimatedCapacity),
        withReserveTime: options.visibleColumns.includes(TestResultTableColumn.EstimatedReserveTime),
    };

    const data = await fetchQuery<testResultExportQuery>(environment, ExportQuery, variables, {
        networkCacheConfig: {
            metadata: {
                // Exports can be heavy, so we give it a bit more time
                timeout: 60_000, // 1 minute
            },
        },
    }).toPromise();

    if (!data) {
        throw new Error('No data returned from server');
    }

    return data.unplannedTests.data;
}

const ExportQuery = graphql`
    query testResultExportQuery(
        $search: String = ""
        $page: Int = 1
        $pageSize: Int = 50
        $pageCount: Int
        $filters: TestResultFilter
        $orderBy: TestResultOrdering
        $withDeviceName: Boolean = false
        $withCause: Boolean = false
        $withFailReason: Boolean = false
        $withCurrentMetrics: Boolean = false
        $withTemperatureMetrics: Boolean = false
        $withVoltageMetrics: Boolean = false
        $withDischargedMetrics: Boolean = false
        $withStateOfHealth: Boolean = false
        $withCapacity: Boolean = false
        $withReserveTime: Boolean = false
    ) {
        unplannedTests(
            page: $page
            pageSize: $pageSize
            pageCount: $pageCount
            filters: $filters
            orderBy: $orderBy
            search: $search
        ) {
            data {
                name
                state
                commencedTime
                completedTime
                batteryStrings {
                    string
                }
                device {
                    name @include(if: $withDeviceName)
                }
                cause @include(if: $withCause)
                failReason @include(if: $withFailReason)
                averageCurrent @include(if: $withCurrentMetrics)
                averageTemperature @include(if: $withTemperatureMetrics)
                finalVoltage @include(if: $withVoltageMetrics)
                discharged @include(if: $withDischargedMetrics)
                estimatedStateOfHealth @include(if: $withStateOfHealth) {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            lower
                            upper
                        }
                    }
                    ... on MissingEstimatedValue {
                        reason
                        reasonDescription
                    }
                }
                estimatedCapacity @include(if: $withCapacity) {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            lower
                            upper
                        }
                    }
                    ... on MissingEstimatedValue {
                        reason
                        reasonDescription
                    }
                }
                estimatedReserveTime @include(if: $withReserveTime) {
                    ... on EstimatedValue {
                        value
                        type
                        confidenceInterval {
                            lower
                            upper
                        }
                    }
                    ... on MissingEstimatedValue {
                        reason
                        reasonDescription
                    }
                }
            }
        }
    }
`;
