/**
 * @generated SignedSource<<d54ba017c81a7d642d3499d6bd8132a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACDeviceReliability_reliability$data = {
  readonly mtbf: {
    readonly current: number | null;
    readonly percentChange: number | null;
    readonly previous: number | null;
  };
  readonly mttr: {
    readonly current: number | null;
    readonly percentChange: number | null;
    readonly previous: number | null;
  };
  readonly percentile: {
    readonly current: number | null;
    readonly percentChange: number | null;
    readonly previous: number | null;
  };
  readonly " $fragmentType": "ACDeviceReliability_reliability";
};
export type ACDeviceReliability_reliability$key = {
  readonly " $data"?: ACDeviceReliability_reliability$data;
  readonly " $fragmentSpreads": FragmentRefs<"ACDeviceReliability_reliability">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "current",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "previous",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentChange",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ACDeviceReliability_reliability",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReliabilityMetric",
      "kind": "LinkedField",
      "name": "mttr",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReliabilityMetric",
      "kind": "LinkedField",
      "name": "mtbf",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReliabilityMetric",
      "kind": "LinkedField",
      "name": "percentile",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "DeviceAcPowerReliabilityMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "6aaa97fcdb0386ce40f48e771027f46e";

export default node;
