import { EditUserContentQuery$data } from '../__generated__/EditUserContentQuery.graphql';
import { AdminAccess, ReadAccess, ReadWriteAccess, UserFormValues, createDefaultUserFormValues } from '../schema';

type User = EditUserContentQuery$data['user'];

export function convertUserToFormState(user: User): UserFormValues {
    if (!user) {
        return createDefaultUserFormValues();
    }

    const values: UserFormValues = createDefaultUserFormValues();

    if (user.name) {
        values.name = user.name;
    }
    if (user.email) {
        values.email = user.email;
    }

    values.permissions = {
        general: user.permissions.general as ReadAccess,
        assets: user.permissions.assets as ReadWriteAccess,
        batteryHealthTasks: user.permissions.batteryHealthTasks as ReadWriteAccess,
        administration: user.permissions.administration as AdminAccess,
    };

    return values;
}
