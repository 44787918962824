/**
 * @generated SignedSource<<4bd46e4f3be0582f14985baded31d018>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryTechnologyType = "LeadAcidAGM" | "LeadAcidFlooded" | "LeadAcidGel" | "LeadAcidOther" | "LeadAcidTPPL" | "LeadAcidVRLA" | "LithiumIon" | "LithiumIronPhosphate" | "LithiumPolymer" | "NickelCadmium" | "NickelMetalHydride" | "Other" | "Supercapacitor" | "%future added value";
export type DischargeTableType = "Current" | "Power" | "%future added value";
export type EditBatteryTypeFormQuery$variables = {
  batteryTypeId: string;
};
export type EditBatteryTypeFormQuery$data = {
  readonly batteryType: {
    readonly attributes: ReadonlyArray<{
      readonly name: string;
      readonly value: string;
    }> | null;
    readonly blocVoltage: number | null;
    readonly cells: {
      readonly allowedVoltage: {
        readonly maximum: number | null;
        readonly minimum: number | null;
      } | null;
      readonly boostVoltage: {
        readonly maximum: number | null;
        readonly minimum: number | null;
      } | null;
      readonly capacity: number;
      readonly capacityRating: number;
      readonly floatVoltage: {
        readonly maximum: number | null;
        readonly minimum: number | null;
      } | null;
      readonly referenceTemperature: number;
      readonly typicalVoltage: number | null;
    };
    readonly cellsPerBloc: number | null;
    readonly dischargeTables: ReadonlyArray<{
      readonly endOfDischargeVoltage: number;
      readonly rows: ReadonlyArray<{
        readonly time: number;
        readonly value: number;
      }>;
      readonly type: DischargeTableType;
    }>;
    readonly manufacturer: string;
    readonly model: string;
    readonly peukertsConstant: number | null;
    readonly technology: BatteryTechnologyType;
  } | null;
};
export type EditBatteryTypeFormQuery = {
  response: EditBatteryTypeFormQuery$data;
  variables: EditBatteryTypeFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "batteryTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "batteryTypeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "manufacturer",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "model",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technology",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cellsPerBloc",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "minimum",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maximum",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryCellDefinition",
  "kind": "LinkedField",
  "name": "cells",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacityRating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceTemperature",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VoltageRange",
      "kind": "LinkedField",
      "name": "allowedVoltage",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VoltageRange",
      "kind": "LinkedField",
      "name": "floatVoltage",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VoltageRange",
      "kind": "LinkedField",
      "name": "boostVoltage",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "typicalVoltage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "peukertsConstant",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "DischargeTable",
  "kind": "LinkedField",
  "name": "dischargeTables",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endOfDischargeVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DischargeTableRow",
      "kind": "LinkedField",
      "name": "rows",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "time",
          "storageKey": null
        },
        (v9/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blocVoltage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Attribute",
  "kind": "LinkedField",
  "name": "attributes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBatteryTypeFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "batteryType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBatteryTypeFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BatteryType",
        "kind": "LinkedField",
        "name": "batteryType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "884d8346da8d6c7434d4b75a3af3e953",
    "id": null,
    "metadata": {},
    "name": "EditBatteryTypeFormQuery",
    "operationKind": "query",
    "text": "query EditBatteryTypeFormQuery(\n  $batteryTypeId: ID!\n) {\n  batteryType(id: $batteryTypeId) {\n    manufacturer\n    model\n    technology\n    cellsPerBloc\n    cells {\n      capacity\n      capacityRating\n      referenceTemperature\n      allowedVoltage {\n        minimum\n        maximum\n      }\n      floatVoltage {\n        minimum\n        maximum\n      }\n      boostVoltage {\n        minimum\n        maximum\n      }\n      typicalVoltage\n    }\n    peukertsConstant\n    dischargeTables {\n      type\n      endOfDischargeVoltage\n      rows {\n        time\n        value\n      }\n    }\n    blocVoltage\n    attributes {\n      name\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4cd18c0995a0dd588b72aac2bec512a";

export default node;
