import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { ResultView } from './ResultView';
import { MetricsViewCombined_test$key } from './__generated__/MetricsViewCombined_test.graphql';

const TestResultFragment = graphql`
    fragment MetricsViewCombined_test on DeviceBatteryTestResults {
        averageCurrent(unit: Amps)
        averageTemperature(unit: Celsius)
        discharged(unit: AmpHour)
        finalVoltage(unit: Volts)

        device {
            name
        }
        ...ResultView_test
    }
`;

interface MetricsViewProps {
    test1Ref: MetricsViewCombined_test$key;
    test2Ref: MetricsViewCombined_test$key;
}

export const MetricsViewCombined: FC<MetricsViewProps> = ({ test1Ref, test2Ref }) => {
    let endVoltage;
    let avgTemperature;
    let avgCurrent;
    let discharged;

    const test1 = useFragment<MetricsViewCombined_test$key>(TestResultFragment, test1Ref);
    const test2 = useFragment<MetricsViewCombined_test$key>(TestResultFragment, test2Ref);

    // lowest voltage
    if (test1.finalVoltage && test2.finalVoltage) {
        if (test1.finalVoltage < test2.finalVoltage) {
            endVoltage = `${test1.finalVoltage.toFixed(1)}V`;
        } else {
            endVoltage = `${test2.finalVoltage.toFixed(1)}V`;
        }
    } else {
        endVoltage = '-';
    }

    // average temperature
    if (test1.averageTemperature && test2.averageTemperature) {
        const avgCalculation = (test1.averageTemperature + test2.averageTemperature) / 2;
        avgTemperature = `${avgCalculation.toFixed(1)}°C`;
    } else {
        avgTemperature = '-';
    }
    // average current
    if (test1.averageCurrent && test2.averageCurrent) {
        const avgCalculation = (test1.averageCurrent + test2.averageCurrent) / 2;
        avgCurrent = `${avgCalculation.toFixed(1)}A`;
    } else {
        avgCurrent = '-';
    }
    // maximum discharged
    if (test1.discharged && test2.discharged) {
        if (test1.discharged > test2.discharged) {
            discharged = `${test1.discharged.toFixed(0)}Ah`;
        } else {
            discharged = `${test2.discharged.toFixed(0)}Ah`;
        }
    } else {
        discharged = '-';
    }

    return (
        <>
            <div className='mb-4'>
                <div className='font-bold'>{test1.device?.name} Results</div>
                <ResultView testResult={test1} />
                <div className='font-bold'>{test2.device?.name} Results</div>
                <ResultView testResult={test2} />
            </div>
            <div className='grid grid-cols-4'>
                <MetricDisplay label='Lowest end voltage' value={endVoltage} />
                <MetricDisplay label='Average current' value={avgCurrent} />
                <MetricDisplay label='Average temperature' value={avgTemperature} />
                <MetricDisplay label='Maximum discharged' value={discharged} />
            </div>
        </>
    );
};
