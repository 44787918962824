import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { BatteryTestResultsColumn } from 'filters/battery-test-results-model';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { renderTableStatusCell } from 'lib/table-columns';
import { formatBatteryTestName } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import {
    BatteryTestState,
    TestSelectModalContentQuery$data,
} from './__generated__/TestSelectModalContentQuery.graphql';

type BatteryTestResults = TestSelectModalContentQuery$data['batteryTestResults']['data'][number];

const columnHelper = createColumnHelper<BatteryTestResults>();

function renderNameColumn(row: BatteryTestResults) {
    return row.name ?? <div className='italic'>{formatBatteryTestName(row.cause)}</div>;
}
function renderStatusCell(value: BatteryTestState) {
    return renderTableStatusCell(value);
}

function renderRunTimeCell(data: BatteryTestResults) {
    const from = data.commencedTime ? DateTime.fromISO(data.commencedTime) : DateTime.now();
    const to = data.completedTime;

    if (to) {
        return formatDurationAsHoursAndMinutes(from, DateTime.fromISO(to));
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

export const TestNameColumn = columnHelper.display({
    id: BatteryTestResultsColumn.TaskName,
    header: 'Name',
    exportHeader: 'Name',
    cell: ({ row }) => renderNameColumn(row.original),
    exportValue({ row }) {
        return row.name ?? formatBatteryTestName(row.cause);
    },
    meta: {
        maxWidth: '10rem',
        filterable: true,
        sortable: true,
    },
});
export const TypeColumn = columnHelper.accessor('task.type', {
    id: BatteryTestResultsColumn.Type,
    header: 'Type',
    exportHeader: 'Type',
    cell: ({ cell }) => cell.getValue() ?? 'External',
    exportValue: ({ value }) => value ?? 'External',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const StatusColumn = columnHelper.accessor('state', {
    id: BatteryTestResultsColumn.State,
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    header: 'Result',
    exportHeader: 'Test Result',
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const DateColumn = columnHelper.accessor('commencedTime', {
    id: BatteryTestResultsColumn.StartTime,
    header: 'Date',
    exportHeader: 'Start Time',
    cell: ({ cell }) => {
        if (cell.getValue() === null) {
            return <div className='italic'>Test not started</div>;
        }
        return getDateTimeFormat(cell.getValue() as string);
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const RunTimeColumn = columnHelper.display({
    id: BatteryTestResultsColumn.RunTime,
    header: 'Run Time',
    exportHeader: 'Run Time (min)',
    cell: ({ row }) => renderRunTimeCell(row.original),
    exportValue: ({ row }) => {
        const from = row.commencedTime ? DateTime.fromISO(row.commencedTime) : DateTime.now();
        const to = row.completedTime;

        let minutes: number;
        if (to) {
            minutes = from.diff(DateTime.fromISO(to)).as('minutes');
        } else {
            minutes = from.diff(DateTime.now()).as('minutes');
        }

        return minutes.toFixed(0);
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const TestDeviceColumn = columnHelper.accessor('device.name', {
    id: BatteryTestResultsColumn.DeviceName,
    header: 'Device',
    exportHeader: 'Device Name',
    meta: {
        maxWidth: '10rem',
        filterable: true,
        sortable: true,
    },
});

export const SingleDeviceTestsBaseTableColumns = [
    TestNameColumn,
    TypeColumn,
    StatusColumn,
    DateColumn,
    RunTimeColumn,
] as ColumnWithId<BatteryTestResultsColumn, BatteryTestResults>[];

export const MultiDeviceTestsBaseTableColumns = [
    TestNameColumn,
    StatusColumn,
    TestDeviceColumn,
    DateColumn,
    RunTimeColumn,
] as ColumnWithId<BatteryTestResultsColumn, BatteryTestResults>[];
