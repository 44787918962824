import React, { FC, useEffect, useMemo, useState } from 'react';
import { PreloadedQuery, loadQuery, usePreloadedQuery } from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';

import { Menu, Modal, PageHeading, Tab, Tabs, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { AlertBanner } from 'components/AlertBanner';
import { useUserPermissions } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { MenuItemGroup } from 'lib/menu';
import { Paths } from 'lib/routes';
import { ExportForm } from 'views/exporting/components';

import style from '../style.module.css';
import { SingleSiteContent_SiteViewQuery } from './__generated__/SingleSiteContent_SiteViewQuery.graphql';
import { DeviceReport } from './device-section/DeviceReport';

export const BatterySingleSiteViewQuery = graphql`
    query SingleSiteContent_SiteViewQuery($siteId: ID!) {
        site(id: $siteId) {
            name
            address {
                state
                address
            }
            devices(filters: { category: [PowerController] }) {
                data {
                    id
                    name
                    type {
                        displayName
                    }
                    dualPlaneCompanion {
                        device {
                            id
                        }
                    }
                    battery {
                        installed
                        metrics {
                            originalCapacity
                            latestRemainingCapacity
                        }
                    }
                    ...Details_device
                }
            }
        }
    }
`;

export interface BatterySingleSiteViewContentProps {
    queryRef: PreloadedQuery<SingleSiteContent_SiteViewQuery>;
}

export const BatterySingleSiteViewContent: FC<BatterySingleSiteViewContentProps> = ({ queryRef }) => {
    const props = usePreloadedQuery<SingleSiteContent_SiteViewQuery>(BatterySingleSiteViewQuery, queryRef);

    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { siteId, deviceId } = useParams() as { siteId: string; deviceId: string };
    const navigate = useExtendedNavigate();
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const activeDeviceTab = useMemo(() => {
        if (!props.site) {
            return;
        }

        if (props.site.devices.data.length === 0) {
            return;
        }

        const matchingDevice = props.site.devices.data.find(device => device.id === deviceId);
        if (matchingDevice) {
            return { id: matchingDevice.id, label: matchingDevice.name };
        }
    }, [deviceId, props.site]);

    useDocumentTitle(`Reports - Batteries by site${props.site?.name ? ` - ${props.site.name}` : ''}`);

    let body: React.ReactNode;
    if (!props.site) {
        body = <div>Error unknown site</div>;
    } else {
        const { site } = props;
        const devices = [...site.devices.data].sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));
        const device = activeDeviceTab ? devices.find(device => device.id === activeDeviceTab.id) : null;

        let siteLocation: string;
        if (site.address.address) {
            siteLocation = `${site.address.address}, ${site.address.state}`;
        } else {
            siteLocation = site.address.state;
        }

        let reportSection = <></>;
        if (device) {
            reportSection = (
                <DeviceReport
                    id={device.id}
                    name={device.name}
                    device={device}
                    siteId={siteId}
                    batteryInstalled={device.battery.installed}
                />
            );
        } else if (devices.length === 0) {
            reportSection = (
                <div className={style.alert_container}>
                    <AlertBanner
                        title='No Device'
                        message='No devices configured on this site'
                        action={
                            hasAssetsWrite
                                ? {
                                      label: 'Add Device',
                                      onClick: () => navigate({ pathname: Paths.AddDevice, search: { site: siteId } }),
                                  }
                                : undefined
                        }
                    />
                </div>
            );
        } else {
            <div>Error unknown device</div>;
        }

        let tabsSection: React.ReactNode = <></>;
        if (props.site?.devices.data.length > 1 && activeDeviceTab) {
            const tabs: Tab[] = [];
            const devices = [...site.devices.data].sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name));

            const singlePlaneDevices = devices.filter(device => !device.dualPlaneCompanion?.device);
            for (const device of singlePlaneDevices) {
                tabs.push({
                    id: device.id,
                    label: device.name,
                    subtitle: device.type.displayName,
                });
            }

            const groups: string[] = [];
            const dualPlaneDevices = devices.filter(device => device.dualPlaneCompanion?.device);
            for (const device of dualPlaneDevices) {
                const ids = [device.id, device.dualPlaneCompanion!.device!.id];
                const groupId = ids.sort().join('-');
                if (!groups.includes(groupId)) {
                    groups.push(groupId);
                }

                const groupIndex = groups.indexOf(groupId) + 1;

                tabs.push({
                    id: device.id,
                    label: device.name,
                    subtitle: device.type.displayName,
                    group: `Plane ${groupIndex}`,
                });
            }

            tabsSection = (
                <div data-testid='tabs-component'>
                    <Tabs
                        activeItem={activeDeviceTab}
                        setActiveItem={value =>
                            navigate({
                                pathname: Paths.ReportBatteriesViewSiteDevice,
                                params: { siteId, deviceId: value.id },
                            })
                        }
                        tabs={tabs}
                        variant='card'
                    />
                </div>
            );
        }

        body = (
            <>
                <div className='flex flex-row justify-between items-center'>
                    <PageHeading value={`${site.name}, ${site.address.state}`} />
                    <Menu
                        id={`site-${siteId}-menu`}
                        groups={[
                            { key: MenuItemGroup.Assets, title: MenuItemGroup.Assets },
                            { key: MenuItemGroup.Reports, title: MenuItemGroup.Reports },
                        ]}
                        menuItems={[
                            { name: 'Export all devices', onClick: () => setIsExportModalOpen(true) },
                            {
                                name: hasAssetsWrite ? 'Edit site' : 'View site',
                                onClick: () => navigate({ pathname: Paths.EditSite, params: { id: siteId } }),
                                disabled: !hasAssetsRead,
                                group: MenuItemGroup.Assets,
                            },
                            {
                                name: 'AC power report',
                                onClick: () =>
                                    navigate({
                                        pathname: Paths.ReportACPowerSiteView,
                                        params: { siteId: siteId },
                                    }),
                                group: MenuItemGroup.Reports,
                            },
                        ]}
                    />
                </div>
                <div className={style.site_info}>
                    <div>
                        {/* FIXME: Remove the colour class from here once postcss and tailwind are working properly with css modules */}
                        <span className='text-coralRegular'>Site Location: </span>
                        <span>{siteLocation}</span>
                    </div>
                </div>
                {tabsSection}
                {reportSection}
                <Modal
                    bgColor='bg-white'
                    open={isExportModalOpen}
                    onHide={() => setIsExportModalOpen(false)}
                    closeButton={true}
                >
                    <ExportForm
                        deviceIds={devices.map(device => device.id)}
                        basicMetrics={['BatteryVoltage', 'BatteryCurrent', 'BatteryTemperature', 'BatteryPower']}
                    />
                </Modal>
            </>
        );
    }

    return (
        <>
            <div className={`${style.container} text-eggplantRegular`}>
                {/* FIXME: Remove the eggplant colour class from here once postcss and tailwind are working properly with css modules */}

                {body}
                <div className={style.footer}></div>
            </div>
        </>
    );
};

export const NavigateToFirstDevice: FC = () => {
    const { siteId } = useParams() as { siteId: string };
    const navigate = useExtendedNavigate();

    const queryRef = useLoaderData() as PreloadedQuery<SingleSiteContent_SiteViewQuery>;

    const data = usePreloadedQuery<SingleSiteContent_SiteViewQuery>(BatterySingleSiteViewQuery, queryRef);

    useEffect(() => {
        if (!data?.site) {
            // Let the component handle invalid site
            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: 'invalid-site' } },
                { replace: true }
            );
            return;
        }

        if (data.site.devices.data.length > 0) {
            const firstDevice = [...data.site.devices.data].sort((deviceA, deviceB) =>
                deviceA.name.localeCompare(deviceB.name)
            )[0];

            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: firstDevice.id } },
                { replace: true }
            );
        } else {
            // Let the component handle site with no devices
            navigate(
                { pathname: Paths.ReportBatteriesViewSiteDevice, params: { siteId, deviceId: 'empty-site' } },
                { replace: true }
            );
        }
        return;
    }, [data, navigate, siteId]);

    return <></>;
};

export function loadSingleSitePageData(id: string) {
    return loadQuery(
        getGlobalEnvironment(),
        BatterySingleSiteViewQuery,
        {
            siteId: id,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
