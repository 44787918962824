import graphql from 'babel-plugin-relay/macro';
import {
    BatteryTypeFilterState,
    BatteryTypeTableColumn,
    BatteryTypeTableColumnId,
    batteryTypeToFilterObject,
} from 'filters/battery-type';
import { SortDirection, TableState } from 'layouts';
import { FetchOptions } from 'lib/query-helpers';

import { useTableQuery } from '../table-query';
import {
    BatteryTypeOrdering,
    BatteryTypeSortField,
    requestBatteryTypeTableQuery,
} from './__generated__/requestBatteryTypeTableQuery.graphql';

export function useBatteryTypeTableQuery(
    tableState: TableState<BatteryTypeTableColumnId>,
    filters: BatteryTypeFilterState,
    fetchOptions?: FetchOptions
) {
    return useTableQuery<BatteryTypeTableColumnId, requestBatteryTypeTableQuery>(
        RequestQuery,
        options => {
            const sortObject: BatteryTypeOrdering = {
                field: options.orderBy as BatteryTypeSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            return {
                page: options.page,
                pageSize: options.pageSize,
                pageCount: options.pageCount,
                search: options.search,
                filter: batteryTypeToFilterObject(filters),
                orderBy: sortObject,
                withCapacityRating: options.visibleColumns.includes(BatteryTypeTableColumn.CellCapacityRating),
                withReferenceTemperature: options.visibleColumns.includes(
                    BatteryTypeTableColumn.CellReferenceTemperature
                ),
                withCellsPerBloc: options.visibleColumns.includes(BatteryTypeTableColumn.CellsPerBloc),
                withPeukertsConstant: options.visibleColumns.includes(BatteryTypeTableColumn.PeukertsConstant),
            };
        },
        tableState,
        fetchOptions
    );
}

const RequestQuery = graphql`
    query requestBatteryTypeTableQuery(
        $page: Int = 1
        $pageSize: Int = 50
        $pageCount: Int
        $search: String = ""
        $filter: BatteryTypeFilter
        $orderBy: BatteryTypeOrdering
        $withCapacityRating: Boolean = false
        $withReferenceTemperature: Boolean = false
        $withCellsPerBloc: Boolean = false
        $withPeukertsConstant: Boolean = false
    ) {
        batteryTypes(
            page: $page
            pageSize: $pageSize
            pageCount: $pageCount
            search: $search
            filters: $filter
            orderBy: $orderBy
        ) {
            total
            data {
                id
                model
                manufacturer
                cells {
                    capacity
                    capacityRating @include(if: $withCapacityRating)
                    referenceTemperature @include(if: $withReferenceTemperature)
                }
                cellsPerBloc @include(if: $withCellsPerBloc)
                peukertsConstant @include(if: $withPeukertsConstant)
                technology
                attributes {
                    name
                    value
                }
            }
            pageInfo {
                page
                size
                total
                hasNext
                hasPrevious
            }
        }
        overallBatteryTypes: batteryTypes {
            total
        }
    }
`;
