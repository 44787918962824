import { BatteryTypeFormValues, createDefaultBatteryTypeFormValues } from 'views/manage/battery-type/add-edit/schema';
import { AttributeFormValues } from 'views/manage/common/schema';
import { SiteFormValues, createDefaultSiteFormValues } from 'views/manage/site/add-edit/schema';

import { DeviceBatterySettings, createDefaultBatterySettings } from './sub-forms/battery-settings';
import {
    ConnectivitySettingsFormValues,
    createDefaultConnectivitySettingsValues,
} from './sub-forms/connectivity-settings/schema';

export enum DualPlaneConfigurationType {
    Single = 'Single',
    NPlusOne = 'NPlusOne',
    TwoN = 'TwoN',
}

export interface SiteReference {
    id: string;
    displayName: string;
}

export interface DeviceReference {
    id: string;
    displayName: string;
    siteName: string;
}

export interface OptimaIntegrationValues {
    elementName: string;
    siteId: string;
}

export interface CompanionFormValues {
    name: string;
    settings: ConnectivitySettingsFormValues;
    batteries: DeviceBatterySettings;
    optimaIntegration: OptimaIntegrationValues;
    monitorOnly: boolean;
    attributes: AttributeFormValues[];
}

export interface DeviceFormValues {
    name: string;
    category: string;
    type: string;
    site: SiteReference | null;
    newSite: SiteFormValues;
    firmwareVersion: string | null;
    settings: ConnectivitySettingsFormValues;
    batteries: DeviceBatterySettings;
    newBatteryType: BatteryTypeFormValues;
    monitorOnly: boolean;
    optimaIntegration: OptimaIntegrationValues;
    attributes: AttributeFormValues[];

    companionConfiguration: DualPlaneConfigurationType;
    companionReference: DeviceReference | null;
    newCompanion: CompanionFormValues;
}

function createDefaultOptimaIntegrationValues(): OptimaIntegrationValues {
    return { elementName: '', siteId: '' };
}

function createDefaultCompanionFormValues(): CompanionFormValues {
    return {
        name: '',
        settings: createDefaultConnectivitySettingsValues(),
        batteries: createDefaultBatterySettings(),
        monitorOnly: false,
        optimaIntegration: createDefaultOptimaIntegrationValues(),
        attributes: [],
    };
}

export function createDefaultDeviceFormValues(): DeviceFormValues {
    return {
        name: '',
        category: '',
        type: '',
        site: null,
        newSite: createDefaultSiteFormValues(),
        firmwareVersion: null,
        settings: createDefaultConnectivitySettingsValues(),
        batteries: createDefaultBatterySettings(),
        newBatteryType: createDefaultBatteryTypeFormValues(),
        monitorOnly: false,
        optimaIntegration: createDefaultOptimaIntegrationValues(),
        attributes: [],

        companionConfiguration: DualPlaneConfigurationType.Single,
        companionReference: null,
        newCompanion: createDefaultCompanionFormValues(),
    };
}
