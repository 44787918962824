/**
 * @generated SignedSource<<a13c6dfc7ea629b6832eff1045d32f92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type deleteValidationQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type deleteValidationQuery$data = {
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly devices: {
        readonly total: number;
      };
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type deleteValidationQuery = {
  response: deleteValidationQuery$data;
  variables: deleteValidationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "kind": "ObjectValue",
        "name": "filters"
      }
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaginatedDevices",
            "kind": "LinkedField",
            "name": "devices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteValidationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteValidationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b833d8eb67dabf77bdc20308d9aa225f",
    "id": null,
    "metadata": {},
    "name": "deleteValidationQuery",
    "operationKind": "query",
    "text": "query deleteValidationQuery(\n  $ids: [ID!]!\n) {\n  sites(filters: {ids: $ids}) {\n    data {\n      id\n      name\n      devices {\n        total\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "845af6d7adfed61b0d4a64e7bb3cd47e";

export default node;
