import React, { FC, useCallback, useReducer } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useParams } from 'react-router-dom';

import { LoadableContentArea, PageHeading, useExtendedNavigate, useReferrer, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { FormikProvisionLayout } from 'layouts/FormikProvisionLayout';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';
import { BatteryInformation } from 'views/manage/battery-type/add-edit/BatteryInformation';
import { convertBatteryTypeFormStateToApi } from 'views/manage/battery-type/add-edit/lib/convert';
import { addNewBatteryType } from 'views/manage/battery-type/add-edit/lib/saving';
import { ProvisionSectionHeading } from 'views/manage/components';
import { SiteInformation } from 'views/manage/site/add-edit/SiteInformation';
import { convertSiteFormStateToApi } from 'views/manage/site/add-edit/lib/convert';
import { addNewSite } from 'views/manage/site/add-edit/lib/saving';

import { DeviceInformation } from './DeviceInformation';
import { EditDeviceFormQuery } from './__generated__/EditDeviceFormQuery.graphql';
import { addNewDevice, decodeDeviceApiErrors, editDevice } from './helperFunctions';
import {
    convertDeviceFormStateToApi,
    convertDeviceFormStateToApiForUpdate,
    convertDeviceToFormState,
    switchCompanionStateToNormal,
} from './lib/convert';
import { useDeviceTypes } from './lib/device-types';
import { formControlInitialState, formControlReducer } from './lib/reducer';
import { DeviceFormValues } from './schema';
import { checkDeviceFormAdditionalValidity, getDeviceValidationSchema } from './validation';

export const EditDeviceForm: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const referrer = useReferrer() ?? Paths.Devices;
    const environment = useRelayEnvironment();
    const deviceTypes = useDeviceTypes();

    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { id: deviceId } = useParams() as { id: string };

    let title: string;
    if (hasAssetsWrite) {
        title = 'Edit Device';
    } else {
        title = 'View Device';
    }

    useDocumentTitle(title);

    const { show } = useToast();

    // device state variables
    const [formControlState, formControlDispatch] = useReducer(formControlReducer, formControlInitialState);

    // FIXME: Use a suspending query so we don't have to handle null states
    const { data, error } = useQuery<EditDeviceFormQuery>(
        LoadExistingDeviceQuery,
        {
            deviceId,
        },
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleSite = useCallback(
        async (values: DeviceFormValues, setErrors: (errors: FormikErrors<DeviceFormValues>) => void) => {
            try {
                const newSite = convertSiteFormStateToApi(values.newSite);
                return await addNewSite(newSite, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newSite: formErrors });

                    show({
                        text: 'Unable to save site. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('Device', deviceId);
                        return scope;
                    });

                    show({
                        text: 'Unable to save site. Try again later',
                        variant: 'error',
                    });
                }
                return;
            }
        },
        [deviceId, environment, show]
    );

    const handleBattery = useCallback(
        async (values: DeviceFormValues, setErrors: (errors: FormikErrors<DeviceFormValues>) => void) => {
            try {
                const newBatteryType = convertBatteryTypeFormStateToApi(values.newBatteryType);
                return await addNewBatteryType(newBatteryType, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newBatteryType: formErrors });

                    show({
                        text: 'Unable to save battery type. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('Device', deviceId);
                        return scope;
                    });

                    show({
                        text: 'Unable to save battery type. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [deviceId, environment, show]
    );

    const handleCompanionDevice = useCallback(
        async (
            values: DeviceFormValues,
            setErrors: (errors: FormikErrors<DeviceFormValues>) => void
        ): Promise<string | undefined> => {
            const updatedValues = switchCompanionStateToNormal(
                values,
                formControlState.duplicateCompanionPlaneBatteries
            );

            const deviceToAdd = convertDeviceFormStateToApi(updatedValues, deviceTypes);

            try {
                return await addNewDevice(deviceToAdd, environment);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeDeviceApiErrors(error);
                    setErrors({ newCompanion: formErrors });

                    show({
                        text: 'Unable to save device. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('Device', deviceId);
                        return scope;
                    });

                    show({
                        text: 'Unable to save device. Try again later',
                        variant: 'error',
                    });
                }
                return;
            }
        },
        [deviceId, deviceTypes, environment, formControlState.duplicateCompanionPlaneBatteries, show]
    );

    const handleEdit = useCallback(
        async (values: DeviceFormValues, { setErrors, setSubmitting }: FormikHelpers<DeviceFormValues>) => {
            setSubmitting(true);
            try {
                // NEW SITE SECTION
                if (formControlState.addNewSite) {
                    const siteId = await handleSite(values, setErrors);
                    if (!siteId) {
                        setSubmitting(false);
                        return;
                    }
                    values.site = {
                        id: siteId,
                        displayName: '',
                    };
                }

                // NEW BATTERY SECTION
                if (formControlState.addNewBatteryType) {
                    const addedBatteryTypeId = await handleBattery(values, setErrors);
                    if (!addedBatteryTypeId) {
                        setSubmitting(false);
                        return;
                    }
                    for (const batteryString of values.batteries.strings) {
                        if (batteryString.type === null) {
                            batteryString.type = {
                                id: addedBatteryTypeId,
                                manufacturer: '',
                                model: '',
                                displayName: '',
                            };
                        }
                    }
                    for (const batteryString of values.newCompanion.batteries.strings) {
                        if (batteryString.type === null) {
                            batteryString.type = {
                                id: addedBatteryTypeId,
                                manufacturer: '',
                                model: '',
                                displayName: '',
                            };
                        }
                    }
                }

                // NEW COMPANION DEVICE SECTION
                if (formControlState.addNewDualPlaneCompanionDevice) {
                    const addedCompanionDeviceId = await handleCompanionDevice(values, setErrors);
                    if (!addedCompanionDeviceId) {
                        setSubmitting(false);
                        return;
                    }
                    values.companionReference = {
                        id: addedCompanionDeviceId,
                        displayName: '',
                        siteName: '',
                    };
                }

                const update = convertDeviceFormStateToApiForUpdate(values, deviceTypes);

                try {
                    await editDevice(deviceId, update, environment);
                    show({
                        text: 'Device updated successfully!',
                        variant: 'info',
                    });
                    navigate(referrer);
                } catch (error) {
                    if (Array.isArray(error)) {
                        const formErrors = decodeDeviceApiErrors(error);
                        setErrors(formErrors);

                        show({
                            text: 'Unable to save device. Please correct the highlighted errors',
                            variant: 'error',
                        });
                    } else {
                        captureException(error, scope => {
                            scope.setTag('Device', deviceId);
                            return scope;
                        });

                        show({
                            text: 'Unable to save device. Try again later',
                            variant: 'error',
                        });
                    }
                }
            } finally {
                setSubmitting(false);
            }
        },

        [
            deviceId,
            deviceTypes,
            environment,
            formControlState.addNewBatteryType,
            formControlState.addNewDualPlaneCompanionDevice,
            formControlState.addNewSite,
            handleBattery,
            handleCompanionDevice,
            handleSite,
            navigate,
            referrer,
            show,
        ]
    );

    if (error || (data && data.device === null)) {
        return <ErrorNotFound />;
    }

    return (
        <div className='space-y-6'>
            <PageHeading value={title} />
            <LoadableContentArea
                data={data}
                className='h-96'
                render={loadedData => (
                    <Formik
                        initialValues={convertDeviceToFormState(loadedData.device)}
                        validationSchema={getDeviceValidationSchema(
                            formControlState.addNewSite,
                            formControlState.addNewBatteryType,
                            deviceTypes
                        )}
                        validate={values => checkDeviceFormAdditionalValidity(values, formControlState)}
                        onSubmit={handleEdit}
                    >
                        <FormikProvisionLayout
                            type='device'
                            secondaryAction={() => navigate(referrer)} // FIXME: This would be better as a Link
                            operation={hasAssetsWrite ? 'edit' : 'view'}
                        >
                            <DeviceInformation
                                deviceTypes={deviceTypes}
                                // form control
                                formControlState={formControlState}
                                formControlDispatch={formControlDispatch}
                            />

                            {formControlState.addNewSite && (
                                <div className='pt-6 space-y-6'>
                                    <ProvisionSectionHeading>New Site</ProvisionSectionHeading>
                                    <SiteInformation namespace='newSite' />
                                </div>
                            )}

                            {formControlState.addNewBatteryType && (
                                <div className='pt-12'>
                                    <BatteryInformation namespace='newBatteryType' />
                                </div>
                            )}
                        </FormikProvisionLayout>
                    </Formik>
                )}
            />
        </div>
    );
};

const LoadExistingDeviceQuery = graphql`
    query EditDeviceFormQuery($deviceId: ID!) {
        device(id: $deviceId) {
            name
            type {
                id
                category
                displayName
            }
            firmware {
                id
                name
                defined
            }
            site {
                id
                name
            }
            monitorOnly
            connectionSettings {
                addresses
                protocols {
                    definition
                    type
                    displayName
                    ... on ProtocolBasic {
                        credentials {
                            username
                            password {
                                value
                            }
                        }
                    }
                    ... on ProtocolSnmp {
                        port
                        version
                        settings {
                            ... on ProtocolSnmpV1And2cSettings {
                                readOnlyCommunity
                                readWriteCommunity
                            }
                            ... on ProtocolSnmpV3Settings {
                                engineId
                                user
                                securityLevel
                                authType
                                authPassphrase {
                                    isSet
                                    canBeRetrieved
                                    value
                                }
                                privType
                                privPassphrase {
                                    isSet
                                    canBeRetrieved
                                    value
                                }
                            }
                        }
                    }
                    ... on ProtocolGateway {
                        gateway {
                            id
                            name
                            site {
                                name
                            }
                        }
                        localId
                    }
                }
            }
            attributes {
                name
                value
            }
            integrations {
                type {
                    ... on FBDRIntegration {
                        id
                        name
                        enabled
                        deviceAttributeDefinitions {
                            name
                            type
                            required
                            minimum
                            maximum
                        }
                        hosts
                        key
                    }
                }
                attributes {
                    name
                    value
                }
            }
            battery {
                strings {
                    count
                    strings {
                        id
                        type {
                            id
                            manufacturer
                            model
                        }
                        installDate
                        manufactureDate
                        cellsPerString
                    }
                }
                reserveTime
                allowedVoltage {
                    minimum
                    maximum
                }
                quickTestFailThreshold
                quickTestCheckPercent
            }
            dualPlaneCompanion {
                device {
                    id
                    name
                    site {
                        name
                    }
                }
                configuration
            }
        }
    }
`;
