import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowDownCircleIcon,
    ArrowDownIcon,
    ArrowRightIcon,
    ArrowUpCircleIcon,
    ArrowUpIcon,
    BatteryChargingIcon,
    CalendarIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    LoadIcon,
    MinusIcon,
    PowerIcon,
    StatusLabel,
    Tooltip,
    VoltmeterIcon,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { getDateTimeFormat, getHumanizedTextBetweenDates } from 'lib/dateFormatter';
import { incidentStatusToStatusLabelConfig } from 'lib/statusLabelFormatter';

import { ACPowerEventStatus } from './__generated__/ACPowerSiteIncidentListTableQuery.graphql';
import { SiteIncidentExpandedRow_event$key } from './__generated__/SiteIncidentExpandedRow_event.graphql';
import style from './style.module.css';

export interface SiteIncidentExpandedRowProps {
    row: SiteIncidentExpandedRow_event$key;
}

export const SiteIncidentExpandedRow: FC<SiteIncidentExpandedRowProps> = ({ row }) => {
    const incident = useFragment(Fragment, row);

    let statusDescription: string;

    switch (incident.worstStatus) {
        case 'Outage':
            statusDescription = 'Outage';
            break;
        case 'UnderVoltage':
            statusDescription = 'Undervoltage';
            break;
        case 'OverVoltage':
            statusDescription = 'Overvoltage';
            break;
        default:
        case '%future added value':
            statusDescription = 'Unknown incident';
            break;
    }

    let incidentDescription: string;

    if (incident.affectedAllDevices) {
        incidentDescription = `Site-wide ${statusDescription.toLowerCase()}`;
    } else {
        incidentDescription = `Internal ${statusDescription.toLowerCase()}`;
    }

    if (incident.affectedAllFeeds) {
        incidentDescription = `${incidentDescription} across all feeds`;
    } else {
        const realAffectedFeeds = incident.affectedFeeds.filter(feed => feed.status !== 'Normal');

        incidentDescription += ' on ';

        if (realAffectedFeeds.length === 1) {
            incidentDescription += `feed ${realAffectedFeeds[0].label} only`;
        } else {
            incidentDescription += `${realAffectedFeeds.length} feeds`;
        }
    }

    if (!incident.affectedAllDevices) {
        const realAffectedDevices = incident.affectedDevices.filter(device => device.name !== incident.site.name);

        if (realAffectedDevices.length > 1) {
            incidentDescription += ` affecting ${realAffectedDevices.length} devices`;
        } else if (realAffectedDevices.length === 1) {
            incidentDescription += ` affecting ${realAffectedDevices[0].name}`;
        }
    }

    // Tooltips
    let dischargedTooltip: string;
    let dodTooltip: string;
    let uptimeTooltip: string;

    if (incident.stats.batteryDidDischarge) {
        dischargedTooltip = 'The battery discharged during this incident';
    } else {
        dischargedTooltip = 'The battery did not discharge during this incident';
    }

    if (incident.stats.batteryDoD == null || !incident.stats.batteryDidDischarge) {
        dodTooltip = 'The battery did not discharge';
    } else {
        dodTooltip = `The battery discharged ${incident.stats.batteryDoD.toFixed(0)}% during this incident`;
    }

    if (incident.stats.loadUptime != null) {
        if (incident.stats.loadUptime >= 100) {
            uptimeTooltip = 'The load was unaffected by this incident';
        } else {
            uptimeTooltip = `The load was kept online ${incident.stats.loadUptime.toFixed(0)}% of the time`;
        }
    } else {
        uptimeTooltip = 'The load uptime is unknown';
    }

    return (
        <div className='p-8' key={`expanded-row-${incident.id}`} data-testid='incident-list-expanded-row'>
            <div className='flex flex-row justify-between pb-4 flex-wrap'>
                <div>
                    <div className='font-bold text-2xl' data-testid='incident-expanded-device-name'>
                        {incident.site.name}
                    </div>
                    <div className='font-light' data-testid='incident-expanded-address'>
                        {incidentDescription}
                    </div>
                </div>

                <div data-testid='incident-expanded-severity'>
                    <StatusLabel
                        label={statusDescription.toUpperCase()}
                        {...incidentStatusToStatusLabelConfig(incident.worstStatus)}
                    />
                </div>
            </div>

            <div className='flex flex-row flex-wrap'>
                <Tooltip content='Started at'>
                    <div className='flex flex-row items-center' data-testid='incident-expanded-active-date-text'>
                        <div className='h-4 w-8 flex flex-row text-coralRegular mr-2'>
                            <CalendarIcon />
                            <ArrowUpIcon />
                        </div>
                        {getDateTimeFormat(incident.startTime)}
                    </div>
                </Tooltip>

                <div className='px-8 flex flex-row items-center'>
                    <div className='h-4 w-4 mr-2'>
                        <MinusIcon />
                    </div>
                    <div className='text-sm text-eggplantLight' data-testid='incident-expanded-cleared-duration-text'>
                        {getHumanizedTextBetweenDates(incident.startTime, incident.endTime)} later
                    </div>
                    <div className='h-4 w-4 ml-2'>
                        <ArrowRightIcon />
                    </div>
                </div>

                <Tooltip content={'Finished at'}>
                    <div className='flex flex-row items-center' data-testid='incident-expanded-cleared-date-element'>
                        <div className='h-4 w-8 flex flex-row text-eggplantLight mr-2'>
                            <CalendarIcon />
                            <ArrowDownIcon />
                        </div>
                        {getDateTimeFormat(incident.endTime)}
                    </div>
                </Tooltip>
            </div>
            <div className='grid grid-cols-3 gap-4 mt-2'>
                <div>
                    <div className='font-bold flex flex-row gap-1 items-center'>
                        <div className='w-4 h-4'>
                            <PowerIcon />
                        </div>
                        <span>AC feeds</span>
                    </div>
                    <div className={style.ac_feeds_section}>
                        {incident.affectedFeeds.map(feed => (
                            <React.Fragment key={feed.id}>
                                <div>{feed.label}</div>
                                <div className='flex flex-row items-center gap-1'>
                                    <div className='w-4 h-4'>
                                        <VoltmeterIcon />
                                    </div>
                                    <Tooltip
                                        content={
                                            feed.voltage != null
                                                ? `${feed.voltage.toFixed(0)}V measured at the start of the incident`
                                                : 'No voltage reading was taken'
                                        }
                                    >
                                        <div className='text-sm'>
                                            {feed.voltage != null ? `${feed.voltage.toFixed(0)}V` : `N/A`}
                                        </div>
                                    </Tooltip>
                                </div>
                                {renderStatus(feed.status)}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div>
                    <div className='font-bold flex flex-row gap-1 items-center'>
                        <div className='w-4 h-4'>
                            <BatteryChargingIcon />
                        </div>
                        <span>Battery</span>
                    </div>
                    <div className='ml-4'>
                        <div>
                            <Tooltip content={dischargedTooltip}>
                                <div className='font-light w-min'>
                                    Discharged:{' '}
                                    <span className='font-normal'>
                                        {incident.stats.batteryDidDischarge ? 'Yes' : 'No'}
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip content={dodTooltip}>
                                <div className='font-light w-min'>
                                    DoD:{' '}
                                    <span className='font-normal'>
                                        {incident.stats.batteryDoD == null || !incident.stats.batteryDidDischarge
                                            ? 'N/A'
                                            : `${incident.stats.batteryDoD.toFixed(0)}%`}
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='font-bold flex flex-row gap-1 items-center'>
                        <div className='w-4 h-4'>
                            <LoadIcon />
                        </div>
                        <span>Load</span>
                    </div>
                    <div className='ml-4'>
                        <div>
                            <Tooltip content={uptimeTooltip}>
                                <div className='font-light w-min'>
                                    Uptime:{' '}
                                    <span className='font-normal'>
                                        {incident.stats.loadUptime == null
                                            ? 'Unknown'
                                            : `${incident.stats.loadUptime.toFixed(0)}%`}
                                    </span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment SiteIncidentExpandedRow_event on SiteACPowerEvent {
        id
        startTime
        endTime
        duration
        worstStatus
        affectedAllFeeds
        affectedFeeds {
            id
            label
            voltage
            status
        }
        affectedAllDevices
        affectedDevices {
            name
        }
        stats {
            batteryDidDischarge
            batteryDoD
            loadUptime
        }
        site {
            name
        }
    }
`;

function renderStatus(status: ACPowerEventStatus): ReactNode {
    let icon: ReactNode;
    let label: string;
    let colour: string;

    switch (status) {
        case 'Outage':
            icon = <CircleCrossIcon />;
            label = 'Outage';
            colour = 'text-coralRegular';
            break;
        case 'UnderVoltage':
            icon = <ArrowDownCircleIcon />;
            label = 'Undervoltage';
            colour = 'text-mauveRegular';
            break;
        case 'OverVoltage':
            icon = <ArrowUpCircleIcon />;
            label = 'Overvoltage';
            colour = 'text-mustardRegular';
            break;
        case 'Normal':
            icon = <CircleCheckIcon />;
            label = 'Online';
            colour = 'text-pineRegular';
            break;
        default:
        case '%future added value':
            icon = <CircleHelpIcon />;
            label = 'Unknown';
            colour = 'text-eggplantRegular';
            break;
    }

    return (
        <div className={classNames('flex flex-row items-center gap-1', colour)}>
            <div className='w-4 h-4'>{icon}</div>
            <div className='text-sm'>{label}</div>
        </div>
    );
}
