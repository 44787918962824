import React, { FC, useMemo } from 'react';
import { useFragment } from 'react-relay';

import { InlineSparkline, InlineSparklineSeries, Link, NavigateTo } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { CustomDateRangeFilter } from 'components/FilterDateSelect/common';
import { numberToLocaleString } from 'lib/numberFormatters';
import { DateTime, Duration } from 'luxon';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';

import style from '../style.module.css';
import { VitalsTable_info$key } from './__generated__/VitalsTable_info.graphql';

export interface VitalsTableProps {
    runReport: VitalsTable_info$key;
}

type SparklineOffsetData = { offset: string; value: number | null };

function toSparklineOffsetData(data: SparklineOffsetData[] | null): InlineSparklineSeries[] {
    if (!data) {
        return [];
    }

    return data.flatMap(point => {
        if (point.value !== null) {
            const key = Duration.fromISO(point.offset);
            if (key.toMillis() >= 0) {
                return [
                    {
                        key: key,
                        value: point.value,
                    },
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    });
}

export const VitalsTable: FC<VitalsTableProps> = ({ runReport }) => {
    const data = useFragment<VitalsTable_info$key>(VitalsTableFragment, runReport);

    const processedOilPressureOverTime: InlineSparklineSeries[] = useMemo(
        () => toSparklineOffsetData(data.oilPressureOverTime as SparklineOffsetData[]),
        [data.oilPressureOverTime]
    );

    const processedOilTemperatureOverTime: InlineSparklineSeries[] = useMemo(
        () => toSparklineOffsetData(data.oilTemperatureOverTime as SparklineOffsetData[]),
        [data.oilTemperatureOverTime]
    );

    const processedCoolantPressureOverTime: InlineSparklineSeries[] = useMemo(
        () => toSparklineOffsetData(data.coolantPressureOverTime as SparklineOffsetData[]),
        [data.coolantPressureOverTime]
    );

    const processedCoolantTemperatureOverTime: InlineSparklineSeries[] = useMemo(
        () => toSparklineOffsetData(data.coolantTemperatureOverTime as SparklineOffsetData[]),
        [data.coolantTemperatureOverTime]
    );

    const processedRpmOverTime: InlineSparklineSeries[] = useMemo(
        () => toSparklineOffsetData(data.rpmOverTime as SparklineOffsetData[]),
        [data.rpmOverTime]
    );

    const linkDate: CustomDateRangeFilter = {
        type: 'custom',
        range: [
            DateTime.fromISO(data.generatorStartTime as string).toJSDate(),
            data.generatorStopTime ? DateTime.fromISO(data.generatorStopTime).toJSDate() : DateTime.now().toJSDate(),
        ],
    };

    return (
        <div data-testid='vitals-table'>
            <div className='font-semibold'>Vitals</div>
            <div className={style.vitals_table}>
                <div className='items-center py-2 grid col-start-2 col-span-3 grid-cols-subgrid font-light'>
                    <div>Average</div>
                    <div>Minimum</div>
                    <div>Maximum</div>
                </div>
                <GridRow
                    title='Oil pressure'
                    units='psi'
                    average={data.oilPressure?.average}
                    min={data.oilPressure?.min}
                    max={data.oilPressure?.max}
                    data={processedOilPressureOverTime}
                    link={makeLinkToMetric(
                        data.generator.id,
                        {
                            metric: 'GeneratorOilPressure',
                            op: Operation.Average,
                        },
                        linkDate
                    )}
                />
                <GridRow
                    title='Oil temperature'
                    units='°C'
                    average={data.oilTemperature?.average}
                    min={data.oilTemperature?.min}
                    max={data.oilTemperature?.max}
                    data={processedOilTemperatureOverTime}
                    link={makeLinkToMetric(
                        data.generator.id,
                        {
                            metric: 'GeneratorOilTemperature',
                            op: Operation.Average,
                        },
                        linkDate
                    )}
                />
                <GridRow
                    title='Coolant pressure'
                    units='psi'
                    average={data.coolantPressure?.average}
                    min={data.coolantPressure?.min}
                    max={data.coolantPressure?.max}
                    data={processedCoolantPressureOverTime}
                    link={makeLinkToMetric(
                        data.generator.id,
                        {
                            metric: 'GeneratorCoolantPressure',
                            op: Operation.Average,
                        },
                        linkDate
                    )}
                />
                <GridRow
                    title='Coolant temperature'
                    units='°C'
                    average={data.coolantTemperature?.average}
                    min={data.coolantTemperature?.min}
                    max={data.coolantTemperature?.max}
                    data={processedCoolantTemperatureOverTime}
                    link={makeLinkToMetric(
                        data.generator.id,
                        {
                            metric: 'GeneratorCoolantTemperature',
                            op: Operation.Average,
                        },
                        linkDate
                    )}
                />
                <GridRow
                    title='RPM'
                    average={data.rpm?.average}
                    min={data.rpm?.min}
                    max={data.rpm?.max}
                    data={processedRpmOverTime}
                    link={makeLinkToMetric(
                        data.generator.id,
                        {
                            metric: 'GeneratorRPM',
                            op: Operation.Average,
                        },
                        linkDate
                    )}
                />
            </div>
        </div>
    );
};

const VitalsTableFragment = graphql`
    fragment VitalsTable_info on GeneratorRunReport {
        generator {
            id
        }
        generatorStartTime
        generatorStopTime
        oilPressure(unit: PSI) {
            min
            max
            average
        }
        oilTemperature(unit: Celsius) {
            min
            max
            average
        }
        coolantPressure(unit: PSI) {
            min
            max
            average
        }
        coolantTemperature(unit: Celsius) {
            min
            max
            average
        }
        rpm {
            min
            max
            average
        }
        oilPressureOverTime(unit: PSI, points: 10) {
            offset
            value
        }
        oilTemperatureOverTime(unit: Celsius, points: 10) {
            offset
            value
        }
        coolantPressureOverTime(unit: PSI, points: 10) {
            offset
            value
        }
        coolantTemperatureOverTime(unit: Celsius, points: 10) {
            offset
            value
        }
        rpmOverTime(points: 10) {
            offset
            value
        }
    }
`;

interface GridRowProps {
    title: string;
    average: number | null | undefined;
    min: number | null | undefined;
    max: number | null | undefined;
    data: InlineSparklineSeries[];
    link: NavigateTo;
    units?: string;
}

const GridRow: FC<GridRowProps> = ({ title, units, average, min, max, data, link }) => {
    return (
        <div
            className='items-center p-2 grid col-span-4 lg:col-span-5 grid-cols-subgrid bg-grayLight'
            data-testid={`${title.replaceAll(' ', '-').toLowerCase()}-grid-row`}
        >
            <Link className={classNames('font-semibold', { 'hover:underline': link })} to={link}>
                {title}
            </Link>
            <div data-testid='grid-row'>
                {numberToLocaleString(average ?? null, 0)}
                {units}
            </div>
            <div data-testid='grid-row'>
                {numberToLocaleString(min ?? null, 0)}
                {units}
            </div>
            <div data-testid='grid-row'>
                {numberToLocaleString(max ?? null, 0)}
                {units}
            </div>
            <div className='h-8 pt-2 col-span-4 lg:col-span-1 lg:pt-0'>
                <InlineSparkline data={data} />
            </div>
        </div>
    );
};
