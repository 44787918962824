/**
 * @generated SignedSource<<73f384a22c38279eaf6df24278e08f57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SearchQuery$variables = {
  pageSize: number;
  search: string;
};
export type SearchQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly site: {
        readonly address: {
          readonly state: string;
        };
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
  readonly sites: {
    readonly data: ReadonlyArray<{
      readonly address: {
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type SearchQuery = {
  response: SearchQuery$data;
  variables: SearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "dir": "Asc",
    "field": "Name"
  }
},
v3 = {
  "kind": "Variable",
  "name": "pageSize",
  "variableName": "pageSize"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Address",
    "kind": "LinkedField",
    "name": "address",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "PaginatedSites",
    "kind": "LinkedField",
    "name": "sites",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "site",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "b1b5bbec7459d8e8b2e1fe8be8e74de3",
    "id": null,
    "metadata": {},
    "name": "SearchQuery",
    "operationKind": "query",
    "text": "query SearchQuery(\n  $search: String!\n  $pageSize: Int!\n) {\n  sites(search: $search, pageSize: $pageSize, orderBy: {field: Name, dir: Asc}, onlyProvisioningStatuses: Active) {\n    data {\n      id\n      name\n      address {\n        state\n      }\n    }\n  }\n  devices(search: $search, pageSize: $pageSize, orderBy: {field: Name, dir: Asc}) {\n    data {\n      id\n      name\n      site {\n        id\n        name\n        address {\n          state\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b6a8aae12cae0513aaec0cb79276acc";

export default node;
