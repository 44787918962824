/**
 * @generated SignedSource<<f93de256026c7dbaf43fe50bdca85e89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type GeneratorRunReportCause = "LocalOtherStart" | "PowerFailure" | "RemoteStart" | "Unknown" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MetricsView_data$data = {
  readonly averageOutputPower: number | null;
  readonly cause: GeneratorRunReportCause;
  readonly fuelConsumed: number | null;
  readonly totalEnergyOutput: number | null;
  readonly " $fragmentType": "MetricsView_data";
};
export type MetricsView_data$key = {
  readonly " $data"?: MetricsView_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsView_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsView_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Liter"
        }
      ],
      "kind": "ScalarField",
      "name": "fuelConsumed",
      "storageKey": "fuelConsumed(unit:\"Liter\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "WattHour"
        }
      ],
      "kind": "ScalarField",
      "name": "totalEnergyOutput",
      "storageKey": "totalEnergyOutput(unit:\"WattHour\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Watt"
        }
      ],
      "kind": "ScalarField",
      "name": "averageOutputPower",
      "storageKey": "averageOutputPower(unit:\"Watt\")"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};

(node as any).hash = "a3c17025724546367ba8edd6233fe59d";

export default node;
