import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';

import { GeneratorRunCard } from '../../overview/components';
import { PreviousRuns_data$key } from './__generated__/PreviousRuns_data.graphql';

interface PreviousRunsProps {
    runReport: PreviousRuns_data$key;
}

export const PreviousRuns: FC<PreviousRunsProps> = ({ runReport }) => {
    const data = useFragment<PreviousRuns_data$key>(PreviousRunsFragment, runReport);

    if (data.previousReports.data.length === 0) {
        return null;
    } else {
        const cards = (data.previousReports.data ?? []).map((runReport, index) => (
            <GeneratorRunCard key={index} runReport={runReport} />
        ));

        return (
            <div>
                <div className='font-semibold'>Previous runs</div>
                <div className='mt-4 gap-4 grid grid-cols-3'>{cards}</div>
            </div>
        );
    }
};

const PreviousRunsFragment = graphql`
    fragment PreviousRuns_data on GeneratorRunReport {
        previousReports(pageSize: 3) {
            data {
                id
                cause
                state
                generator {
                    id
                    name
                    site {
                        id
                        name
                        address {
                            state
                        }
                    }
                }
                generatorStartTime
                generatorStopTime
            }
        }
    }
`;
