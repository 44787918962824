/**
 * @generated SignedSource<<a811147cb735d4e63052adf980dda7eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GatewayProtocolConnectivityStatus = "GatewayBadCredentials" | "GatewayUnreachable" | "NeNotFound" | "NeOffline" | "Ok" | "%future added value";
export type SNMPProtocolConnectivityStatus = "BadCredentials" | "Ok" | "ReadOnly" | "Unreachable" | "WriteOnly" | "%future added value";
export type SnmpAuthAlgorithm = "MD5" | "SHA1" | "%future added value";
export type SnmpPrivAlgorithm = "AES" | "DES" | "%future added value";
export type SnmpSecurityLevel = "AuthNoPriv" | "AuthPriv" | "NoAuthNoPriv" | "%future added value";
export type SnmpVersion = "V1" | "V2c" | "V3" | "%future added value";
export type WebProtocolConnectivityStatus = "BadCredentials" | "Ok" | "Reachable" | "Unreachable" | "%future added value";
export type ConnectionSettingsIn = {
  addresses: ReadonlyArray<string>;
  protocols: ReadonlyArray<ProtocolIn>;
};
export type ProtocolIn = {
  basic?: ProtocolBasicIn | null;
  gateway?: ProtocolGatewayIn | null;
  id: string;
  snmp?: ProtocolSnmpIn | null;
};
export type ProtocolSnmpIn = {
  port?: number | null;
  settingsV1V2c?: ProtocolSnmpV1AndV2cIn | null;
  settingsV3?: ProtocolSnmpV3In | null;
  version: SnmpVersion;
};
export type ProtocolSnmpV1AndV2cIn = {
  readOnlyCommunity: string;
  readWriteCommunity: string;
};
export type ProtocolSnmpV3In = {
  authPassphrase?: string | null;
  authType: SnmpAuthAlgorithm;
  engineId?: string | null;
  privPassphrase?: string | null;
  privType: SnmpPrivAlgorithm;
  securityLevel: SnmpSecurityLevel;
  user: string;
};
export type ProtocolBasicIn = {
  password: string;
  username: string;
};
export type ProtocolGatewayIn = {
  gatewayId: string;
  localId: string;
};
export type useConnectionTestMutation$variables = {
  deviceType: string;
  settings: ConnectionSettingsIn;
};
export type useConnectionTestMutation$data = {
  readonly checkConnectivity: {
    readonly addresses: ReadonlyArray<{
      readonly address: string;
      readonly protocols: ReadonlyArray<{
        readonly gatewayStatus?: GatewayProtocolConnectivityStatus;
        readonly protocolId: string;
        readonly snmpStatus?: SNMPProtocolConnectivityStatus;
        readonly webStatus?: WebProtocolConnectivityStatus;
      }>;
      readonly reachable: boolean;
    }>;
  } | null;
};
export type useConnectionTestMutation = {
  response: useConnectionTestMutation$data;
  variables: useConnectionTestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "settings"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deviceType",
    "variableName": "deviceType"
  },
  {
    "kind": "Variable",
    "name": "settings",
    "variableName": "settings"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reachable",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "protocolId",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "snmpStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "SNMPProtocolConnectivityResult",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "gatewayStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "GatewayProtocolConnectivityResult",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "webStatus",
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "WebProtocolConnectivityResult",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useConnectionTestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConnectivityResult",
        "kind": "LinkedField",
        "name": "checkConnectivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressConnectivityResult",
            "kind": "LinkedField",
            "name": "addresses",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "protocols",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useConnectionTestMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConnectivityResult",
        "kind": "LinkedField",
        "name": "checkConnectivity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddressConnectivityResult",
            "kind": "LinkedField",
            "name": "addresses",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "protocols",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0991058a48c233f1e92503bcf36640a2",
    "id": null,
    "metadata": {},
    "name": "useConnectionTestMutation",
    "operationKind": "mutation",
    "text": "mutation useConnectionTestMutation(\n  $deviceType: ID!\n  $settings: ConnectionSettingsIn!\n) {\n  checkConnectivity(settings: $settings, deviceType: $deviceType) {\n    addresses {\n      address\n      reachable\n      protocols {\n        __typename\n        protocolId\n        ... on SNMPProtocolConnectivityResult {\n          snmpStatus: status\n        }\n        ... on GatewayProtocolConnectivityResult {\n          gatewayStatus: status\n        }\n        ... on WebProtocolConnectivityResult {\n          webStatus: status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "01c2272bc79263b82292a572a8009c55";

export default node;
