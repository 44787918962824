import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';

import { FuelStats_data$key } from './__generated__/FuelStats_data.graphql';

export interface FuelStatsProps {
    runReport: FuelStats_data$key;
}

export const FuelStats: FC<FuelStatsProps> = ({ runReport }) => {
    const data = useFragment<FuelStats_data$key>(FuelStatsFragment, runReport);

    return (
        <div data-testid='fuel-stats'>
            <div className='pb-4 font-semibold'>Fuel</div>
            <div className='flex justify-between w-1/2'>
                <FuelMetric label='Consumed' value={data.fuelConsumed} />
                <FuelMetric label='Capacity' value={data.generator.fuelTankMetrics.capacity} />
                <FuelMetric label='Refuelled' value={data.fuelRefuelled} />
            </div>
        </div>
    );
};

const FuelStatsFragment = graphql`
    fragment FuelStats_data on GeneratorRunReport {
        fuelConsumed(unit: Liter)
        fuelRefuelled(unit: Liter)
        generator {
            fuelTankMetrics {
                capacity(unit: Liter)
            }
        }
    }
`;

const FuelMetric: FC<{ label: string; value: number | null }> = ({ label, value }) => {
    return (
        <div className='flex flex-col' data-testid='fuel-metric'>
            <div className='text-sm font-light'>{label}</div>
            <div className='text-lg font-semibold'>{numberToLocaleString(value, 0)}L</div>
        </div>
    );
};
