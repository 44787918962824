import { useMemo } from 'react';

import { Domain, DomainAbsolute, MenuItemType, Theme, asAbsoluteDurationDomain } from '@accesstel/pcm-ui';

import { DateTime, Duration } from 'luxon';

import { ExtendedChartMarker } from '../../components/MetricsLine';
import { AnnotationType } from './annotations';
import { MenuOptions } from './common';

interface CoupDeFouetInfo {
    float: number;
    troughVoltage: number;
    troughOffset: unknown;
    dip: number;
    plateauVoltage: number;
    plateauOffset: unknown;
}

export function useCDFOverlays(
    startTime: DateTime,
    endTime: DateTime,
    chartDomain: Domain<Duration>,
    cdfRegions: CoupDeFouetInfo[] | CoupDeFouetInfo | null,
    onZoomCDF: (domain: DomainAbsolute<Duration>) => void
): [ExtendedChartMarker[], MenuItemType[]] {
    let regions: CoupDeFouetInfo[];

    if (cdfRegions) {
        if (Array.isArray(cdfRegions)) {
            regions = cdfRegions;
        } else {
            regions = [cdfRegions];
        }
    } else {
        regions = [];
    }

    const maximumPlateauOffset = regions
        .map(region => Duration.fromISO(region.plateauOffset as string))
        .reduce((maximum, offset) => {
            if (offset > maximum) {
                return offset;
            } else {
                return maximum;
            }
        }, Duration.fromObject({}));

    const markers = useMemo<ExtendedChartMarker[]>(() => {
        if (regions.length === 0) {
            return [];
        }

        const markers: ExtendedChartMarker[] = [
            {
                type: 'area-vertical',
                color: Theme.eggplantExtraLight,
                x1: Duration.fromObject({ seconds: 0 }),
                x2: maximumPlateauOffset,
                below: true,
                opacity: 0.3,
                adjustDomain: true,
                annotationType: AnnotationType.CoupDeFouet,
            },
        ];

        const chartDuration = endTime.diff(startTime);
        const absoluteChartDomain = asAbsoluteDurationDomain(
            chartDomain,
            Duration.fromObject({ seconds: 0 }),
            chartDuration
        );
        const span = absoluteChartDomain[1].minus(absoluteChartDomain[0]);

        // Only display label of region if not zoomed in
        if (span.as('minutes') > maximumPlateauOffset.as('minutes') * 1.1) {
            markers.push({
                type: 'area-vertical',
                color: Theme.eggplantExtraLight,
                x1: Duration.fromObject({ seconds: 0 }),
                x2: maximumPlateauOffset,
                label: 'Coup de fouet region',
                labelPosition: 'insideBottomLeft',
                below: true,
                labelColor: Theme.eggplantRegular,
                opacity: 0,
                adjustDomain: true,
                annotationType: AnnotationType.CoupDeFouet,
                showLabelOnHover: true,
            });
        }

        // We can't display this neatly for multiple regions at once
        if (span.as('minutes') <= 20 && regions.length === 1) {
            const region = regions[0];

            const plateauOffset = Duration.fromISO(region.plateauOffset as string);
            const troughOffset = Duration.fromISO(region.troughOffset as string);

            markers.push(
                {
                    type: 'line',
                    x1: Duration.fromObject({ seconds: 0 }),
                    x2: plateauOffset,
                    y1: region.float,
                    y2: region.float,
                    color: Theme.eggplantRegular,
                    strokeDash: '8 4',
                    label: `Float: ${region.float.toFixed(2)}V`,
                    labelPosition: 'insideTopLeft',
                    labelColor: Theme.eggplantRegular,
                    opacity: 0.8,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                },
                {
                    type: 'line',
                    x1: Duration.fromObject({ seconds: 0 }),
                    x2: plateauOffset,
                    y1: region.plateauVoltage,
                    y2: region.plateauVoltage,
                    color: Theme.eggplantRegular,
                    strokeDash: '8 4',
                    label: `Plateau: ${region.plateauVoltage.toFixed(2)}V`,
                    labelPosition: 'insideBottomRight',
                    labelColor: Theme.eggplantRegular,
                    opacity: 0.8,
                    below: true,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                },
                {
                    type: 'line',
                    x1: Duration.fromObject({ seconds: 0 }),
                    x2: plateauOffset,
                    y1: region.troughVoltage,
                    y2: region.troughVoltage,
                    color: Theme.eggplantRegular,
                    strokeDash: '8 4',
                    label: `Trough: ${region.troughVoltage.toFixed(2)}V`,
                    labelPosition: 'insideTopLeft',
                    labelColor: Theme.eggplantRegular,
                    opacity: 0.8,
                    below: true,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                },
                {
                    type: 'line',
                    x1: troughOffset,
                    x2: troughOffset,
                    y1: region.float,
                    y2: region.troughVoltage,
                    color: Theme.eggplantLight,
                    label: `Drop: ${region.dip.toFixed(2)}V`,
                    labelPosition: 'right',
                    labelColor: Theme.eggplantRegular,
                    opacity: 0.8,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                },
                {
                    type: 'point',
                    y: region.troughVoltage,
                    x: troughOffset,
                    radius: 4,
                    color: Theme.eggplantRegular,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                },
                {
                    type: 'point',
                    y: region.plateauVoltage,
                    x: plateauOffset,
                    radius: 4,
                    color: Theme.eggplantRegular,
                    adjustDomain: true,
                    annotationType: AnnotationType.CoupDeFouet,
                }
            );
        }

        return markers;
    }, [maximumPlateauOffset, endTime, startTime, chartDomain, regions]);

    const menuOptions = useMemo<MenuItemType[]>(() => {
        if (regions.length === 0) {
            return [];
        }

        return [
            {
                id: MenuOptions.ViewCDF,
                name: 'Coup de fouet region',
                onClick() {
                    onZoomCDF([Duration.fromObject({ seconds: 0 }), maximumPlateauOffset]);
                },
            },
        ];
    }, [maximumPlateauOffset, onZoomCDF, regions.length]);

    return [markers, menuOptions];
}
