import React, { useState } from 'react';
import { useFragment } from 'react-relay';

import { ChartArea, DateLineChart, Domain, LineChartDataPoint, LineChartSeries } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';

import { FeedColours } from '../../ac-power/DeviceACPower';
import { GeneratorPowerChart_metrics$key } from './__generated__/GeneratorPowerChart_metrics.graphql';

interface GeneratorPowerChartProps {
    fragmentRef: GeneratorPowerChart_metrics$key;
}

export const GeneratorPowerChart: React.FC<GeneratorPowerChartProps> = ({ fragmentRef }) => {
    const metrics = useFragment(Fragment, fragmentRef);
    const lines = [metrics.phase1, metrics.phase2, metrics.phase3];

    const startTime = DateTime.fromISO(metrics.phase1?.power?.start ?? DateTime.local().toISO());
    const endTime = DateTime.fromISO(metrics.phase1?.power?.end ?? DateTime.local().toISO());

    const chartXDomain: Domain<Date> = [startTime.startOf('hour').toJSDate(), endTime.endOf('hour').toJSDate()];
    const unit: 'W' | 'kW' =
        Math.max(...lines.flatMap(line => line?.power?.values?.map(value => value.value ?? 0) ?? [])) > 1000
            ? 'kW'
            : 'W';

    const [series, setSeries] = useState<LineChartSeries<Date>[]>(() => {
        const seriesData: LineChartSeries<Date>[] = [];
        lines.forEach((line, index) => {
            let lineData: LineChartDataPoint<Date>[];

            if (line?.power?.values) {
                lineData = line.power.values.map<LineChartDataPoint<Date>>(value => ({
                    key: new Date(value.timestamp),
                    value: unit === 'W' ? value.value : (value.value ?? 0) / 1000,
                }));
            } else {
                lineData = [];
            }

            if (lineData) {
                const label = index + 1;
                seriesData.push({
                    id: `${label}`,
                    data: lineData,
                    color: FeedColours[index % FeedColours.length],
                    name: `Line ${label}`,
                    hidden: false,
                });
            }
        });

        return seriesData;
    });

    const onLegendClickHandler = (name: string) => {
        setSeries(prevSeries => {
            const newSeries = prevSeries.map(series => {
                if (series.name === name) {
                    return {
                        ...series,
                        hidden: !series.hidden,
                    };
                }

                return series;
            });

            return newSeries;
        });
    };

    return (
        <ChartArea title='Power'>
            <DateLineChart
                series={series}
                axisUnits
                unit={unit}
                aspectRatio={2.2}
                xDomain={chartXDomain}
                formatTooltipLabel={(_label: string | null, data: LineChartDataPoint<Date>) => {
                    const timeToNow = DateTime.fromJSDate(data.key).diffNow().negate();

                    if (timeToNow.as('hours') < 1) {
                        return 'Now';
                    } else {
                        return `${humanizeDuration(timeToNow.as('milliseconds'), { largest: 1, round: true })} ago`;
                    }
                }}
                formatTooltipValue={(value: number | null) => `${value?.toFixed(0)}${unit}`}
                xTicks={8}
                end
                formatEndTick={() => 'Now'}
                legend
                legendLayout='horizontal'
                legendOnClick={onLegendClickHandler}
            />
        </ChartArea>
    );
};

const Fragment = graphql`
    fragment GeneratorPowerChart_metrics on DeviceGeneratorOutputMetrics {
        phase1 {
            power(begin: $begin, end: $end, interval: "PT15M") {
                values {
                    timestamp
                    value
                }
                start
                end
            }
        }
        phase2 {
            power(begin: $begin, end: $end, interval: "PT15M") {
                values {
                    timestamp
                    value
                }
                start
                end
            }
        }
        phase3 {
            power(begin: $begin, end: $end, interval: "PT15M") {
                values {
                    timestamp
                    value
                }
                start
                end
            }
        }
    }
`;
