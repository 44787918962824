import React, { FC, useCallback, useState } from 'react';
import { PreloadedQuery, usePreloadedQuery, useRelayEnvironment } from 'react-relay';

import { MenuItem, useExtendedNavigate, useReferrer, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Formik, FormikHelpers } from 'formik';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { FormikProvisionLayout } from '../../../../layouts/FormikProvisionLayout';
import { ProvisionPageLayout } from '../../../../layouts/ProvisionPageLayout';
import { useConfirmationModal } from '../../../../lib/confirmation';
import { EditUserContentQuery } from './__generated__/EditUserContentQuery.graphql';
import { GeneralSection } from './components/GeneralSection';
import { PermissionsSection } from './components/PermissionsSection';
import { convertUserToFormState } from './lib/convert';
import { resendUserInvitation } from './lib/invitations';
import { resetUserPassword } from './lib/password';
import { decodeUserApiErrors, disableUser, enableUser, updateUser } from './lib/saving';
import { UserFormValues } from './schema';
import { UserValidationSchema } from './validation';

export interface EditUserContentProps {
    userId: string;
    queryRef: PreloadedQuery<EditUserContentQuery>;
}

export const EditUserContent: FC<EditUserContentProps> = ({ userId, queryRef }) => {
    const navigate = useExtendedNavigate();
    const { show: showToast } = useToast();
    const [showModal, modalComponent] = useConfirmationModal();

    const environment = useRelayEnvironment();
    const referrer = useReferrer() ?? Paths.SettingsUsers;

    const [isTogglingEnabledState, setIsTogglingEnabledState] = useState(false);

    const data = usePreloadedQuery<EditUserContentQuery>(LoadExistingUserQuery, queryRef);

    const handleEdit = useCallback(
        async (values: UserFormValues, { setErrors, setSubmitting }: FormikHelpers<UserFormValues>) => {
            setSubmitting(true);

            try {
                await updateUser(userId, values, environment);

                showToast({
                    text: 'User updated successfully!',
                    variant: 'info',
                });

                navigate(referrer);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeUserApiErrors(error);
                    setErrors(formErrors);

                    showToast({
                        text: 'Unable to save user. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('User', userId);
                        return scope;
                    });

                    showToast({
                        text: 'Unable to save user. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, navigate, referrer, showToast, userId]
    );

    if (!data.user) {
        // TODO: This looks quite bad. It would be better to show the form but
        // disabled with an error message
        return <ErrorNotFound />;
    }

    let primaryAction: string;
    if (data.user.enabled) {
        primaryAction = 'Disable';
    } else {
        primaryAction = 'Enable';
    }

    const onClickPrimaryAction = () => {
        showModal({
            title: 'Are you sure?',
            content: `Are you sure you want to ${primaryAction.toLowerCase()} this user?`,
            buttons: [
                {
                    id: 'confirm',
                    label: primaryAction,
                    variant: 'primary',
                },
                {
                    id: 'cancel',
                    label: 'Cancel',
                    variant: 'white',
                },
            ],
            onResult: async result => {
                if (result === 'confirm') {
                    setIsTogglingEnabledState(true);
                    if (data.user!.enabled) {
                        disableUser(userId, environment)
                            .then(() => {
                                showToast({
                                    text: 'User disabled successfully',
                                    variant: 'info',
                                });

                                navigate(referrer);
                            })
                            .catch(error => {
                                captureException(error, scope => {
                                    scope.setTag('User', userId);
                                    return scope;
                                });

                                showToast({
                                    text: 'Unable to disable user. Try again later',
                                    variant: 'error',
                                });
                            })
                            .finally(() => {
                                setIsTogglingEnabledState(false);
                            });
                    } else {
                        enableUser(userId, environment)
                            .then(() => {
                                showToast({
                                    text: 'User enabled successfully',
                                    variant: 'info',
                                });

                                navigate(referrer);
                            })
                            .catch(error => {
                                captureException(error, scope => {
                                    scope.setTag('User', userId);
                                    return scope;
                                });

                                showToast({
                                    text: 'Unable to enable user. Try again later',
                                    variant: 'error',
                                });
                            })
                            .finally(() => {
                                setIsTogglingEnabledState(false);
                            });
                    }
                }
            },
        });
    };

    const secondaryActions: MenuItem[] = [
        {
            name: 'Reset password',
            onClick: () => {
                showModal({
                    title: 'Reset user password?',
                    content: (
                        <>
                            <p>
                                This will reset the user's password immediately, preventing them from logging in with
                                their old password.
                            </p>
                            <p>
                                They will be sent a verification code which they can use to set their new password. The
                                code will only be valid for <strong>1 hour</strong>.
                            </p>
                        </>
                    ),
                    buttons: [
                        {
                            id: 'confirm',
                            label: 'Reset',
                            variant: 'primary',
                        },
                        {
                            id: 'cancel',
                            label: 'Cancel',
                            variant: 'white',
                        },
                    ],
                    onResult: async result => {
                        if (result === 'confirm') {
                            resetUserPassword(userId, environment)
                                .then(() => {
                                    showToast({
                                        text: 'Password reset successfully',
                                        variant: 'info',
                                    });
                                })
                                .catch(error => {
                                    captureException(error, scope => {
                                        scope.setTag('User', userId);
                                        return scope;
                                    });

                                    showToast({
                                        text: 'Unable to reset password. Try again later',
                                        variant: 'error',
                                    });
                                });
                        }
                    },
                });
            },
            disabled: data.user.type === 'External',
        },
    ];

    if (data.user.status === 'Invited') {
        secondaryActions.push({
            name: 'Re-send invitation',
            onClick: () => {
                resendUserInvitation(userId, environment)
                    .then(() => {
                        showToast({
                            text: 'Invite re-sent successfully',
                            variant: 'info',
                        });
                    })
                    .catch(error => {
                        captureException(error, scope => {
                            scope.setTag('User', userId);
                            return scope;
                        });

                        showToast({
                            text: 'Unable to resend invitation. Try again later',
                            variant: 'error',
                        });
                    })
                    .finally(() => {
                        setIsTogglingEnabledState(false);
                    });
            },
        });
    }

    return (
        <Formik
            initialValues={convertUserToFormState(data.user)}
            validationSchema={UserValidationSchema}
            onSubmit={handleEdit}
        >
            <ProvisionPageLayout
                title='Edit User'
                primaryAction={primaryAction}
                primaryActionClick={onClickPrimaryAction}
                primaryActionProcessing={isTogglingEnabledState}
                secondaryActions={secondaryActions}
            >
                <FormikProvisionLayout
                    type='user'
                    operation='edit'
                    secondaryAction={() => {
                        navigate(referrer);
                    }}
                >
                    <div className='space-y-8'>
                        <GeneralSection />
                        {data.user.type !== 'External' && <PermissionsSection />}
                    </div>
                </FormikProvisionLayout>
                {modalComponent}
            </ProvisionPageLayout>
        </Formik>
    );
};

const LoadExistingUserQuery = graphql`
    query EditUserContentQuery($username: String!) {
        user(username: $username) {
            name
            email
            permissions {
                general
                assets
                batteryHealthTasks
                administration
            }
            type
            status
            enabled
        }
    }
`;
