/**
 * @generated SignedSource<<48ca3202735c4b6714193b946e6a74cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneratorRunReportEditResult = "InvalidReport" | "InvalidValue" | "Success" | "%future added value";
export type NoteEditDialogGeneratorRunMutation$variables = {
  id: string;
  notes?: string | null;
};
export type NoteEditDialogGeneratorRunMutation$data = {
  readonly editGeneratorRunReportNote: GeneratorRunReportEditResult;
};
export type NoteEditDialogGeneratorRunMutation = {
  response: NoteEditDialogGeneratorRunMutation$data;
  variables: NoteEditDialogGeneratorRunMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "notes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "note",
        "variableName": "notes"
      }
    ],
    "kind": "ScalarField",
    "name": "editGeneratorRunReportNote",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NoteEditDialogGeneratorRunMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NoteEditDialogGeneratorRunMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c9719fe3404d0051eb144296bab9cab",
    "id": null,
    "metadata": {},
    "name": "NoteEditDialogGeneratorRunMutation",
    "operationKind": "mutation",
    "text": "mutation NoteEditDialogGeneratorRunMutation(\n  $id: ID!\n  $notes: String\n) {\n  editGeneratorRunReportNote(id: $id, note: $notes)\n}\n"
  }
};
})();

(node as any).hash = "7a0fbf5d1e1602b8c9ce455b49745a3e";

export default node;
