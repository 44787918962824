/**
 * @generated SignedSource<<09bde023a304edadf82633cfc9084acf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type columns_deviceAttributeColumnsQuery$variables = {
  disableAttributeColumns: boolean;
};
export type columns_deviceAttributeColumnsQuery$data = {
  readonly attributeNames?: ReadonlyArray<string>;
};
export type columns_deviceAttributeColumnsQuery = {
  response: columns_deviceAttributeColumnsQuery$data;
  variables: columns_deviceAttributeColumnsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "disableAttributeColumns"
  }
],
v1 = [
  {
    "condition": "disableAttributeColumns",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "limit",
            "value": 1000
          },
          {
            "kind": "Literal",
            "name": "only",
            "value": "Device"
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "status",
            "value": "Active"
          }
        ],
        "kind": "ScalarField",
        "name": "attributeNames",
        "storageKey": "attributeNames(limit:1000,only:\"Device\",search:\"\",status:\"Active\")"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "columns_deviceAttributeColumnsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "columns_deviceAttributeColumnsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "add188317bd068f2f0b0cda258a67263",
    "id": null,
    "metadata": {},
    "name": "columns_deviceAttributeColumnsQuery",
    "operationKind": "query",
    "text": "query columns_deviceAttributeColumnsQuery(\n  $disableAttributeColumns: Boolean!\n) {\n  attributeNames(search: \"\", only: Device, limit: 1000, status: Active) @skip(if: $disableAttributeColumns)\n}\n"
  }
};
})();

(node as any).hash = "114015a467dece017d8daeee89be415a";

export default node;
