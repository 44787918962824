import humanizeDuration from 'humanize-duration';
import { DateTime, Duration } from 'luxon';

const SHORT_DATE_FORMAT = 'dd LLL yyyy';
const LONG_DATE_FORMAT = 'dd LLLL yyyy';
const TIME_FORMAT = 'HH.mm';
const DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT}, ${TIME_FORMAT}`;
const FULL_DATE_TIME_FORMAT = `${LONG_DATE_FORMAT}, ${TIME_FORMAT}`;

const IntlDateFormat = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
});
const IntlShortDateFormatter = new Intl.DateTimeFormat(undefined, {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
});

export interface DateTimeFormatOptions {
    fullMonth?: boolean;
}

export function getDateTimeFormat(value: string | Date, options?: DateTimeFormatOptions): string {
    let date;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    if (options?.fullMonth) {
        return date.toFormat(FULL_DATE_TIME_FORMAT);
    }
    return date.toFormat(DATE_TIME_FORMAT);
}

export function getFormattedDateFromDate(value: string | Date, options?: DateTimeFormatOptions): string {
    let date;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    if (options?.fullMonth) {
        return date.toFormat(LONG_DATE_FORMAT);
    }
    return date.toFormat(SHORT_DATE_FORMAT);
}

export function getFormattedTimeFromDate(value: string | Date): string {
    let date;
    if (typeof value == 'string') {
        date = DateTime.fromISO(value);
    } else {
        date = DateTime.fromJSDate(value);
    }

    return date.toFormat(TIME_FORMAT);
}

/**
 * Format a date using the Intl API
 * @param value The ISO string or Date object to format
 * @param format The format to use
 * @returns The formatted date string
 */
export function formatDateIntl(value: string | Date, format: 'short-date' | 'date'): string {
    let date: Date;
    if (typeof value == 'string') {
        date = new Date(value);
    } else {
        date = value;
    }

    if (format === 'short-date') {
        return IntlShortDateFormatter.format(date);
    } else if (format === 'date') {
        return IntlDateFormat.format(date);
    } else {
        return 'Invalid format';
    }
}

/**
 *
 * @param durationDiff in milliseconds
 * @returns a formatted string of the duration in the PAST, otherwise null (not used for future duration)
 */
export function getHumanizedTextFromDuration(durationDiff: number): string | null {
    if (durationDiff > 0) {
        return null;
    }

    if (Duration.fromMillis(Math.abs(durationDiff)).as('months') < 1) {
        return 'less than a month ago';
    }

    return `${humanizeDuration(durationDiff, { units: ['y', 'mo'], round: true })} ago`;
}

/**
 * This function is meant to be used in the correct order, returning 0 minutes if the 'from' is bigger than the 'to'.
 * Otherwise, will return a string in the format 'x hour[s] y minute[s]', omitting any zero values.
 *
 * Example with correct ordering:
 * ```
 * from: '2022-06-06T04:00:00.981Z (in luxon.DateTime format)'
 * to: '2022-06-06T05:50:00.981Z (in luxon.DateTime format)'
 * returns: '1 hour 50 minutes'
 * ```
 *
 * Example with incorrect ordering:
 * ```
 * from: '2022-06-06T05:50:00.981Z (in luxon.DateTime format)'
 * to: '2022-06-06T04:00:00.981Z (in luxon.DateTime format)'
 * returns: '0 minutes'
 * ```
 * */
export function formatDurationAsHoursAndMinutes(from: DateTime, to: DateTime): string {
    // must be to.diff(from) so that duration is positive
    const calculatedDuration = to.diff(from).as('minutes');
    return formatMinutesAsHoursAndMinutes(calculatedDuration);
}

export function formatMinutesAsHoursAndMinutes(rawMinutes: number, short?: boolean): string {
    const hours = Math.floor(rawMinutes / 60);
    const minutes = Math.floor(rawMinutes % 60);

    const durationSegment = [];
    if (short) {
        if (hours > 0) {
            durationSegment.push(`${hours}h`);
        }

        if (minutes > 0) {
            durationSegment.push(`${minutes}m`);
        }
    } else {
        if (hours === 1) {
            durationSegment.push('1 hour');
        } else if (hours > 1) {
            durationSegment.push(`${Math.floor(hours)} hours`);
        }

        if (minutes >= 1 && minutes < 2) {
            durationSegment.push('1 minute');
        } else if (minutes > 1) {
            durationSegment.push(`${Math.floor(minutes)} minutes`);
        }
    }

    // when it is 0 hours and 0 minutes
    if (durationSegment.length === 0) {
        if (short) {
            return '0 min';
        } else {
            return '0 minutes';
        }
    }

    return durationSegment.join(' ');
}

export const getHumanizedTextBetweenDates = (date1: string, date2: string) => {
    const dateTime1 = DateTime.fromISO(date1);
    const dateTime2 = DateTime.fromISO(date2);
    const msDiff = dateTime2.diff(dateTime1);

    return humanizeDuration(msDiff.as('milliseconds'), { round: true, largest: 2 });
};
