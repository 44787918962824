import { Dispatch } from 'react';

import { FilterAction, FilterState, useFilterReducer } from '../common';
import {
    DefaultValues,
    GeneratorRunReportColumnFilterMap,
    StaticGeneratorRunReportFilterDefinitions,
} from './settings';
import { GeneratorRunReportTableColumn } from './types';

export type GeneratorRunReportFilterState = FilterState<
    GeneratorRunReportTableColumn,
    GeneratorRunReportColumnFilterMap
>;
export type GeneratorRunReportFilterAction = FilterAction<
    GeneratorRunReportColumnFilterMap,
    GeneratorRunReportTableColumn
>;

export function useGeneratorRunReportFilter(): [
    GeneratorRunReportFilterState,
    Dispatch<GeneratorRunReportFilterAction>,
] {
    const [state, updateState] = useFilterReducer<
        GeneratorRunReportTableColumn,
        GeneratorRunReportColumnFilterMap,
        GeneratorRunReportFilterState,
        GeneratorRunReportFilterAction
    >('run-report-filter', DefaultValues, StaticGeneratorRunReportFilterDefinitions, {});

    return [state, updateState];
}

export function generatorReportToFilterObject(filters: GeneratorRunReportFilterState): Record<string, unknown> {
    const output: Record<string, unknown> = {};
    const {
        Cause: cause,
        State: state,
        DeviceName: deviceName,
        ModelName: modelName,
        SerialNumber: serialNumber,
        FuelCapacity: fuelCapacity,
        CreatedTime: createdTime,
        FinalisedTime: finalisedTime,
        GeneratorStartTime: generatorStartTime,
        GeneratorStopTime: generatorStopTime,
        TotalRunTime: totalRunTime,
    } = filters.columnValues;

    if (deviceName.length > 0) {
        output.deviceName = deviceName.map(filter => ({ value: filter.name }));
    }

    if (cause.length > 0) {
        output.cause = cause.map(filter => filter.id);
    }

    if (state.length > 0) {
        output.state = state.map(filter => filter.id);
    }

    if (createdTime) {
        const dateFilter = createdTime;
        output.createdTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (finalisedTime) {
        const dateFilter = finalisedTime;
        output.finalisedTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (generatorStartTime) {
        const dateFilter = generatorStartTime;
        output.generatorStartTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }
    if (generatorStopTime) {
        const dateFilter = generatorStopTime;
        output.generatorStopTime = {
            min: dateFilter.range[0].toISOString(),
            max: dateFilter.range[1].toISOString(),
        };
    }

    if (totalRunTime) {
        output.totalRunTime = {
            min: totalRunTime.range[0],
            max: totalRunTime.range[1],
        };
    }

    if (modelName.length > 0) {
        output.modelName = modelName.map(filter => ({ value: filter.value }));
    }

    if (serialNumber.length > 0) {
        output.serialNumber = serialNumber.map(filter => ({ value: filter.value }));
    }

    if (fuelCapacity) {
        output.fuelCapacity = {
            min: fuelCapacity.min,
            max: fuelCapacity.max,
        };
    }

    return output;
}
