/**
 * @generated SignedSource<<1fae0ce8ec82810f5cceea37daea254f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type IncidentCountsChart$data = {
  readonly siteAcPowerIncidentDistributionOverTime: ReadonlyArray<{
    readonly begin: string;
    readonly distribution: ReadonlyArray<{
      readonly count: number;
      readonly status: ACPowerEventStatus;
    }>;
    readonly end: string;
  }> | null;
  readonly " $fragmentType": "IncidentCountsChart";
};
export type IncidentCountsChart$key = {
  readonly " $data"?: IncidentCountsChart$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncidentCountsChart">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "end"
    },
    {
      "kind": "RootArgument",
      "name": "incidentBegin"
    },
    {
      "kind": "RootArgument",
      "name": "interval"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "IncidentCountsChart",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "begin",
          "variableName": "incidentBegin"
        },
        {
          "kind": "Variable",
          "name": "end",
          "variableName": "end"
        },
        {
          "kind": "Variable",
          "name": "interval",
          "variableName": "interval"
        }
      ],
      "concreteType": "SiteACPowerIncidentDistribution",
      "kind": "LinkedField",
      "name": "siteAcPowerIncidentDistributionOverTime",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "begin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SiteACPowerIncidentDistributionCount",
          "kind": "LinkedField",
          "name": "distribution",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "68d657960907c81f98572db2e777c240";

export default node;
