import React, { FC, useEffect, useRef, useState } from 'react';
import { useFragment } from 'react-relay';

import { BarChart, CartesianChartDataType, Theme, ThemedBarSeriesType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { IncidentCountsChart_acPower$key } from './__generated__/IncidentCountsChart_acPower.graphql';

const PageBreakThreshold = 1280;

type HorizontalOrVertical = 'horizontal' | 'vertical';

export interface IncidentsCountChartProps {
    fragmentRef: IncidentCountsChart_acPower$key;
}

export const IncidentsCountChart: FC<IncidentsCountChartProps> = ({ fragmentRef }) => {
    const data = useFragment(Fragment, fragmentRef);

    let alertType: 'empty-good' | undefined;
    let alertMessage;
    let alertTitle;

    if (data?.deviceAcIncidentStatusDistribution == null) {
        alertType = 'empty-good';
        alertTitle = 'No devices found';
        alertMessage = '';
    } else {
        const totalIncidents = data.deviceAcIncidentStatusDistribution?.reduce(
            (previous, current) =>
                previous + current.distribution.reduce((previous, current) => previous + current.count, 0),
            0
        );

        if (totalIncidents === 0) {
            alertType = 'empty-good';
            alertTitle = 'No incidents found';
            alertMessage = '';
        }
    }

    const containerRef = useRef<HTMLDivElement>(null);
    const resizeObserver = useRef<ResizeObserver | null>(null);

    const [chartLayout, setChartLayout] = useState<HorizontalOrVertical>('vertical');
    const [aspectRatio, setAspectRatio] = useState<number>(1);
    const [isReadyToRender, setReadyToRender] = useState<boolean>(false);

    const deviceDistributions = data.deviceAcIncidentStatusDistribution ?? [];
    const outageData: CartesianChartDataType<string>[] = [];
    const underVoltageData: CartesianChartDataType<string>[] = [];
    const overVoltageData: CartesianChartDataType<string>[] = [];

    for (const deviceDistribution of deviceDistributions) {
        for (const entry of deviceDistribution.distribution) {
            switch (entry.key) {
                case 'Outage':
                    outageData.push({
                        key: deviceDistribution.device.name,
                        value: entry.count,
                    });
                    break;
                case 'UnderVoltage':
                    underVoltageData.push({
                        key: deviceDistribution.device.name,
                        value: entry.count,
                    });
                    break;
                case 'OverVoltage':
                    overVoltageData.push({
                        key: deviceDistribution.device.name,
                        value: entry.count,
                    });
                    break;
            }
        }
    }

    const chartData: ThemedBarSeriesType<string>[] = [
        {
            id: 'out',
            name: 'Outages',
            color: Theme.coralRegular,
            data: outageData,
        },
        {
            id: 'uv',
            name: 'Undervoltages',
            color: Theme.eggplantLight,
            data: underVoltageData,
        },
        {
            id: 'ov',
            name: 'Overvoltages',
            color: Theme.mustardRegular,
            data: overVoltageData,
        },
    ];

    const [series, setSeries] = useState<ThemedBarSeriesType<string>[]>(chartData);

    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            // Adjust the layout based on the page width
            if (window.innerWidth >= PageBreakThreshold) {
                setChartLayout('horizontal');
            } else {
                setChartLayout('vertical');
            }

            // Adjust the aspect ratio to match the container
            const { width, height } = entries[0].contentRect;
            setAspectRatio(Math.round(width) / Math.round(height));
            setReadyToRender(true);
        });

        resizeObserver.current.observe(containerRef.current!);

        return () => {
            resizeObserver.current?.disconnect();
        };
    }, []);

    const onLegendClickHandler = (name: string) => {
        const newSeries = series.map(series => {
            if (series.name === name) {
                return {
                    ...series,
                    hidden: !series.hidden,
                };
            }

            return series;
        });

        setSeries(newSeries);
    };

    return (
        <div ref={containerRef} className='flex-grow relative pb-12'>
            <div className='absolute inset-2'>
                {isReadyToRender && (
                    <BarChart
                        series={series}
                        aspectRatio={aspectRatio}
                        layout={chartLayout}
                        xTickAngle={chartLayout === 'vertical'}
                        xAxisSize={chartLayout === 'vertical' ? 70 : 150}
                        formatTooltipValue={(value, _data, _unit, name) => {
                            const formattedValue = value?.toFixed(0) ?? '-';

                            return `${formattedValue} ${name}`;
                        }}
                        allStacked
                        showLegendInTooltip={false}
                        legend
                        legendSeparate
                        legendOnClick={onLegendClickHandler}
                        alertType={alertType}
                        alertMessage={alertMessage}
                        alertTitle={alertTitle}
                        maxBarSize={80}
                    />
                )}
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment IncidentCountsChart_acPower on SiteACPower {
        deviceAcIncidentStatusDistribution(timeRange: $timeRange) {
            device {
                id
                name
            }
            distribution {
                key
                count
            }
        }
    }
`;
