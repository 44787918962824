/**
 * @generated SignedSource<<ac024e4938f4e4a05958e434aa8aff6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ACPowerEventSortField = "AffectedAllFeeds" | "DeviceName" | "Duration" | "EndTime" | "SiteName" | "SiteState" | "StartTime" | "Voltage" | "WorstStatus" | "%future added value";
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type ACPowerEventFilter = {
  activeWithin?: DateRangeFilter | null;
  affectedFeeds?: ReadonlyArray<StringFilter> | null;
  affectsAllFeeds?: BooleanFilter | null;
  deviceId?: ReadonlyArray<string> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  duration?: DurationFilter | null;
  endTime?: DateRangeFilter | null;
  isSignificant?: BooleanFilter | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  startTime?: DateRangeFilter | null;
  status?: ReadonlyArray<ACPowerEventStatusFilter> | null;
  voltage?: FloatRangeFilter | null;
  worstStatus?: ReadonlyArray<ACPowerEventStatusFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type ACPowerEventStatusFilter = {
  value: ACPowerEventStatus;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type ACPowerEventOrdering = {
  dir?: SortDirection | null;
  field: ACPowerEventSortField;
};
export type ACPowerDeviceIncidentListTableQuery$variables = {
  filter?: ACPowerEventFilter | null;
  orderBy?: ACPowerEventOrdering | null;
  page: number;
};
export type ACPowerDeviceIncidentListTableQuery$data = {
  readonly acEvents: {
    readonly data: ReadonlyArray<{
      readonly affectedFeeds: ReadonlyArray<{
        readonly id: string;
        readonly label: string;
        readonly status: ACPowerEventStatus;
        readonly voltage: number | null;
      }>;
      readonly device: {
        readonly id: string;
        readonly name: string;
        readonly site: {
          readonly id: string;
          readonly name: string;
        };
      };
      readonly duration: string;
      readonly id: string;
      readonly startTime: string;
      readonly worstStatus: ACPowerEventStatus;
      readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentExpandedRow_event">;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly total: number;
    };
    readonly total: number;
  };
  readonly overallIncidents: {
    readonly total: number;
  };
};
export type ACPowerDeviceIncidentListTableQuery = {
  response: ACPowerDeviceIncidentListTableQuery$data;
  variables: ACPowerDeviceIncidentListTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filter"
},
v4 = [
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ACPowerEventFeed",
  "kind": "LinkedField",
  "name": "affectedFeeds",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voltage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "device",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worstStatus",
  "storageKey": null
},
v14 = {
  "alias": "overallIncidents",
  "args": [
    (v3/*: any*/)
  ],
  "concreteType": "PaginatedACPowerEvent",
  "kind": "LinkedField",
  "name": "acEvents",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ACPowerDeviceIncidentListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedACPowerEvent",
        "kind": "LinkedField",
        "name": "acEvents",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ACPowerEvent",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeviceIncidentExpandedRow_event"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ACPowerDeviceIncidentListTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedACPowerEvent",
        "kind": "LinkedField",
        "name": "acEvents",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ACPowerEvent",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "affectedAllFeeds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ACPowerEventStats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "batteryDidDischarge",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "batteryDoD",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loadUptime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v14/*: any*/)
    ]
  },
  "params": {
    "cacheID": "e635a008c818a2a419ce7a5707ee8e4a",
    "id": null,
    "metadata": {},
    "name": "ACPowerDeviceIncidentListTableQuery",
    "operationKind": "query",
    "text": "query ACPowerDeviceIncidentListTableQuery(\n  $page: Int!\n  $filter: ACPowerEventFilter\n  $orderBy: ACPowerEventOrdering\n) {\n  acEvents(page: $page, orderBy: $orderBy, filters: $filter) {\n    total\n    pageInfo {\n      page\n      total\n      hasNext\n      hasPrevious\n    }\n    data {\n      id\n      startTime\n      affectedFeeds {\n        id\n        label\n        status\n        voltage\n      }\n      device {\n        id\n        name\n        site {\n          id\n          name\n        }\n      }\n      duration\n      worstStatus\n      ...DeviceIncidentExpandedRow_event\n    }\n  }\n  overallIncidents: acEvents(filters: $filter) {\n    total\n  }\n}\n\nfragment DeviceIncidentExpandedRow_event on ACPowerEvent {\n  id\n  startTime\n  endTime\n  duration\n  worstStatus\n  affectedAllFeeds\n  affectedFeeds {\n    id\n    label\n    voltage\n    status\n  }\n  stats {\n    batteryDidDischarge\n    batteryDoD\n    loadUptime\n  }\n  device {\n    id\n    name\n    site {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae85bf442ddd4814525b5bb5e40cf10b";

export default node;
