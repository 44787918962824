import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ArrowDownCircleIcon, CircleAlertIcon, CircleCrossIcon, CircleHelpIcon, ListTile } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { makeLinkToMetric } from '../../../../../explore/lib/link';
import { Operation } from '../../../../../explore/types';
import { DeviceHealth, ModuleTile_data$key } from './__generated__/ModuleTile_data.graphql';

export interface ModuleTileProps {
    module: ModuleTile_data$key;
    deviceId: string;
    deviceOffline: boolean;
}

export const ModuleTile: FC<ModuleTileProps> = ({ module, deviceId, deviceOffline }) => {
    const data = useFragment(Fragment, module);

    let primaryValue: number | null = null;
    if (data.metrics?.latestOutputCurrent) {
        primaryValue = Math.round(data.metrics?.latestOutputCurrent);
    }

    let secondaryValue: number | null = null;
    if (data.metrics?.latestTemperature) {
        secondaryValue = Math.round(data.metrics?.latestTemperature);
    }

    return (
        <ListTile
            label={data.label}
            primaryValue={!deviceOffline ? primaryValue : null}
            primaryValueUnit={'A'}
            secondaryValue={!deviceOffline ? secondaryValue : null}
            secondaryValueUnit='°C'
            error={data.metrics.status !== 'Healthy'}
            statusIcon={getTileIcon(data.metrics.status)}
            link={makeLinkToMetric(deviceId, [
                {
                    metric: `RectifierModuleOutputCurrent.${data.label}`,
                    op: Operation.Average,
                },
                {
                    metric: `RectifierModuleTemperature.${data.label}`,
                    op: Operation.Average,
                },
            ])}
        />
    );
};

function getTileIcon(status: DeviceHealth): React.ReactNode {
    switch (status) {
        case 'Degraded':
            return <ArrowDownCircleIcon />;
        case 'Critical':
            return <CircleAlertIcon />;
        case 'Offline':
            return <CircleCrossIcon />;
        case 'Unknown':
            return <CircleHelpIcon />;
        default:
            return <></>;
    }
}

const Fragment = graphql`
    fragment ModuleTile_data on RectifierModule {
        id
        label
        metrics {
            status
            latestTemperature
            latestOutputCurrent
        }
    }
`;
