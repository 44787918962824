import React, { FC, useCallback } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import {
    NotInProgTestsTableColumn,
    PageTypes,
    getTaskStateFromPageType,
    taskToFilterObject,
    useTaskFilter,
} from 'filters/task';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { useUserPermissions } from 'lib/auth';
import { Paths } from 'lib/routes';

import { useTableQuery } from '../../../../lib/tables/table-query';
import {
    BatteryHealthTaskListQuery,
    BatteryHealthTaskListQuery$data,
    TaskOrdering,
    TaskSortField,
} from './__generated__/BatteryHealthTaskListQuery.graphql';
import {
    BatteryHealthTaskListSearchQuery,
    BatteryHealthTaskListSearchQuery$data,
} from './__generated__/BatteryHealthTaskListSearchQuery.graphql';
import { AllTableColumns, BaseTableColumns } from './settings';

type Task = BatteryHealthTaskListQuery$data['tasks']['data'][number];
type CompletedTestsSearchResult = BatteryHealthTaskListSearchQuery$data['tasks']['data'][number];

interface BatteryHealthTaskListProps {
    pageType: PageTypes;
}

const TableStorageKeyPrefix = 'test-table';

export const BatteryHealthTaskList: FC<BatteryHealthTaskListProps> = ({ pageType }) => {
    const { hasTasksWrite } = useUserPermissions();

    let pageSettings: {
        taskState: string[];
        title: string;
        tableSortDirection: SortDirection;
        exportFilename: string;
    };

    switch (pageType) {
        case 'cancelled':
            pageSettings = {
                taskState: getTaskStateFromPageType(pageType),
                title: 'Cancelled tests',
                tableSortDirection: SortDirection.Descending,
                exportFilename: 'cancelled-tests',
            };
            break;
        case 'scheduled':
            pageSettings = {
                taskState: getTaskStateFromPageType(pageType),
                title: 'Scheduled tests',
                tableSortDirection: SortDirection.Ascending,
                exportFilename: 'scheduled-tests',
            };
            break;
        case 'completed':
        default:
            pageSettings = {
                taskState: getTaskStateFromPageType(pageType),
                title: 'Completed tests',
                tableSortDirection: SortDirection.Descending,
                exportFilename: 'completed-tests',
            };
            break;
    }

    const environment = useRelayEnvironment();
    const [tableState, dispatchTableState] = useTableReducer<NotInProgTestsTableColumn>({
        defaultSortColumn: NotInProgTestsTableColumn.Date,
        defaultSortDirection: pageSettings.tableSortDirection,
        allColumns: AllTableColumns,
        defaultVisibleColumns: BaseTableColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useTaskFilter();

    const {
        data: props,
        error,
        retry,
        isFetching,
        fetchTable,
    } = useTableQuery<NotInProgTestsTableColumn, BatteryHealthTaskListQuery>(
        graphql`
            query BatteryHealthTaskListQuery(
                $page: Int = 1
                $pageSize: Int = 50
                $pageCount: Int
                $state: [String!]
                $filters: TaskFilter
                $orderBy: TaskOrdering
                $search: String = ""
                $withSmartStart: Boolean = false
                $withCommencedTime: Boolean = false
            ) {
                tasks(
                    type: BatteryTest
                    page: $page
                    pageSize: $pageSize
                    pageCount: $pageCount
                    state: $state
                    filters: $filters
                    orderBy: $orderBy
                    search: $search
                ) {
                    total
                    data {
                        id
                        ... on BatteryTest {
                            type
                            testState
                            abortedTime
                            cancelledTime
                            schedule {
                                time
                                repeat
                            }
                            usingSmartStart @include(if: $withSmartStart)
                            commencedTime @include(if: $withCommencedTime)
                        }
                        name
                        devices {
                            total
                        }
                        overallState
                        completedTime
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                tasksCount: tasks(type: BatteryTest, state: $state) {
                    total
                }
            }
        `,
        options => {
            const sortObject: TaskOrdering = {
                field: options.orderBy as TaskSortField,
                dir: options.orderDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
            };

            return {
                page: options.page,
                pageSize: options.pageSize,
                filters: taskToFilterObject(filters),
                state: pageSettings.taskState,
                orderBy: sortObject,
                search: options.search,
                withSmartStart: options.visibleColumns.includes(NotInProgTestsTableColumn.UsingSmartStart),
                withCommencedTime: options.visibleColumns.includes(NotInProgTestsTableColumn.RunTime),
            };
        },
        tableState
    );

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<BatteryHealthTaskListSearchQuery>(
                environment,
                graphql`
                    query BatteryHealthTaskListSearchQuery($search: String = "", $state: [String!], $pageSize: Int!) {
                        tasks(search: $search, state: $state, type: BatteryTest, pageSize: $pageSize) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                {
                    search: input,
                    state: pageSettings.taskState,
                    pageSize: 10,
                }
            )
                .toPromise()
                .then(result => (result?.tasks.data as CompletedTestsSearchResult[]) ?? []);
        },
        [environment, pageSettings.taskState]
    );

    return (
        <TableLayout
            title={pageSettings.title}
            columns={AllTableColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={(props?.tasks.data ?? null) as Task[]}
            getRowId={row => row.id}
            isProcessing={!!props && isFetching}
            page={props?.tasks.pageInfo.page}
            pageCount={props?.tasks.pageInfo.total}
            overallCount={props?.tasksCount.total}
            resultCount={props?.tasks.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder={'Search by Test Name'}
            onSearch={handleSearch}
            renderSearchResult={(item: CompletedTestsSearchResult) => item.name}
            renderSearchResultAsString={(item: CompletedTestsSearchResult) => item.name ?? item.id}
            emptyMessage='There are no tests present'
            unit='Test'
            primaryAction={hasTasksWrite ? 'Schedule a test' : undefined}
            primaryActionLink={hasTasksWrite ? Paths.TestsScheduleTest : undefined}
            getItemLink={getTaskLink}
            exportEnabled
            exportFilename={pageSettings.exportFilename}
            exportFetchData={options => fetchTable(options).then(result => result.tasks.data)}
        />
    );
};

function getTaskLink(task: Task): string {
    return `${Paths.TestsDetailsView}/${task.id}`;
}
