/**
 * @generated SignedSource<<3c427f9ed606034e3a1250afa4c1f464>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BatteryStatus = "BoostCharging" | "Charging" | "Discharging" | "Disconnected" | "FloatCharging" | "Idle" | "Missing" | "Unknown" | "%future added value";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type DeviceFilter = {
  acIncidentCount?: IntRangeFilter | null;
  acMtbf?: IntRangeFilter | null;
  acMttr?: IntRangeFilter | null;
  acOutageDurationSum?: IntRangeFilter | null;
  acReliabilityPercentile?: IntRangeFilter | null;
  attributes?: ReadonlyArray<AttributeFilters> | null;
  batteryCapacityRemaining?: FloatRangeFilter | null;
  batteryCapacityTotal?: FloatRangeFilter | null;
  batteryEnergyTotal?: FloatRangeFilter | null;
  batteryInstallDate?: DateRangeFilter | null;
  batteryManufactureDate?: DateRangeFilter | null;
  batteryReserveTime?: FloatRangeFilter | null;
  batteryState?: ReadonlyArray<BatteryStatusFilter> | null;
  batteryStateOfHealth?: FloatRangeFilter | null;
  batteryStringCount?: IntRangeFilter | null;
  batteryTemperature?: FloatRangeFilter | null;
  category?: ReadonlyArray<DeviceCategory> | null;
  health?: ReadonlyArray<StringFilter> | null;
  ids?: ReadonlyArray<string> | null;
  lastTestedDate?: DateRangeFilter | null;
  monitorOnly?: BooleanFilter | null;
  name?: ReadonlyArray<StringFilter> | null;
  siteId?: ReadonlyArray<string> | null;
  siteName?: ReadonlyArray<StringFilter> | null;
  siteState?: ReadonlyArray<StringFilter> | null;
  snmpVersion?: ReadonlyArray<StringFilter> | null;
  supportsBatteryTesting?: BooleanFilter | null;
  type?: ReadonlyArray<StringFilter> | null;
};
export type StringFilter = {
  value: string;
};
export type AttributeFilters = {
  filters: ReadonlyArray<StringFilter>;
  name: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type BatteryStatusFilter = {
  value: BatteryStatus;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type BooleanFilter = {
  value: boolean;
};
export type DevicesAllIdsQuery$variables = {
  filters?: DeviceFilter | null;
};
export type DevicesAllIdsQuery$data = {
  readonly devices: {
    readonly data: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type DevicesAllIdsQuery = {
  response: DevicesAllIdsQuery$data;
  variables: DevicesAllIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Literal",
        "name": "onlyProvisioningStatuses",
        "value": "Active"
      },
      {
        "kind": "Literal",
        "name": "pageSize",
        "value": 10000
      }
    ],
    "concreteType": "PaginatedDevices",
    "kind": "LinkedField",
    "name": "devices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DevicesAllIdsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DevicesAllIdsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bdfa856ef21e666886afbf961063d4ae",
    "id": null,
    "metadata": {},
    "name": "DevicesAllIdsQuery",
    "operationKind": "query",
    "text": "query DevicesAllIdsQuery(\n  $filters: DeviceFilter\n) {\n  devices(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {\n    data {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b527e5ca0522db98d6b2f86f83f0c3a6";

export default node;
