import { DateRangeFilter } from '../../components/FilterDateSelect/common';
import { RunTimeFilter } from '../../components/FilterDurationSelect/common';
import {
    GeneratorRunReportCause,
    GeneratorRunReportState,
} from '../../views/tasks/generator-run/report-list/__generated__/GeneratorRunListQuery.graphql';
import { RangeFilter } from '../common';
import { DeviceNameFilter } from '../device';

export type { GeneratorRunReportState, GeneratorRunReportCause };

export enum GeneratorRunReportTableColumn {
    DeviceName = 'DeviceName',
    CreatedTime = 'CreatedTime',
    FinalisedTime = 'FinalisedTime',
    GeneratorStartTime = 'GeneratorStartTime',
    GeneratorStopTime = 'GeneratorStopTime',
    TotalRunTime = 'TotalRunTime',
    State = 'State',
    Cause = 'Cause',
    ModelName = 'ModelName',
    SerialNumber = 'SerialNumber',
    FuelCapacity = 'FuelCapacity',
}

export type GeneratorRunReportDeviceNameFilter = DeviceNameFilter;
export type GeneratorRunReportTotalRunTimeFilter = RunTimeFilter;
export type GeneratorRunReportTimeFilter = DateRangeFilter;

export interface GeneratorRunReportStateFilter {
    id: GeneratorRunReportState;
    name: string;
}

export interface GeneratorRunReportCauseFilter {
    id: GeneratorRunReportCause;
    name: string;
}

export interface TextFilterFilterBase {
    type: 'result' | 'special';
    value: string;
    wildcard?: boolean;
}

export type GeneratorRunReportModelFilter = TextFilterFilterBase;
export type GeneratorRunReportSerialNumberFilter = TextFilterFilterBase;

export type GeneratorRunReportFuelCapacityFilter = RangeFilter;
