import React from 'react';
import { FC } from 'react';
import { useFragment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { Paths } from 'lib/routes';
import { CardSection } from 'views/tasks/components';

import { FilterValueMap } from '../../../../../filters/common';
import { GeneratorRunReportTableColumn } from '../../../../../filters/generator-run-report';
import {
    GeneratorRunReportAllFilterMap,
    StaticGeneratorRunReportFilterDefinitions,
} from '../../../../../filters/generator-run-report/settings';
import { encodeFilterParameters } from '../../../../../lib/table-filter';
import { GeneratorRunCard } from '../components/GeneratorRunCard';
import { InProgressGeneratorRuns_tasks$key } from './__generated__/InProgressGeneratorRuns_tasks.graphql';

export interface InProgressGeneratorRunsProps {
    tasks: InProgressGeneratorRuns_tasks$key | null;
    maxItems: number;
}

export const InProgressGeneratorRuns: FC<InProgressGeneratorRunsProps> = ({ tasks, maxItems }) => {
    const result = useFragment<InProgressGeneratorRuns_tasks$key>(
        graphql`
            fragment InProgressGeneratorRuns_tasks on PaginatedGeneratorRunReport {
                data {
                    id
                    cause
                    state
                    generator {
                        id
                        name
                        site {
                            id
                            name
                            address {
                                state
                            }
                        }
                    }
                    generatorStartTime
                    generatorStopTime
                }
                total
            }
        `,
        tasks
    );

    if (!result || result?.data.length === 0) {
        return (
            <CardSection title='Happening now' testId='inprogress-section'>
                {emptyContent}
            </CardSection>
        );
    }

    return (
        <CardSection
            title='Happening now'
            testId='inprogress-section'
            to={{
                pathname: Paths.TasksGeneratorsList,
                search: encodeFilterParameters<
                    GeneratorRunReportTableColumn,
                    FilterValueMap<GeneratorRunReportAllFilterMap>
                >(
                    {
                        [GeneratorRunReportTableColumn.State]: [
                            {
                                id: 'Running',
                                name: 'In Progress',
                            },
                            {
                                id: 'Finishing',
                                name: 'Finishing',
                            },
                            {
                                id: 'Analysing',
                                name: 'Analysing',
                            },
                        ],
                    },
                    StaticGeneratorRunReportFilterDefinitions
                ),
            }}
        >
            {result.data.slice(0, maxItems).map(runReport => {
                return <GeneratorRunCard key={runReport.id} runReport={runReport} />;
            })}
        </CardSection>
    );
};

const emptyContent = (
    <div className='flex flex-col items-start justify-start gap-4 bg-white p-8 col-span-full'>
        <div>
            <div className='text-lg text-eggplantRegular font-bold'>All quiet in here...</div>
            <div className='text-base font-normal text-eggplantLight mb-4'>
                There are no generator runs happening right now.
            </div>
        </div>
    </div>
);
