/**
 * @generated SignedSource<<53519af1fef6eca294452c55918a6e9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ACPowerEventStatus = "Normal" | "Outage" | "OverVoltage" | "UnderVoltage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ACDeviceIncidents_acPower$data = {
  readonly incidentCount: {
    readonly total: number;
  };
  readonly latestSigIncident: {
    readonly data: ReadonlyArray<{
      readonly duration: string;
      readonly startTime: string;
      readonly worstStatus: ACPowerEventStatus;
    }>;
  };
  readonly sigIncidentCount: {
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DeviceIncidentList_acPower">;
  readonly " $fragmentType": "ACDeviceIncidents_acPower";
};
export type ACDeviceIncidents_acPower$key = {
  readonly " $data"?: ACDeviceIncidents_acPower$data;
  readonly " $fragmentSpreads": FragmentRefs<"ACDeviceIncidents_acPower">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "value": true
},
v1 = {
  "kind": "Variable",
  "name": "startTime",
  "variableName": "startRange"
},
v2 = {
  "fields": [
    {
      "kind": "Literal",
      "name": "isSignificant",
      "value": (v0/*: any*/)
    },
    (v1/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startRange"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ACDeviceIncidents_acPower",
  "selections": [
    {
      "alias": "latestSigIncident",
      "args": [
        (v2/*: any*/),
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "dir": "Desc",
            "field": "StartTime"
          }
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ACPowerEvent",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "worstStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "sigIncidentCount",
      "args": [
        (v2/*: any*/)
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "incidentCount",
      "args": [
        {
          "fields": [
            (v1/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "PaginatedACPowerEvent",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "showSignificant",
          "value": (v0/*: any*/)
        },
        {
          "kind": "Variable",
          "name": "startRange",
          "variableName": "startRange"
        }
      ],
      "kind": "FragmentSpread",
      "name": "DeviceIncidentList_acPower"
    }
  ],
  "type": "DeviceACPower",
  "abstractKey": null
};
})();

(node as any).hash = "393392e5cd1a9681f9315ec968c1c096";

export default node;
