import React, { FC, useMemo } from 'react';
import { useFragment } from 'react-relay';

import { LineChartSeries, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { Duration } from 'luxon';

import { Events } from './Events';
import { FuelStats } from './FuelStats';
import { LineChartWithMetrics } from './LineChartWithMetrics';
import { LoadChart } from './LoadChart';
import { MetricsView } from './MetricsView';
import { VitalsTable } from './VitalsTable';
import {
    RunReportResultDisplay_data$data,
    RunReportResultDisplay_data$key,
} from './__generated__/RunReportResultDisplay_data.graphql';

export interface RunReportResultDisplayProps {
    runReport: RunReportResultDisplay_data$key;
}

export const RunReportResultDisplay: FC<RunReportResultDisplayProps> = ({ runReport }) => {
    const data = useFragment<RunReportResultDisplay_data$key>(RunReportResultDisplayFragment, runReport);

    const processedFuelConsumptionData = useMemo(
        () => processChartData(data.fuelConsumptionRateOverTime),
        [data.fuelConsumptionRateOverTime]
    );
    const fuelConsumptionSeries: LineChartSeries<Duration>[] = [
        {
            name: 'Fuel consumption rate',
            data: processedFuelConsumptionData,
            color: Theme.coralRegular,
        },
    ];

    const processedFuelLevelData = useMemo(() => processChartData(data.fuelLevelOverTime), [data.fuelLevelOverTime]);
    const fuelLevelSeries: LineChartSeries<Duration>[] = [
        {
            name: 'Fuel level',
            data: processedFuelLevelData,
            color: Theme.coralRegular,
        },
    ];

    return (
        <>
            <MetricsView runReport={data} />
            <LoadChart runReport={data} />
            <VitalsTable runReport={data} />
            <FuelStats runReport={data} />
            <LineChartWithMetrics
                title='Consumption rate'
                series={fuelConsumptionSeries}
                unit='L/h'
                data={{
                    average: data.fuelConsumptionRate?.average ?? null,
                    min: data.fuelConsumptionRate?.min ?? null,
                    max: data.fuelConsumptionRate?.max ?? null,
                    latest: data.fuelConsumptionRate?.latest ?? null,
                }}
                type='consumption'
                state={data.state}
            />
            <LineChartWithMetrics
                title='Fuel level'
                series={fuelLevelSeries}
                unit='%'
                data={{
                    average: data.fuelLevel?.average ?? null,
                    min: data.fuelLevel?.min ?? null,
                    max: data.fuelLevel?.max ?? null,
                    latest: data.fuelLevel?.latest ?? null,
                }}
                yDomain={[0, 100]}
                type='fuelLevel'
                state={data.state}
            />
            <Events runReport={data} />
        </>
    );
};

const RunReportResultDisplayFragment = graphql`
    fragment RunReportResultDisplay_data on GeneratorRunReport @argumentDefinitions(chartPoints: { type: "Int" }) {
        id
        state
        fuelConsumptionRate(unit: LiterPerHour) {
            min
            max
            average
            latest
        }
        fuelConsumptionRateOverTime(points: $chartPoints, unit: LiterPerHour) {
            offset
            value
        }
        fuelLevel {
            min
            max
            average
            latest
        }
        fuelLevelOverTime(points: $chartPoints, unit: Liter) {
            offset
            value
        }
        ...MetricsView_data
        ...LoadChart_info
        ...VitalsTable_info
        ...FuelStats_data
        ...Events_data
    }
`;

type MetricOverTime = RunReportResultDisplay_data$data['fuelConsumptionRateOverTime' | 'fuelLevelOverTime'] | null;

function processChartData(data: MetricOverTime | null) {
    if (data) {
        const returnData = data
            .flatMap(point => {
                const key = Duration.fromISO(point.offset as string).set({ milliseconds: 0 });
                if (key.toMillis() >= 0) {
                    return {
                        key: key,
                        value: point.value,
                    };
                } else {
                    return [];
                }
            })
            .filter(point => point && point.value !== null);

        return returnData;
    } else {
        return [];
    }
}
