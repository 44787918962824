/**
 * @generated SignedSource<<46fe76d9b9867dd2216c64fd2c7dbbc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type columns_attributeColumnsQuery$variables = {
  disableAttributeColumns: boolean;
};
export type columns_attributeColumnsQuery$data = {
  readonly attributeNames?: ReadonlyArray<string>;
};
export type columns_attributeColumnsQuery = {
  response: columns_attributeColumnsQuery$data;
  variables: columns_attributeColumnsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "disableAttributeColumns"
  }
],
v1 = [
  {
    "condition": "disableAttributeColumns",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "limit",
            "value": 1000
          },
          {
            "kind": "Literal",
            "name": "only",
            "value": "Site"
          },
          {
            "kind": "Literal",
            "name": "search",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "status",
            "value": "Active"
          }
        ],
        "kind": "ScalarField",
        "name": "attributeNames",
        "storageKey": "attributeNames(limit:1000,only:\"Site\",search:\"\",status:\"Active\")"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "columns_attributeColumnsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "columns_attributeColumnsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42da2dc6e7d605899a8d8a8a8a22cd6b",
    "id": null,
    "metadata": {},
    "name": "columns_attributeColumnsQuery",
    "operationKind": "query",
    "text": "query columns_attributeColumnsQuery(\n  $disableAttributeColumns: Boolean!\n) {\n  attributeNames(search: \"\", only: Site, limit: 1000, status: Active) @skip(if: $disableAttributeColumns)\n}\n"
  }
};
})();

(node as any).hash = "0f5263c140bca6084a8b94c8deb8c39e";

export default node;
