/**
 * @generated SignedSource<<eb3ac33b44af34db2ad54ef63f6d5ee8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthLevelAdmin = "Admin" | "None" | "%future added value";
export type AuthLevelRead = "None" | "ReadOnly" | "%future added value";
export type AuthLevelWrite = "None" | "ReadOnly" | "ReadWrite" | "%future added value";
export type AuthenticationRole = "Administrator" | "AssetsReadOnly" | "AssetsReadWrite" | "ReadOnly" | "TasksBatteryHealthReadOnly" | "TasksBatteryHealthReadWrite" | "%future added value";
export type UserErrorType = "EmailAlreadyRegistered" | "InvalidUser" | "MissingEmail" | "MissingName" | "Noop" | "OperationNotAllowed" | "%future added value";
export type UserUpdateIn = {
  email?: string | null;
  name?: string | null;
  permissions?: UserPermissionsIn | null;
  roles?: ReadonlyArray<AuthenticationRole> | null;
};
export type UserPermissionsIn = {
  administration?: AuthLevelAdmin | null;
  assets?: AuthLevelWrite | null;
  batteryHealthTasks?: AuthLevelWrite | null;
  general?: AuthLevelRead | null;
};
export type savingEditUserMutation$variables = {
  id: string;
  user: UserUpdateIn;
};
export type savingEditUserMutation$data = {
  readonly editUser: {
    readonly problems?: ReadonlyArray<{
      readonly type: UserErrorType;
    }>;
    readonly username?: string;
  } | null;
};
export type savingEditUserMutation = {
  response: savingEditUserMutation$data;
  variables: savingEditUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "id"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProblem",
      "kind": "LinkedField",
      "name": "problems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "savingEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "savingEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49c325011169d7f6871a1a3303798b2b",
    "id": null,
    "metadata": {},
    "name": "savingEditUserMutation",
    "operationKind": "mutation",
    "text": "mutation savingEditUserMutation(\n  $id: String!\n  $user: UserUpdateIn!\n) {\n  editUser(username: $id, user: $user) {\n    __typename\n    ... on User {\n      username\n    }\n    ... on UserProblemResponse {\n      problems {\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33dff289476b8596aab8b2f61a027575";

export default node;
